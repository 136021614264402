import { ComponentType, useEffect, useState } from 'react'
import prettyBytes from 'pretty-bytes'
import { FilesSdk } from '@cango-app/sdk'
import dayjs from 'dayjs'
import { PulseLoader } from 'react-spinners'

import { colors } from 'src/theme/colors'

import { Box, IBoxProps } from '../box'
import { Text } from '../text'

type AttachedFileComponentProps = {
	file?: FilesSdk.File
	isLoading?: boolean
	onClick?: () => void
	containerProps?: IBoxProps
	isUploadingAsync: boolean
}

export const AttachedFileComponent: ComponentType<AttachedFileComponentProps> = ({
	file,
	isLoading,
	onClick,
	containerProps = {},
	isUploadingAsync,
}) => {
	const [thumbnailLoaded, setThumbnailLoaded] = useState(false)
	const isReady = !isLoading && file
	const { sx: containerSx, ...restContainerProps } = containerProps
	const containerStyles = {
		...containerSx,
		cursor: isReady ? 'pointer' : undefined,
		'&:hover': isReady ? { bgcolor: colors.neutral['10'] } : undefined,
	}

	useEffect(() => {
		if (file?.thumbnailLink) {
			const img = new Image()
			img.onload = () => setThumbnailLoaded(true)
			img.onerror = () => setThumbnailLoaded(false)
			img.src = file.thumbnailLink
		}
	}, [file?.thumbnailLink])

	return (
		<Box
			border={1}
			borderColor={colors.neutral['40']}
			display="flex"
			borderRadius="8px"
			minHeight="75px"
			overflow="hidden"
			{...restContainerProps}
			sx={containerStyles}
			onClick={isReady ? onClick : undefined}>
			{!isReady ? (
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					minWidth="170px"
					px={2}>
					<PulseLoader size={8} />
					{
						<Text fontSize={12}>
							{!isUploadingAsync ? 'Fetching file from Google drive' : 'File uploading...'}
						</Text>
					}
				</Box>
			) : (
				<>
					{thumbnailLoaded && !!file.thumbnailLink ? (
						<Box mr={2}>
							<img src={file.thumbnailLink} width={100} onError={() => setThumbnailLoaded(false)} />
						</Box>
					) : (
						<Box
							width={75}
							display="flex"
							alignItems="center"
							justifyContent="center"
							bgcolor={colors.neutral['10']}>
							<img src={file.iconLink.replace('16', '128')} width="26px" height="26px" />
						</Box>
					)}

					<Box px={2} display="flex" flexDirection="column" justifyContent="center">
						<Text color={colors.info.main}>{file.name}</Text>
						{!!file.size && <Text fontSize={12}>{prettyBytes(parseInt(file.size))}</Text>}
						<Text fontSize={12}>
							Last updated: {dayjs(file.modifiedTime).format('MMM DD, YYYY HH:mm')}
						</Text>
					</Box>
				</>
			)}
		</Box>
	)
}
