import { ComponentType, SVGProps } from 'react'

type InviteProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Invite: ComponentType<InviteProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 28 28" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M23.75 5H6.25a2.5 2.5 0 00-2.5 2.5V25a2.5 2.5 0 002.5 2.5h17.5a2.5 2.5 0 002.5-2.5V7.5a2.5 2.5 0 00-2.5-2.5zM3.75 12.5h22.5M20 2.5v5M10 2.5v5"></path>
	</svg>
)
