import React, { ComponentType } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'

import { Box } from '../box'
import { TextField } from '../text-field'
import { DisplayToggle } from '../display-view/display-toggle'

export const ViewSettings: ComponentType = () => {
	const { control } = useFormContext<TableTypes.TableView>()

	return (
		<Box>
			<Controller
				control={control}
				name="name"
				rules={{ required: 'View name is required' }}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<TextField
						value={value}
						onChange={onChange}
						label="View name"
						fullWidth
						sx={{ mb: 2 }}
						error={!!error}
						helperText={error?.message}
					/>
				)}
			/>
			<DisplayToggle />
		</Box>
	)
}
