import { ComponentType } from 'react'
import { useDispatch } from 'react-redux'
import { ClientTypes } from '@cango-app/types'

import { Box, Button, Text } from 'src/components'
import { actions as contactActions } from 'src/store/modules/contacts'

type DeleteContactProps = {
	closeDrawer: () => void
	contact: ClientTypes.Contact
}

export const DeleteContact: ComponentType<DeleteContactProps> = ({ contact, closeDrawer }) => {
	const dispatch = useDispatch()

	const handleDeleteContact = () => {
		dispatch(contactActions.deleteContact({ _id: contact._id }))
		closeDrawer()
	}

	return (
		<Box width={400} role="presentation">
			<Button onClick={handleDeleteContact} variant="contained" color="error">
				Delete Contact
			</Button>
		</Box>
	)
}
