import { TableTypes } from '@cango-app/types'

import { convertRowValueToArray } from '../utils'

export const convertRowValueToQuestionaireOption = (rowValue: any) => {
	const defaultValue: {
		answerType: TableTypes.AnswerType
		options: TableTypes.ListOption[]
	} = {
		answerType: TableTypes.AnswerType.SingleSelect,
		options: [],
	}
	if (!rowValue) {
		return defaultValue
	}

	if (!rowValue['answerType'] || !rowValue['options']) {
		return defaultValue
	}

	return {
		answerType: rowValue['answerType'],
		options: convertRowValueToArray(rowValue['options']),
	}
}
