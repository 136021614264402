import React, { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type DonwloadIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const DownloadIcon: ComponentType<DonwloadIconProps> = ({
	width = 16,
	fill = colors.feldgrau['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={fill}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M4 16v1a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-1m-4-4-4 4m0 0-4-4m4 4V4"
		/>
	</svg>
)
