import React, { ComponentType } from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

type TextProps = TypographyProps & {
	tooltip?: string
}

export const Text: ComponentType<TextProps> = ({ tooltip, ...props }) => {
	const handleClickStyles = props.onClick
		? {
				cursor: 'pointer',
				'&:hover': {
					textDecoration: 'underline',
				},
		  }
		: {}

	if (tooltip) {
		return (
			<Tooltip title={tooltip}>
				<Typography {...props} sx={{ ...props.sx, ...handleClickStyles }} />
			</Tooltip>
		)
	}

	return <Typography {...props} sx={{ ...props.sx, ...handleClickStyles }} />
}
