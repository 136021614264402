import { TableTypes, ClientTypes } from '@cango-app/types'
import _orderBy from 'lodash/orderBy'
import { GridFilterItem } from '@mui/x-data-grid-pro'
import {
	getGridNumericOperators,
	getGridSingleSelectOperators,
	getGridStringOperators,
	GridColType,
	GridColumnVisibilityModel,
	GridFilterModel,
	GridRowModel,
} from '@mui/x-data-grid-premium'
import dayjs from 'dayjs'
import _isArray from 'lodash/isArray'

import { SingleSelectForm } from './column-settings/types'

const buildDependencyGraph = (fields: TableTypes.Field[]) => {
	const graph = new Map<string, Set<string>>()
	fields.forEach((field) => {
		if (field.type === 'calculation') {
			field.calculation.forEach((part) => {
				if (part.type === TableTypes.FormulaSliceType.FIELD) {
					if (!graph.has(field._id)) {
						graph.set(field._id, new Set())
					}
					graph.get(field._id)?.add(part.value)
				}
			})
		}
	})
	return graph
}

const topologicalSort = (fields: TableTypes.Field[], dependencyGraph: Map<string, Set<string>>) => {
	const visitedFields = new Map()
	const sortedFields: TableTypes.Field[] = []

	const visitField = (fieldId: string) => {
		if (visitedFields.get(fieldId)) {
			return
		}
		visitedFields.set(fieldId, true)

		const dependencies = dependencyGraph.get(fieldId)
		if (dependencies) {
			dependencies.forEach((dependentFieldId) => {
				visitField(dependentFieldId)
			})
		}

		const field = fields.find((field) => field._id === fieldId)
		if (field) {
			sortedFields.push(field)
		}
	}

	dependencyGraph.forEach((_, fieldId: string) => {
		visitField(fieldId)
	})

	fields.forEach((field) => {
		if (!visitedFields.get(field._id)) {
			sortedFields.push(field)
		}
	})

	return sortedFields
}

export type ResolvedRowData = { _id: string } & TableTypes.Record['data']

type ResolveAnyRowCalculations = {
	fields: TableTypes.Field[]
	row: Omit<TableTypes.Record, 'calculations'>
	contacts: Map<string, ClientTypes.Contact>
}

export const resolveAnyRowCalculations = ({
	row,
	fields,
}: ResolveAnyRowCalculations): ResolvedRowData => {
	const dependencyGraph = buildDependencyGraph(fields)
	const sortedFields = topologicalSort(fields, dependencyGraph)
	const rowData: TableTypes.Record['data'] = {}
	sortedFields.forEach((field) => {
		const rowKey = field._id
		rowData[rowKey] = row.data[rowKey]
	})
	return { _id: row._id, ...rowData }
}

export const resolveResources = (resources: TableTypes.ResourceRecord) => {
	if (!resources?.data) return 0
	return resources.data.reduce((acc, record) => {
		const usage = record['usage'] || 1
		return acc + record['rate'] * record['quantity'] * usage
	}, 0)
}

export const getFilters = (table: TableTypes.CangoTable | undefined, filters: GridFilterItem[]) => {
	if (!table || !filters.length) return []
	const mappedColumnTypes = new Map(table.fields.map((_field) => [_field._id, _field.type]))

	return filters.reduce((_accFilters: GridFilterItem[], _filter) => {
		const columnType = mappedColumnTypes.get(_filter.field)
		if (!columnType) return _accFilters
		if (columnType === 'reference') {
			return _accFilters
		}
		const stringOperators = getGridStringOperators().map((_operator) => _operator.value)
		const singleSelectOperators = getGridSingleSelectOperators().map((_operator) => _operator.value)
		const numberOperators = getGridNumericOperators().map((_operator) => _operator.value)

		if (columnType === 'string' && !stringOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'equals',
				},
			]
		}

		if (columnType === 'calculation' && !numberOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: '=',
				},
			]
		}

		if (columnType === 'singleSelect' && !singleSelectOperators.includes(_filter.operator)) {
			return [
				..._accFilters,
				{
					..._filter,
					operator: 'is',
				},
			]
		}

		return [..._accFilters, _filter]
	}, [])
}

export const getExplicitVisibilityModel = (
	visibilityModel: GridColumnVisibilityModel,
	columns: Map<string, TableTypes.Field>,
): GridColumnVisibilityModel => {
	return [...columns.keys()].reduce(
		(acc, key) => {
			// in case there a column which doesn't have a visibilityModel. Default = false

			return {
				...acc,
				[key]: !!visibilityModel[key],
			}
		},
		{
			__check__: false,
		},
	)
}

export const getCleanedUpSingleSelectData = (
	newData: SingleSelectForm,
	oldData: {
		options: TableTypes.Field['valueOptions']
		valueOptionFilters: TableTypes.Field['valueOptionFilters']
		single_select_lookup:
			| {
					tableId: string
					fieldId: string
			  }
			| undefined
	},
): {
	valueOptions: TableTypes.Field['valueOptions']
	single_select_lookup: TableTypes.Field['single_select_lookup']
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
} => {
	// cases
	// old lookup new lookup
	// old lookup new custom -> reset single select filters
	// old custom new lookup
	// old custom new custom -> reset single select filters
	const orderedOptions = _orderBy(newData.options, 'label').filter(({ label }) => label)
	if (oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: [],
		}
	}
	if (oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
		}
	}
	if (!oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
			single_select_lookup: newData.single_select_lookup,
			valueOptions: [],
		}
	}
	if (!oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
		}
	}
	return {
		...newData,
		valueOptions: newData.options,
	}
}

export const columnValueGetter = (
	value: any,
	field: TableTypes.Field,
	valueOptions: Map<string, { _id: string; label: string }>,
) => {
	if (field.type === TableTypes.FieldType.BOOLEAN) {
		if (value === 'FALSE') {
			return false
		}
		return !!value
	}

	if (field.type === TableTypes.FieldType.RESOURCES) {
		return resolveResources(value)
	}

	if (
		[
			TableTypes.FieldType.TABLE_SELECT,
			TableTypes.FieldType.SINGLE_SELECT,
			TableTypes.FieldType.REFERENCE,
			TableTypes.FieldType.CONTACT,
			TableTypes.FieldType.ROLE,
			TableTypes.FieldType.QUESTIONAIRE_REFERENCE,
		].includes(field.type)
	) {
		const _selectedOption = valueOptions.get(value)
		if (!_selectedOption) {
			return value
		}
		return _selectedOption.label
	}

	return value
}

type OperatorFunction = (columnType: GridColType, cellValue: any, filterValue: any) => boolean

const operatorFunctions: Record<string, OperatorFunction> = {
	contains: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase()),
	doesNotContain: (columnType, cellValue, filterValue) =>
		!String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase()),
	equals: (columnType, cellValue, filterValue) => String(cellValue) === String(filterValue),
	doesNotEqual: (columnType, cellValue, filterValue) => String(cellValue) !== String(filterValue),
	startsWith: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase()),
	endsWith: (columnType, cellValue, filterValue) =>
		String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase()),
	isEmpty: (columnType, cellValue) => !cellValue || String(cellValue).trim() === '',
	isNotEmpty: (columnType, cellValue) => Boolean(cellValue) && String(cellValue).trim() !== '',
	isAnyOf: (columnType, cellValue, filterValue) => filterValue.includes(cellValue),

	// Number Operators
	'=': (columnType, cellValue, filterValue) => Number(cellValue) === Number(filterValue),
	'!=': (columnType, cellValue, filterValue) => Number(cellValue) !== Number(filterValue),
	'>': (columnType, cellValue, filterValue) => Number(cellValue) > Number(filterValue),
	'>=': (columnType, cellValue, filterValue) => Number(cellValue) >= Number(filterValue),
	'<': (columnType, cellValue, filterValue) => Number(cellValue) < Number(filterValue),
	'<=': (columnType, cellValue, filterValue) => Number(cellValue) <= Number(filterValue),

	// Mixed
	is: (columnType, cellValue, filterValue) => {
		if (columnType === 'date') {
			return dayjs(cellValue).isSame(dayjs(filterValue), 'day')
		}
		return String(cellValue) === filterValue
	},
	not: (columnType, cellValue, filterValue) => {
		if (columnType === 'date') {
			return !dayjs(cellValue).isSame(dayjs(filterValue), 'day')
		}
		return cellValue !== filterValue
	},

	// Date Operators (using dayjs)
	after: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isAfter(dayjs(filterValue), 'day'),
	onOrAfter: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isSame(dayjs(filterValue), 'day') ||
		dayjs(cellValue).isAfter(dayjs(filterValue), 'day'),
	before: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isBefore(dayjs(filterValue), 'day'),
	onOrBefore: (columnType, cellValue, filterValue) =>
		dayjs(cellValue).isSame(dayjs(filterValue), 'day') ||
		dayjs(cellValue).isBefore(dayjs(filterValue), 'day'),
}

function applyFilterItem(
	columnType: GridColType,
	row: GridRowModel,
	filterItem: GridFilterItem,
): boolean {
	const { field, operator, value } = filterItem
	const cellValue = row[field]

	const filterFn = operatorFunctions[operator]
	if (!filterFn) {
		return true
	}

	return filterFn(columnType, cellValue, value)
}

export const applyFilterModelToRow = (
	columns: { _id: string; type?: GridColType }[],
	row: GridRowModel,
	filterModel: GridFilterModel,
): boolean => {
	const { items } = filterModel

	return items.every((filterItem) => {
		const columnType = columns.find((column) => column._id === filterItem.field)?.type
		if (!columnType) return true
		return applyFilterItem(columnType, row, filterItem)
	})
}

export const convertRowValueToArray = (rowValue: any): TableTypes.ListOption[] => {
	if (_isArray(rowValue)) {
		return rowValue
	}
	return []
}
