import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type CameraIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const CameraIcon: ComponentType<CameraIconProps> = ({
	width = 20,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 18" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M1 6a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 1h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 4H17a2 2 0 012 2v9a2 2 0 01-2 2H3a2 2 0 01-2-2V6z"></path>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M13 10a3 3 0 11-6 0 3 3 0 016 0z"></path>
	</svg>
)
