import { ComponentType, MouseEvent } from 'react'
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { PulseLoader } from 'react-spinners'

import { AnalyticsEvent, analytics } from 'src/biz'
import { RouteId } from 'src/constants/routes'

export type IconButtonProps = MuiIconButtonProps & {
	tooltip?: string
	isLoading?: boolean
	analytics?: {
		eventName: string
		eventParameters?: Record<string, any>
		routeId: RouteId
	}
}

export const IconButton: ComponentType<IconButtonProps> = ({
	tooltip,
	size = 'medium',
	children,
	isLoading,
	onClick,
	...props
}) => {
	const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		if (!onClick) {
			return
		}
		onClick(event)
		if (props.analytics) {
			analytics.track({
				eventName: AnalyticsEvent.buttonClicked,
				properties: {
					name: props.analytics.eventName,
					screen: props.analytics.routeId,
					...(props.analytics.eventParameters ?? {}),
				},
			})
		}
	}

	const iconButton = (
		<MuiIconButton
			color="primary"
			aria-label={tooltip}
			size={size}
			onClick={handleOnClick}
			{...props}>
			{isLoading ? <PulseLoader size={4} /> : children}
		</MuiIconButton>
	)

	if (tooltip) {
		return <Tooltip title={tooltip}>{iconButton}</Tooltip>
	}

	return iconButton
}
