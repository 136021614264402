import { ComponentType, SVGProps } from 'react'

type FileViewProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const FileView: ComponentType<FileViewProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 28 28" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M16.25 2.5H7.5A2.5 2.5 0 005 5v20a2.5 2.5 0 002.5 2.5h15A2.5 2.5 0 0025 25V11.25L16.25 2.5z"></path>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M16.25 2.5v8.75H25"></path>
	</svg>
)
