import { ComponentType } from 'react'

import { Box, Modal } from '../../components'
import { VideoEmbed } from '../my-tasks-v3/components/actions/video-embed'

type VideoModalProps = {
	videoLink: string | undefined
	onClose: () => void
}

export const VideoModal: ComponentType<VideoModalProps> = ({ videoLink, onClose }) => {
	return (
		<Modal open={!!videoLink} onClose={onClose}>
			<Box width="70vw">
				<VideoEmbed
					boxProps={{
						mb: 0,
					}}
					urls={[
						{
							_id: '0',
							link: videoLink ?? '',
						},
					]}
				/>
			</Box>
		</Modal>
	)
}
