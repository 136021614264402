import React, { ComponentType, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FeedbackSdk } from '@cango-app/sdk'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Select, TextField, Box } from 'src/components'
import { errorHandler } from 'src/helpers/api'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'

enum FeedbackType {
	Idea = 'idea',
	Bug = 'bug',
}

type FeedbackFormType = {
	type: FeedbackType
	description: string
}
const feedbackOptions = [
	{ _id: FeedbackType.Idea, label: '💡 I have an idea!' },
	{ _id: FeedbackType.Bug, label: "🐞 I think I've found a bug" },
]

export const FeedbackForm: ComponentType = () => {
	const dispatch = useDispatch()
	const [isSending, setIsSending] = useState(false)
	const { control, handleSubmit } = useForm<FeedbackFormType>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const handleFormSubmit = async (data: FeedbackFormType) => {
		setIsSending(true)
		let message
		try {
			if (data.type === FeedbackType.Bug) {
				await FeedbackSdk.reportBug(import.meta.env.VITE_API as string, authHeaders, {
					description: data.description,
				})
				message = 'Thanks! Your bug has been logged!'
			} else {
				await FeedbackSdk.submitNewIdea(import.meta.env.VITE_API as string, authHeaders, {
					description: data.description,
				})
				message = 'Amazing! Your idea has been submitted'
			}
			showSnackbar(message, { variant: 'success' })
		} catch (error) {
			errorHandler({ error, dispatch })
		} finally {
			setIsSending(false)
		}
	}
	return (
		<Box mt={3}>
			<Controller
				control={control}
				name="type"
				render={({ field: { value, onChange } }) => (
					<Select
						label="Got and idea or seen a bug?"
						options={feedbackOptions}
						sx={{ mb: 2 }}
						value={value}
						onChange={onChange}
						required
					/>
				)}
				rules={{ required: true }}
			/>
			<Controller
				control={control}
				name="description"
				render={({ field: { value, onChange } }) => (
					<TextField
						label="Tell us about it"
						onChange={(event) => onChange(event.target.value)}
						value={value}
						multiline
						fullWidth
						containerProps={{ mb: 2 }}
						required
					/>
				)}
				rules={{ required: true }}
			/>
			<Button
				variant="contained"
				color="success"
				onClick={handleSubmit(handleFormSubmit)}
				sx={{ width: 170 }}
				isLoading={isSending}>
				Send
			</Button>
		</Box>
	)
}
