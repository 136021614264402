import React, { ComponentType, PropsWithChildren } from 'react'

import noisyGrid from 'src/assets/images/textures/noisy-grid.png'

import { Card } from '../card'

import { CardMenuType } from './index'

type CardContainerProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
	shouldGrey?: boolean
	menuType?: CardMenuType
}

export const CardContainer: ComponentType<PropsWithChildren<CardContainerProps>> = ({
	onClick,
	shouldGrey,
	children,
	menuType,
}) => {
	const backgroundColor = menuType === 'project' ? '#77B28C' : '#42b1ed'

	return (
		<Card
			sx={{
				width: 275,
				margin: 2,
				bgcolor: shouldGrey ? '#ccc' : backgroundColor,
				backgroundImage: `url(${noisyGrid})`,
			}}
			onClick={onClick}>
			{children}
		</Card>
	)
}
