import React, { ComponentType, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'

import { UniversalDrawerType, actions as configActions } from 'src/store/modules/config'
import { useIsMobile } from 'src/hooks/useMobile'
import { RouteId } from 'src/constants/routes'
import { ProjectsIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'

import { Box } from '../box'

import AddDrawer from './add-drawer'

export const UniversalAdd: ComponentType = () => {
	const dispatch = useDispatch()
	const isMobile = useIsMobile()
	const location = useLocation()
	const [open, setOpen] = useState(false)

	const handleSelectDrawerType = (drawerType: UniversalDrawerType) => {
		setOpen(false)
		dispatch(configActions.setUniversalDrawerType(drawerType))
	}

	const canDisplay = useMemo(() => {
		const hiddenBasePaths = [
			`/${RouteId.MyTasks}`,
			`/${RouteId.Blueprint}`,
			`/${RouteId.Inbox}`,
			`/${RouteId.Tables}`,
		]
		return !hiddenBasePaths.some((_path) => location.pathname.includes(_path))
	}, [isMobile, location.pathname])

	return (
		<>
			{!!canDisplay && (
				<Box
					sx={{
						position: 'fixed',
						bottom: '30px',
						right: '30px',
						zIndex: 1000,
					}}
				>
					<SpeedDial
						open={open}
						ariaLabel="Universal add"
						icon={<SpeedDialIcon />}
						onOpen={() => setOpen(true)}
						onClose={() => setOpen(false)}
						sx={{
							'.MuiSpeedDialAction-staticTooltipLabel': {
								fontSize: 12,
								width: '100px',
								textAlign: 'center',
							},
						}}
					>
						{open && (
							<SpeedDialAction
								icon={<ProjectsIcon fill={colors.feldgrau['80']} />}
								tooltipTitle="New project"
								tooltipOpen
								onClick={() => handleSelectDrawerType(UniversalDrawerType.Project)}
							/>
						)}
						{/* {open && (
							<SpeedDialAction
								icon={<CircledCheckIcon fill={colors.feldgrau['80']} />}
								tooltipTitle="New task"
								tooltipOpen
								onClick={() => handleSelectDrawerType(UniversalDrawerType.Task)}
							/>
						)} */}
					</SpeedDial>
				</Box>
			)}
			<AddDrawer />
		</>
	)
}
