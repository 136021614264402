import _isEqual from 'lodash/isEqual'
import _transform from 'lodash/transform'
import _isObject from 'lodash/isObject'

export const getObjectDifferences = (
	obj1: Record<string, any>,
	obj2: Record<string, any>,
): Record<string, any> => {
	return _transform(obj1, (result, value, key) => {
		if (!_isEqual(value, obj2[key])) {
			if (_isObject(value) && _isObject(obj2[key])) {
				result[key] = getObjectDifferences(value, obj2[key])
			} else {
				result[key] = value
			}
		}
	})
}
