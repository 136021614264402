import { ComponentType, SVGProps } from 'react'

type EmailProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Email: ComponentType<EmailProps> = ({ width = 30, stroke = '#886402', ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 16" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M1 4l7.89 5.26a2 2 0 002.22 0L19 4M3 15h14a2 2 0 002-2V3a2 2 0 00-2-2H3a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
	</svg>
)
