import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import * as thunks from './thunks'
import { CangoSchemaState, ConfigState, RemoteConfigState, UniversalDrawerType } from './types'

const initialState: ConfigState = {
	remoteConfig: RemoteConfigState.Idle,
	isMobileMenuOpen: false,
	cangoSchemaState: CangoSchemaState.NoActionRequired,
}

export const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		endSession: () => initialState,
		toggleMobileMenu: (state: ConfigState, action: PayloadAction<boolean | undefined>) => {
			state.isMobileMenuOpen =
				action.payload !== undefined ? action.payload : !state.isMobileMenuOpen
		},
		setCangoSchemaState: (state, action: PayloadAction<{ state: CangoSchemaState }>) => {
			state.cangoSchemaState = action.payload.state
		},
		setUniversalDrawerType: (state, action: PayloadAction<UniversalDrawerType | undefined>) => {
			state.universalDrawerType = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(thunks.updateSchema.fulfilled, (state: ConfigState) => {
				state.cangoSchemaState = CangoSchemaState.NoActionRequired
			})
			.addCase(thunks.updateSchema.pending, (state: ConfigState) => {
				state.cangoSchemaState = CangoSchemaState.Loading
			})
			.addCase(thunks.updateSchema.rejected, (state: ConfigState, action) => {
				if (action.payload) {
					state.cangoSchemaState = action.payload
				} else {
					state.cangoSchemaState = CangoSchemaState.Failed
				}
			})
	},
})
