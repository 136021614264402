import React, { ComponentType } from 'react'
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'

import { DrawerLoading } from './drawer-loading'

type DrawerProps = MuiDrawerProps & {
	isLoading?: boolean
}

export const Drawer: ComponentType<DrawerProps> = ({ children, isLoading, ...props }) => (
	<MuiDrawer variant="temporary" anchor="right" {...props}>
		{!!isLoading && <DrawerLoading />}
		{children}
	</MuiDrawer>
)
