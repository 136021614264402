import { ComponentType } from 'react'

import { colors } from 'src/theme/colors'

import { IconType } from '../index'

export const BlueprintsIcon: ComponentType<IconType> = ({
	width = 24,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={width}
		fill="none"
		viewBox="0 0 24 24"
		{...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M1 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4zM16 6v16M8 2v16"></path>
	</svg>
)
