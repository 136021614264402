import React, { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type SaveIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const SaveIcon: ComponentType<SaveIconProps> = ({
	width = 16,
	fill = colors.feldgrau['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 18 18" {...props}>
		<path
			fill={fill}
			d="M17.71 6.29l-6-6a1 1 0 00-.32-.21A1.09 1.09 0 0011 0H3a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V7a1 1 0 00-.29-.71zM6 2h4v2H6V2zm6 14H6v-3a1 1 0 011-1h4a1 1 0 011 1v3zm4-1a1 1 0 01-1 1h-1v-3a3 3 0 00-3-3H7a3 3 0 00-3 3v3H3a1 1 0 01-1-1V3a1 1 0 011-1h1v3a1 1 0 001 1h6a1 1 0 001-1V3.41l4 4V15z"></path>
	</svg>
)
