import { useCallback, useContext, useState } from 'react'
import { MenuItem, Menu, Alert, Snackbar, AlertProps } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-premium'

import { DownloadIcon } from 'src/assets/icons'
import { Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'

export const ExportTableButton = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const { table } = useContext(TableContext)
	const apiRef = useGridApiContext()
	const [warning, setWarning] = useState<{ message: string; type: AlertProps['severity'] }>({
		type: undefined,
		message: '',
	})

	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleExport = useCallback(
		(format: 'csv' | 'excel') => {
			if (!table) return
			try {
				if (format === 'csv') {
					apiRef.current.exportDataAsCsv({
						utf8WithBom: true,
						fileName: table.name,
					})
				} else {
					apiRef.current.exportDataAsExcel({
						fileName: table.name,
					})
				}
			} catch (err) {
				setWarning({
					type: 'error',
					message: "Something went wrong! We'll figure it out soon",
				})
			}
		},
		[table],
	)
	return (
		<>
			<Snackbar
				open={!!warning.message}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				autoHideDuration={6000}
				onClose={() =>
					setWarning({
						type: undefined,
						message: '',
					})
				}
			>
				<Alert severity={warning.type} sx={{ width: '60%' }}>
					{warning.message}
				</Alert>
			</Snackbar>
			<Button
				variant="text"
				startIcon={<DownloadIcon />}
				aria-controls={open ? 'export-menu-options' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : 'false'}
				onClick={handleClick}
			>
				Export
			</Button>
			<Menu
				id="export-menu-options"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuItem sx={{ width: '170px' }} onClick={() => handleExport('excel')}>
					Export Excel
				</MenuItem>
				<MenuItem sx={{ width: '170px' }} onClick={() => handleExport('csv')}>
					Export CSV
				</MenuItem>
			</Menu>
		</>
	)
}
