import React, { ComponentType, useCallback, useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import { useDispatch, useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { ChainsSdk } from '@cango-app/sdk'
import { SelectChangeEvent } from '@mui/material'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { Button } from '../button'
import { Text } from '../text'
import { Select } from '../select'

import { CardMenuType } from './index'
import { CardContainer } from './card-container'
import { LinkingDBModal } from './linking-db-modal'

type CreationCardProps = {
	menuType: CardMenuType
	onSubmit: (
		selectedBlueprintId: string,
	) => Promise<{ requestStatus: 'fulfilled' | 'rejected'; _id?: string } | void>
	onSubmitSuccess: (_id: string) => void
}

export const CreationCard: ComponentType<CreationCardProps> = ({
	menuType,
	onSubmit,
	onSubmitSuccess,
}) => {
	const dispatch = useDispatch()
	const [selectedBlueprintId, setSelectedBlueprintId] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isLinkingLoading, setIsLinkingLoading] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [selectedDB, setSelectedDB] = useState('')
	const [blueprints, setBlueprints] = useState<
		{ _id: string; label: string; database_table?: string }[]
	>([])
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const createDocument = useCallback(async () => {
		setIsLoading(true)
		const response = await onSubmit(selectedBlueprintId)
		setIsLoading(false)
		if (response?.requestStatus === 'fulfilled' && response._id) {
			onSubmitSuccess(response._id)
		}
	}, [setIsLoading, onSubmitSuccess, onSubmit, selectedBlueprintId])

	const cloneOrCreate = menuType === 'blueprint' ? 'Clone' : 'Create'
	const dropdownLabel = `${cloneOrCreate} from Blueprint`

	const handleSelectedDB = (e: SelectChangeEvent<unknown>) => {
		setSelectedDB(e.target.value as string)
	}

	const linkDatabase = useCallback(async () => {
		setIsLinkingLoading(true)
		try {
			await ChainsSdk.update(import.meta.env.VITE_API as string, authHeaders, selectedBlueprintId, {
				database_table: selectedDB,
			})
			await createDocument()
		} catch (error) {
			showSnackbar('Error selecting database', { variant: 'error' })
		} finally {
			setIsLinkingLoading(false)
		}
	}, [setIsLinkingLoading, showSnackbar, selectedDB, selectedBlueprintId, createDocument])

	const fetchBlueprints = async () => {
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'true',
			})
			setBlueprints(
				response.map(({ _id, name, database_table }) => ({ _id, label: name, database_table })),
			)
		} catch (error) {
			errorHandler({ dispatch, error })
		}
	}

	useEffect(() => {
		fetchBlueprints()
	}, [])

	return (
		<>
			<LinkingDBModal
				openModal={openModal}
				onClose={() => setOpenModal(false)}
				isLoading={isLinkingLoading}
				onSubmit={linkDatabase}
				selectedDB={selectedDB}
				setSelectedDB={handleSelectedDB}
			/>
			<CardContainer shouldGrey menuType={menuType}>
				<Text fontSize={18} fontWeight="bold" color="white" sx={{ mb: 2 }}>
					{`New ${capitalize(menuType)}`}
				</Text>
				<Select
					labelId="clone-blueprint-label"
					label={dropdownLabel}
					onChange={(event) => setSelectedBlueprintId(event.target.value as string)}
					value={selectedBlueprintId}
					sx={{ bgcolor: 'white' }}
					options={blueprints}
				/>
				{((menuType === 'project' && !!selectedBlueprintId) || menuType === 'blueprint') && (
					<Button
						onClick={() => {
							const hasBlueprintTable = blueprints.find(
								({ _id }) => _id === selectedBlueprintId,
							)?.database_table
							hasBlueprintTable ? createDocument() : setOpenModal(true)
						}}
						variant="contained"
						sx={{ my: 2, width: '100%' }}
						disabled={isLoading}
					>
						{isLoading ? (
							<PulseLoader size={8} color="white" />
						) : selectedBlueprintId ? (
							`${cloneOrCreate} ${
								blueprints.find(({ _id }) => _id === selectedBlueprintId)?.label
							} ${cloneOrCreate === 'Create' ? 'Project' : ''}`
						) : (
							`Create Blank ${menuType}`
						)}
					</Button>
				)}
			</CardContainer>
		</>
	)
}
