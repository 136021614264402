export enum RemoteConfigState {
	Idle = 'idle',
	Pending = 'pending',
	Fetched = 'fetched',
	Failed = 'failed',
}

export enum SwitchOrgState {
	NotSwitching = 'NotSwitching',
	Switching = 'Switching',
	Failed = 'Failed',
	NoOrgId = 'NoOrgId',
}

export enum CangoSchemaState {
	NoActionRequired = 'no_action_required',
	RequiresMigration = 'requires_migration',
	MigrationInProgress = 'migration_in_progress',
	Loading = 'loading',
	Failed = 'failed',
}

export enum UniversalDrawerType {
	Task = 'task',
	Project = 'project',
	Feedback = 'feedback',
	Contact = 'contact',
}

export interface ConfigState {
	_id?: string
	name?: string
	environment?: string
	selectedOrganisationId?: string
	remoteConfig: RemoteConfigState
	isMobileMenuOpen: boolean
	cangoSchemaState: CangoSchemaState
	universalDrawerType?: UniversalDrawerType
}
