import { ClientTypes } from '@cango-app/types'
import { ComponentType, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import every from 'lodash/every'

import { Box, FormCard, Select, Text } from 'src/components'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as rolesSelectors } from 'src/store/modules/roles'
import { selectors as contactsSelectors } from 'src/store/modules/contacts'
import { colors } from 'src/theme/colors'

type EmailProps = {
	taskRoles: {
		role: string
		user?: string
	}[]
	taskExternals: {
		role: string
		contact?: string
	}[]
	onAssignRole: (roleId: string, userId: string) => Promise<void>
	onAssignExternal: (roleId: string, contactId: string) => Promise<void>
}

type ContactProps = {
	type: 'contact'
	contact: ClientTypes.Contact
}

type UserProps = {
	type: 'user'
	contact: ClientTypes.User
}

const AssignedContact: ComponentType<ContactProps | UserProps> = ({ type, contact }) => {
	return (
		<Box>
			<Text>
				<b>Name:</b> {contact.name} {contact.surname}
			</Text>
			{type === 'contact' && !!contact.company && (
				<Text>
					<b>Company:</b> {contact.company}
				</Text>
			)}
			{!!contact.phone && (
				<Text>
					<b>Phone:</b> {contact.phone}
				</Text>
			)}
			{!!contact.email && (
				<Text>
					<b>Email:</b> {contact.email}
				</Text>
			)}
			{type === 'contact' && !!contact.address?.line1 && (
				<Text>
					<b>Address:</b> {contact.address.line1}, {contact.address?.line2 || ''},{' '}
					{contact.address?.line3 || ''},
				</Text>
			)}
		</Box>
	)
}

export const ContactAndAssignment: ComponentType<EmailProps> = ({
	taskExternals,
	taskRoles,
	onAssignExternal,
	onAssignRole,
}) => {
	const users = useSelector(userSelectors.getAllUsersForSelect)
	const contacts = useSelector(contactsSelectors.getContactsForSelect)
	const mappedRoles = useSelector(rolesSelectors.getMappedRoles)
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	const mappedContacts = useSelector(contactsSelectors.mappedContacts)
	const [roleSaving, setRoleSaving] = useState('')

	const allRolesAssigned = useMemo(() => {
		return every(taskRoles, ({ user }) => !!user)
	}, [JSON.stringify(taskRoles)])

	const allContactsAssigned = useMemo(() => {
		return every(taskExternals, ({ contact }) => !!contact)
	}, [JSON.stringify(taskExternals)])

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		setRoleSaving(roleId)
		await onAssignRole(roleId, userId)
		setRoleSaving('')
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		setRoleSaving(roleId)
		await onAssignExternal(roleId, contactId)
		setRoleSaving('')
	}

	if (!allRolesAssigned) {
		return (
			<Box>
				{taskRoles.map(({ role: roleId, user: userId }) => {
					const role = mappedRoles.get(roleId)
					if (!role) return null
					return (
						<Box key={roleId}>
							<Select
								label={`Assign user for ${role.label}`}
								options={users}
								onChange={(event) => handleRoleAssignChange(role._id, String(event.target.value))}
								value={userId}
								disabled={!!roleSaving}
							/>
							{roleSaving === role._id && (
								<Text fontSize={12} color={colors.neutral['60']}>
									Saving...
								</Text>
							)}
						</Box>
					)
				})}
			</Box>
		)
	}

	if (!allContactsAssigned) {
		return (
			<Box>
				{taskExternals.map(({ role: roleId, contact: contactId }) => {
					const role = mappedRoles.get(roleId)
					if (!role) return null
					return (
						<Select
							key={roleId}
							label={`Assign contact for ${role.label}`}
							options={contacts}
							onChange={(event) => handleExternalAssignChange(role._id, String(event.target.value))}
							value={contactId || role.defaultContact}
							disabled={!!roleSaving}
						/>
					)
				})}
			</Box>
		)
	}

	return (
		<Box>
			{taskRoles.map(({ role: roleId, user: userId }) => {
				const role = mappedRoles.get(roleId)
				const user = mappedUsers.get(userId || '')
				if (!user || !role) {
					return null
				}
				return (
					<FormCard key={roleId} title={role.label} mb={2}>
						<AssignedContact key={role._id} contact={user} type="user" />
					</FormCard>
				)
			})}
			{taskExternals.map(({ role: roleId, contact: contactId }) => {
				const role = mappedRoles.get(roleId)
				const contact = mappedContacts.get(contactId || '')
				if (!contact || !role) {
					return null
				}
				return (
					<FormCard key={role._id} title={role.label} mb={2}>
						<AssignedContact key={role._id} contact={contact} type="contact" />
					</FormCard>
				)
			})}
		</Box>
	)
}
