import { ComponentType, SVGProps } from 'react'

type CallProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Call: ComponentType<CallProps> = ({ width = 28, stroke = '#886402', ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 28 28" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M26.5 20.15v3.75a2.5 2.5 0 01-2.725 2.5 24.737 24.737 0 01-10.787-3.837 24.375 24.375 0 01-7.5-7.5A24.737 24.737 0 011.65 4.225 2.5 2.5 0 014.138 1.5h3.75a2.5 2.5 0 012.5 2.15c.158 1.2.451 2.379.874 3.513A2.5 2.5 0 0110.7 9.8l-1.587 1.588a20 20 0 007.5 7.5L18.2 17.3a2.5 2.5 0 012.637-.562c1.135.423 2.313.716 3.513.875a2.5 2.5 0 012.15 2.537z"></path>
	</svg>
)
