import React, { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { ClientTypes, ContactTypes } from '@cango-app/types'

import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { Box, Button, Text, TextField, Select, Toggle } from 'src/components'
import { actions as roleActions, RoleWithUsage } from 'src/store/modules/roles'

import { AsyncDispatchType } from '../../../store/types'

type RoleFormProps = {
	role?: RoleWithUsage
	closeDrawer: () => void
	setShouldPromptDelete: (tf: boolean) => void
	onNewRoleAdded?: (roleId: string) => void
}

type RoleFormType = Omit<ClientTypes.Role, '_id'>

export const RoleForm: ComponentType<RoleFormProps> = ({
	role,
	closeDrawer,
	setShouldPromptDelete,
	onNewRoleAdded,
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isLoading, setIsLoading] = useState(false)
	const { handleSubmit, control, watch } = useForm<RoleFormType>({
		defaultValues: {
			label: role?.label,
			internal: role?.internal,
			defaultUser: role?.defaultUser,
			defaultContact: role?.defaultContact,
		},
	})

	const [selectedInternalExternal] = watch(['internal'])

	const users = useSelector(userSelectors.getAllUsersForSelect)
	const contacts = useSelector(contactSelectors.getContactsForSelect)

	const onSubmit = async (roleData: RoleFormType) => {
		setIsLoading(true)
		roleData.internal = !!roleData.internal
		if (role?._id) {
			const updateRoleResponse = await dispatch(
				roleActions.updateRole({ _id: role._id, ...roleData }),
			)
			if (updateRoleResponse.meta.requestStatus === 'fulfilled') {
				closeDrawer()
			}
		} else {
			const addRoleResponse = await dispatch(roleActions.addRole(roleData))
			if (addRoleResponse.meta.requestStatus === 'fulfilled') {
				if (
					onNewRoleAdded &&
					typeof addRoleResponse.payload !== 'string' &&
					typeof addRoleResponse.payload !== 'undefined'
				) {
					onNewRoleAdded(addRoleResponse.payload._id)
				}
				closeDrawer()
			}
		}
		setIsLoading(false)
	}

	return (
		<Box width={400} p={3} role="presentation">
			<Text sx={{ mb: 3 }} variant="h4">
				{role ? role.label : 'New Role'}
			</Text>
			<form>
				<Controller
					name={'internal'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Toggle
							options={[
								{ label: 'Internal Role', value: true },
								{ label: 'External Role', value: false },
							]}
							value={value}
							onChange={onChange}
							containerProps={{ mb: 1 }}
							formControlProps={{
								error: !!error,
							}}
						/>
					)}
				/>
				<Controller
					name={'label'}
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							error={!!error}
							fullWidth
							required
							onChange={onChange}
							value={value}
							label={'Role name'}
							containerProps={{ mb: 3, flex: 2 }}
						/>
					)}
				/>
				{selectedInternalExternal ? (
					<Controller
						name={'defaultUser'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Who is the default user for this role?"
								onChange={onChange}
								value={value}
								options={users}
							/>
						)}
					/>
				) : (
					<Controller
						name={'defaultContact'}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Who is the default contact for this role?"
								onChange={onChange}
								value={value}
								options={contacts.filter(({ type }) => type !== ContactTypes.ContactType.Internal)}
							/>
						)}
					/>
				)}
				<br />
				<Button variant="contained" onClick={handleSubmit(onSubmit)}>
					{role ? 'Update' : 'Create'}
				</Button>
				{role && (
					<Button
						onClick={() => setShouldPromptDelete(true)}
						variant="outlined"
						disabled={(role.usage ?? 0) > 0}
						color="error"
						sx={{ float: 'right' }}
						isLoading={isLoading}
					>
						{(role.usage ?? 0) > 0 ? 'Role in use' : 'Delete'}
					</Button>
				)}
			</form>
		</Box>
	)
}
