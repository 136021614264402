import { ComponentType, SVGProps } from 'react'

type ChevronRightProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const ChevronRight: ComponentType<ChevronRightProps> = ({
	width = 9,
	stroke = '#7D7D7D',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 9 15" {...props}>
		<path stroke={stroke} strokeLinecap="round" d="M1.115 13.758L7.73 7.285 1 1.06"></path>
	</svg>
)
