import React, { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type CheckSquareProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const CheckSquare: ComponentType<CheckSquareProps> = ({
	width = 16,
	stroke = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M9 11l3 3L22 4"
		></path>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
		></path>
	</svg>
)
