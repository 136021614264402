import * as React from 'react'
import { SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type TagIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}
export const TagIcon: React.ComponentType<TagIconProps> = ({
	width = 24,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 0 1 0 2.828l-7 7a2 2 0 0 1-2.828 0l-7-7A1.994 1.994 0 0 1 3 12V7a4 4 0 0 1 4-4Z"
		/>
	</svg>
)
