import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { TablesSdk } from '@cango-app/sdk'
import { TableTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import _isEmpty from 'lodash/isEmpty'
import { useGridApiRef } from '@mui/x-data-grid-premium'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { resolveAnyRowCalculations } from 'src/modules/tables/utils'

import { Modal } from '../modal'
import { Box } from '../box'
import { TableContext } from '../../providers/table-provider'
import { Text } from '../text'
import { colors } from '../../theme/colors'
import { Button } from '../button'
import { showSnackbar } from '../../helpers/snackbarManager'
import { DisplayView } from '../display-view'
import { selectors as contactSelectors } from '../../store/modules/contacts'
import { PresentationView } from '../display-view/presentation-view'

import { ColumnsAndFilters } from './columns-and-filters'
import { ViewSettings } from './view-settings'
import { getDefaultValues } from './getDefaultValues'
import { GroupingChildren } from './grouping-children'

const DisplayTable: ComponentType = () => {
	const { watch } = useFormContext<TableTypes.TableView>()
	const formFields = watch()
	const apiRef = useGridApiRef()
	const { table, mappedColumns } = useContext(TableContext)

	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const rows = useMemo(() => {
		if (!table) return []
		return table.records.map((_record) => {
			const resolvedRow = resolveAnyRowCalculations({
				fields: table.fields,
				row: _record,
				contacts: mappedContacts,
			})
			// this logic will be improved in ticket https://cango.atlassian.net/jira/software/projects/CANGO/boards/1?selectedIssue=CANGO-1106
			table.fields.forEach(({ _id, valueOptions }) => {
				const valueOption = valueOptions?.find(
					(valueOption) => valueOption._id === resolvedRow[_id],
				)?.label

				if (valueOption) {
					resolvedRow[_id] = valueOption
				}
			})
			return resolvedRow
		})
	}, [table, mappedContacts])
	if (!table) return null

	if (formFields.displayMode === TableTypes.ViewStyleMode.editable) {
		return <DisplayView {...formFields} apiRef={apiRef} />
	}

	return <PresentationView rows={rows} columns={[...mappedColumns.values()]} view={formFields} />
}

const ViewForm: ComponentType<{
	onViewSave: (data: { views: TableTypes.TableView[]; newViewId: string | undefined }) => void
	viewId: string | undefined
}> = ({ onViewSave, viewId }) => {
	const { table } = useContext(TableContext)
	const formControl = useForm<TableTypes.TableView>({
		defaultValues: getDefaultValues({ tableViews: table?.views ?? [], viewId }),
	})
	const {
		handleSubmit,
		formState: { errors },
	} = formControl

	const [expandedStage, setExpandedStage] = useState<number | false>(0)
	const [isSavingView, setIsSavingView] = useState(false)

	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const handleAccordionChange =
		(panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpandedStage(newExpanded ? panel : false)
		}

	const handleSaveView = async (data: TableTypes.TableView) => {
		if (!table) return
		setIsSavingView(true)

		if (data.displayMode === TableTypes.ViewStyleMode.presentation) {
			data.displayChildren = true
		}

		try {
			let updatedViews: { views: TableTypes.TableView[]; newViewId: string | undefined }
			if (viewId) {
				const response = await TablesSdk.updateView(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					viewId,
					data,
				)
				updatedViews = {
					views: response,
					newViewId: undefined,
				}
			} else {
				updatedViews = await TablesSdk.createView(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					data,
				)
			}
			onViewSave(updatedViews)
		} catch (err) {
			showSnackbar('Error creating view', { variant: 'error' })
		} finally {
			setIsSavingView(false)
		}
	}

	return (
		<FormProvider {...formControl}>
			<Box
				width="95vw"
				height="88vh"
				display="flex"
				flexDirection="column"
				position="relative"
				py={2}
			>
				<Box height="80%" sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 4 }}>
					<Accordion expanded={expandedStage === 0} onChange={handleAccordionChange(0)}>
						<AccordionSummary>
							<Text variant="h6">View settings</Text>
						</AccordionSummary>
						<AccordionDetails>
							<ViewSettings />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expandedStage === 1} onChange={handleAccordionChange(1)}>
						<AccordionSummary>
							<Text variant="h6">Filters</Text>
						</AccordionSummary>
						<AccordionDetails>
							<ColumnsAndFilters viewId={viewId} />
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expandedStage === 5}
						onChange={handleAccordionChange(5)}
						sx={{ mb: 2 }}
					>
						<AccordionSummary>
							<Text variant="h6">Grouping and Aggregations</Text>
						</AccordionSummary>
						<AccordionDetails>
							<GroupingChildren />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded>
						<AccordionSummary>
							<Text variant="h6">Preview</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Box height="auto">
								<FormProvider {...formControl}>
									<DisplayTable />
								</FormProvider>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
				<Box position="fixed" bottom={8}>
					<Button onClick={handleSubmit(handleSaveView)} isLoading={isSavingView} sx={{ mt: 2 }}>
						Save view
					</Button>
					{!_isEmpty(errors) && (
						<Text color="red" fontSize={14} mt={1}>
							Missing fields
						</Text>
					)}
				</Box>
			</Box>
		</FormProvider>
	)
}

const CreateViewLoadingContainer: ComponentType<{
	viewId: string | undefined
	onViewSave: (data: { views: TableTypes.TableView[]; newViewId: string | undefined }) => void
}> = ({ viewId, onViewSave }) => {
	const { table, isLoadingTable } = useContext(TableContext)

	if (isLoadingTable || !table) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center">
				<PulseLoader />
			</Box>
		)
	}

	return <ViewForm onViewSave={onViewSave} viewId={viewId} />
}

const CreateViewContainer: ComponentType<{
	open: boolean
	onClose: () => void
	viewId: string | undefined
	onViewSave: (data: { views: TableTypes.TableView[]; newViewId: string | undefined }) => void
}> = ({ open, onClose, viewId, onViewSave }) => {
	const { table } = useContext(TableContext)

	const viewName = useMemo(() => {
		if (!table) return ''

		if (!viewId) {
			return 'Create view'
		}

		const view = table.views.find((view) => view._id === viewId)

		if (!view) {
			return 'Edit view'
		}

		return view.name
	}, [table?.views, viewId])

	return (
		<Modal
			open={open}
			onClose={onClose}
			withCloseButton
			containerStyle={{ bgcolor: colors.neutral['10'], maxHeight: 'auto', py: 2 }}
		>
			<Box>
				<Text variant="h5">{viewName}</Text>
				<CreateViewLoadingContainer onViewSave={onViewSave} viewId={viewId} />
			</Box>
		</Modal>
	)
}

export default CreateViewContainer
