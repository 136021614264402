import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type ActiveTasksInboxIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const ActiveTasksInboxIcon: ComponentType<ActiveTasksInboxIconProps> = ({
	width = 24,
	stroke = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeWidth="2"
			fill="none"
			d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
	</svg>
)
