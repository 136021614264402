import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type CycleIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const CycleIcon: ComponentType<CycleIconProps> = ({
	width = 20,
	stroke = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 20" {...props}>
		<path
			fill={stroke}
			d="M3.5 15.5H2v-11h7.8l-.8.79a1 1 0 001.41 1.42l2.5-2.5a1 1 0 000-1.42l-2.5-2.5A1 1 0 109 1.71l.79.79H1a1 1 0 00-1 1v13a1 1 0 001 1h2.5a1 1 0 000-2zM19 2.5h-2.5a1 1 0 100 2H18v11H9.63l.79-.79A1.002 1.002 0 009.715 13a1 1 0 00-.705.29l-2.5 2.5a1 1 0 000 1.42l2.5 2.5a1 1 0 101.41-1.42l-.79-.79H19a1 1 0 001-1v-13a1 1 0 00-1-1z"
		></path>
	</svg>
)
