import React, { ComponentType, useContext, useState } from 'react'
import {
	DataGridPremium,
	GridCallbackDetails,
	GridFilterModel,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridInitialState,
} from '@mui/x-data-grid-premium'

import { TableContext } from '../providers/table-provider'
import { useColumnFormatter } from '../modules/tables/use-column-formatter'

import { Box } from './box'

type TableFiltersProps = {
	onChange: (model: GridFilterModel, details: GridCallbackDetails) => void
	initialState?: GridInitialState
}

const CustomToolbar: ComponentType<{
	setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
}> = ({ setFilterButtonEl }) => {
	return (
		<GridToolbarContainer>
			<GridToolbarFilterButton ref={setFilterButtonEl} />
		</GridToolbarContainer>
	)
}

const slots = {
	toolbar: CustomToolbar,
}

export const TableFilters: ComponentType<TableFiltersProps> = ({ onChange, initialState }) => {
	const { apiRef } = useContext(TableContext)
	const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null)

	const { columns } = useColumnFormatter({
		isTableLocked: true,
		apiRef,
		sortingModel: [],
		isBulkEditEnabled: false,
	})

	return (
		<Box width={190}>
			<DataGridPremium
				initialState={initialState}
				rows={[]}
				columns={columns}
				hideFooter
				sx={{
					'& .MuiDataGrid-main': {
						display: 'none',
					},
					'& .MuiDataGrid-root': {
						border: 'none !important',
					},
					'& .MuiDataGrid-toolbarContainer': {
						padding: 1,
					},
				}}
				disableColumnSelector
				disableDensitySelector
				onFilterModelChange={onChange}
				slots={slots}
				slotProps={{
					toolbar: {
						setFilterButtonEl,
					},
					panel: {
						anchorEl: filterButtonEl,
					},
				}}
			/>
		</Box>
	)
}
