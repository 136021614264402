import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { Box } from 'src/components'

type LinkProps = {
	link?: string
}

export const LinkBox: React.ComponentType<LinkProps> = ({ link }) => {
	return (
		<Box>
			{link ? (
				<a
					rel="noreferrer"
					target="_blank"
					href={link?.startsWith('www') ? `https://${link}` : link}>
					<OpenInNewIcon /> Open
				</a>
			) : (
				'No link provided'
			)}
		</Box>
	)
}
