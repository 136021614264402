import * as React from 'react'

export const DocumentUpload: React.ComponentType<{ size?: number }> = ({ size = 100 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 256 256">
		<g fill="none" strokeMiterlimit="10" strokeWidth="0">
			<path
				fill="#000"
				d="M81.675 67.955a1.499 1.499 0 01-2.115.164l-3.479-2.977v8.674a1.5 1.5 0 01-3 0v-8.674l-3.479 2.976a1.5 1.5 0 11-1.951-2.279l5.954-5.095c.009-.008.021-.01.03-.018.027-.022.053-.045.081-.065.052-.037.108-.067.165-.097.034-.018.066-.039.101-.055.021-.009.044-.016.066-.024.022-.009.045-.013.067-.021.029-.01.058-.02.088-.027.023-.006.046-.017.069-.021a1.48 1.48 0 01.307-.032h.002c.105 0 .208.012.307.032.024.005.046.015.069.021.03.008.059.018.088.027.023.007.045.012.067.021.022.008.045.015.066.024.035.015.067.037.1.055.057.03.113.06.165.097.028.02.054.043.081.065.009.008.021.01.03.017l5.955 5.095a1.5 1.5 0 01.166 2.117z"
				transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
			<path
				d="M63.089 1.483H15.755a2.808 2.808 0 00-2.808 2.808v81.418a2.808 2.808 0 002.808 2.808h58.49a2.808 2.808 0 002.808-2.808V14.927L63.089 1.483zm2.807 13.444a2.808 2.808 0 01-2.808-2.808V1.483l13.964 13.444H65.896zm-43.972 3.398h22.668m-22.668 9.89h47.294m-47.294 9.89h47.294m-47.294 9.89h47.294m-47.294 9.89h22.668"
				transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
			<path
				fill="#000"
				d="M21.924 56.401a1.483 1.483 0 100 2.968h22.668a1.484 1.484 0 000-2.968H21.924zM21.924 29.698h47.294a1.484 1.484 0 000-2.968H21.924a1.483 1.483 0 100 2.968zM70.702 47.995c0-.819-.664-1.484-1.484-1.484H21.924a1.483 1.483 0 100 2.968h47.294a1.485 1.485 0 001.484-1.484zM21.924 19.808h22.668a1.484 1.484 0 000-2.968H21.924a1.483 1.483 0 100 2.968z"
				transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
			<path
				fill="#000"
				d="M75.569 83.678v2.031c0 .729-.594 1.323-1.324 1.323h-58.49c-.73 0-1.324-.594-1.324-1.323V4.291c0-.73.594-1.324 1.324-1.324h45.851v9.153a4.296 4.296 0 004.29 4.291h9.673V52.02a15.88 15.88 0 012.967.566V14.94a1.574 1.574 0 00-.018-.248l-.001-.001v-.003l-.001-.003a1.28 1.28 0 00-.026-.123c-.001-.001.002-.003-.001-.003l-.001-.004a1.483 1.483 0 00-.392-.68h-.001l-.001-.001c-.007-.001-.002-.001-.003-.002.003-.005-.002-.002-.002-.002-.002-.001.001-.001-.003-.002.001-.001-.002-.001-.002-.002l-.003-.002L64.118.415l-.003-.003-.003-.003a1.488 1.488 0 00-.816-.394h-.001L63.29.014H63.276l-.005-.001c-.064-.01-.14-.01-.192-.013H15.755a4.296 4.296 0 00-4.291 4.291V85.71A4.296 4.296 0 0015.755 90h58.49a4.296 4.296 0 004.291-4.29v-2.598a15.8 15.8 0 01-2.967.566zM64.573 4.971l8.8 8.473h-7.477a1.325 1.325 0 01-1.323-1.324V4.971z"
				transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
			<path
				fill="#000"
				d="M21.924 39.588h47.294a1.484 1.484 0 000-2.968H21.924a1.483 1.483 0 100 2.968zM74.096 54.945C81.211 54.945 87 60.734 87 67.849s-5.789 12.904-12.904 12.904-12.904-5.789-12.904-12.904c.001-7.115 5.789-12.904 12.904-12.904m0-3c-8.783 0-15.904 7.12-15.904 15.904s7.12 15.904 15.904 15.904C82.88 83.753 90 76.632 90 67.849s-7.12-15.904-15.904-15.904z"
				transform="matrix(2.81 0 0 2.81 1.407 1.407)"></path>
		</g>
	</svg>
)
