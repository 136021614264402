import { authSlice } from './reducer'
import * as thunks from './thunks'

export { selectors } from './selectors'
export * from './types'

export const actions = {
	...authSlice.actions,
	...thunks,
}

export const reducer = authSlice.reducer
