import React, { ComponentType } from 'react'

import { useIsMobile } from 'src/hooks/useMobile'

import { DriveUploadPanelWeb } from './drive-upload-panel-web'
import { DriveUploadPanelMobile } from './drive-upload-panel-mobile'
import { DriveUploadPanelProps } from './types'

export const DriveUploadPanelContainer: ComponentType<DriveUploadPanelProps> = (props) => {
	const {
		isOpen,
		setIsOpen,
		onlyUpload = false,
		parentFolderId,
		parentFolderName,
		onFileIdsChange,
		selectedFilesIds,
		...parentFolderFilesProps
	} = props
	const isMobile = useIsMobile()

	if (!isOpen) {
		return null
	}

	if (isMobile) {
		return (
			<DriveUploadPanelMobile
				{...parentFolderFilesProps}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				onlyUpload={onlyUpload}
				selectedFilesIds={selectedFilesIds}
				parentFolderId={parentFolderId}
				onFileIdsChange={onFileIdsChange}
				parentFolderName={parentFolderName}
			/>
		)
	}

	return (
		<DriveUploadPanelWeb
			{...parentFolderFilesProps}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			onlyUpload={onlyUpload}
			parentFolderId={parentFolderId}
			onFileIdsChange={onFileIdsChange}
			parentFolderName={parentFolderName}
			selectedFilesIds={selectedFilesIds}
		/>
	)
}
