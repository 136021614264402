import { ComponentType } from 'react'

import { colors } from 'src/theme/colors'

import { IconType } from '../index'
export const TemplatesIcon: ComponentType<IconType> = ({
	width = 24,
	stroke = colors.neutral['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6ZM16 17H8M16 13H8M10 9H8"
		/>
		<path
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M14 2v6h6"
		/>
	</svg>
)
