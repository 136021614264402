import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridSlotsComponentsProps,
	GridToolbarDensitySelector,
	useGridSelector,
	gridColumnsStateSelector,
} from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import RestoreIcon from '@mui/icons-material/Restore'
import { ChainTypes } from '@cango-app/types'
import { Menu, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import Badge from '@mui/material/Badge'

import { PlusIcon, SaveIcon } from 'src/assets/icons'
import { Box, Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { colors } from 'src/theme/colors'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import { ExportTableButton } from './export-table-button'
import { RfaModal } from './rfa/rfa-modal'
import { ActionsButton } from './actions-button'
import { ViewsButton } from './views-button'

const ToolbarActions: ComponentType<{
	hideToolbar: boolean
	onCtaSelect: (cta: ChainTypes.DatabaseCTAsChains) => void
	isLocked: boolean
}> = ({ hideToolbar, onCtaSelect, isLocked }) => {
	const { unsavedChanges, isUpdatingTable, saveChanges, discardAllChanges, apiRef } =
		useContext(TableContext)
	const databaseCtas = useSelector(projectSelectors.getProjectDatabaseCtas)
	const [openCtaMenu, setOpenCtaMenu] = useState<HTMLElement | null>(null)
	const [createViewParams, setCreateView] = useState<{
		open: boolean
		viewId?: string
	}>({ open: false })
	const isSaveDisabled = _isEmpty(unsavedChanges.unsavedRows) || isUpdatingTable

	const handleDiscardChanges = () => {
		if (!apiRef) {
			return
		}
		const resetChanges = _map(unsavedChanges.rowsBeforeChange, (row, id) => ({ ...row, id }))
		const resetNewRows = _map(unsavedChanges.newRows, (row, id) => ({
			id,
			_id: id,
			_action: 'delete',
		}))
		apiRef.current.updateRows([...resetChanges, ...resetNewRows])
		discardAllChanges()
	}

	if (hideToolbar) {
		return null
	}

	return (
		<Box display="flex" flexWrap="wrap" justifyContent="flex-end">
			{databaseCtas.length > 0 && (
				<>
					{databaseCtas.length === 1 ? (
						databaseCtas.map((cta) => {
							return (
								<Button key={cta._id} variant="text" onClick={() => onCtaSelect(cta)}>
									{cta.label}
								</Button>
							)
						})
					) : (
						<>
							<Button
								variant="text"
								onClick={(event) => setOpenCtaMenu(event.currentTarget)}
								id="multiple-rfas"
								aria-controls={openCtaMenu ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openCtaMenu ? 'true' : undefined}
							>
								RFAs
							</Button>
							<Menu
								open={Boolean(openCtaMenu)}
								anchorEl={openCtaMenu}
								onClose={() => setOpenCtaMenu(null)}
								MenuListProps={{
									'aria-labelledby': 'multiple-rfas',
								}}
							>
								{databaseCtas.map((cta) => {
									return (
										<MenuItem
											key={cta._id}
											onClick={() => {
												onCtaSelect(cta)
												setOpenCtaMenu(null)
											}}
										>
											{cta.label}
										</MenuItem>
									)
								})}
							</Menu>
						</>
					)}
				</>
			)}
			<ExportTableButton />
			<ActionsButton />
			<ViewsButton
				openViewParams={createViewParams}
				onViewSelect={(viewId) => setCreateView({ open: true, viewId })}
				onClose={() => setCreateView({ open: false })}
			/>
			{!isLocked && (
				<Box>
					<Button
						variant="text"
						disabled={isSaveDisabled}
						startIcon={
							<SaveIcon fill={isSaveDisabled ? 'rgba(0, 0, 0, 0.26)' : colors.feldgrau['80']} />
						}
						isLoading={isUpdatingTable}
						onClick={saveChanges}
					>
						Save
					</Button>
					<Button
						variant="text"
						startIcon={<RestoreIcon />}
						disabled={_isEmpty(unsavedChanges.unsavedRows) || isUpdatingTable}
						onClick={handleDiscardChanges}
					>
						Discard all changes
					</Button>
				</Box>
			)}
		</Box>
	)
}

export const CustomToolbar: ComponentType<NonNullable<GridSlotsComponentsProps['toolbar']>> = ({
	isStatic,
	isLocked,
	hideToolbar,
	setFilterButtonEl,
	filters,
	hideColumnSelector = false,
	hideDensitySelector = false,
	hideFilterSelector = false,
}) => {
	const { apiRef, onAddColumn } = useContext(TableContext)
	const columns = useGridSelector(apiRef, gridColumnsStateSelector)
	const columnFilters = columns.columnVisibilityModel
	const hasColumnFiltersApplied = columnFilters
		? Object.keys(columnFilters).some((_filter) => !columnFilters[_filter])
		: false
	const databaseCtas = useSelector(projectSelectors.getProjectDatabaseCtas)
	const [selectedCta, setSelectedCta] = useState<ChainTypes.DatabaseCTAsChains>()

	const handleAddColumn = async () => {
		await onAddColumn()
		if (!apiRef) {
			return
		}
	}

	return (
		<>
			{databaseCtas.length > 0 && (
				<RfaModal cta={selectedCta} onClose={() => setSelectedCta(undefined)} filters={filters} />
			)}

			<GridToolbarContainer sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
				{(!isStatic || !hideToolbar) && (
					<Box
						display="flex"
						justifyContent="space-between"
						width="100%"
						className="editting-toolbar"
					>
						{!isStatic && (
							<Box display="flex">
								<Box>
									<Button
										variant="text"
										size="small"
										sx={{ minWidth: 175, px: 2 }}
										startIcon={<PlusIcon width={16} />}
										onClick={handleAddColumn}
									>
										New column
									</Button>
								</Box>
							</Box>
						)}
						<ToolbarActions
							hideToolbar={!!hideToolbar}
							onCtaSelect={setSelectedCta}
							isLocked={!!isLocked}
						/>
					</Box>
				)}
				<Box className="filter-toolbar" display="flex">
					{!hideDensitySelector && (
						<GridToolbarDensitySelector
							slotProps={{
								button: {
									variant: 'text',
									sx: { minWidth: 100 },
								},
							}}
						/>
					)}
					{!hideColumnSelector && (
						<GridToolbarColumnsButton
							slotProps={{
								button: {
									variant: 'text',
									sx: { minWidth: 100 },
									startIcon: hasColumnFiltersApplied ? (
										<Badge badgeContent={'!'} color="primary">
											<ViewWeekIcon />
										</Badge>
									) : (
										<ViewWeekIcon />
									),
								},
							}}
						/>
					)}
					{!hideFilterSelector && (
						<GridToolbarFilterButton
							ref={setFilterButtonEl}
							slotProps={{
								button: {
									variant: 'text',
									sx: { minWidth: 100 },
								},
							}}
						/>
					)}
				</Box>
			</GridToolbarContainer>
		</>
	)
}
