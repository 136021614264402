import { ComponentType } from 'react'
import classNames from 'classnames'
import { BoxProps } from '@mui/material/Box'

import 'src/assets/css/PulsatingDot.scss'

import { Box } from './box'

export const PulsatingDot: ComponentType<{
	variant?: 'success' | 'error' | 'warning'
	size?: 'large' | 'medium' | 'small'
	containerStyle?: BoxProps
}> = ({ variant = 'success', size = 'medium', containerStyle }) => {
	const dotClassnames = classNames(`pulsatingDot--${size}`, {
		success: variant === 'success',
		error: variant === 'error',
		warning: variant === 'warning',
	})

	return (
		<Box {...containerStyle}>
			<div className={dotClassnames} />
		</Box>
	)
}
