import { roleSlice } from './reducer'
import * as thunks from './thunks'

export { selectors } from './selectors'
export * from './types'
export const actions = {
	...roleSlice.actions,
	...thunks,
}
export const reducer = roleSlice.reducer
