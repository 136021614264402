import React, { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type DatabaseProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const DatabaseIcon: ComponentType<DatabaseProps> = ({
	width = 16,
	fill = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 20" {...props}>
		<path
			fill={fill}
			d="M13 15a1 1 0 100 2 1 1 0 000-2zm-6 0H4a1 1 0 000 2h3a1 1 0 000-2zm9 0a1 1 0 100 2 1 1 0 000-2zm-3-6a1 1 0 100 2 1 1 0 000-2zM7 9H4a1 1 0 000 2h3a1 1 0 000-2zm9-6a1 1 0 100 2 1 1 0 000-2zm0 6a1 1 0 100 2 1 1 0 000-2zm4-6a3 3 0 00-3-3H3a3 3 0 00-3 3v2a3 3 0 00.78 2A3 3 0 000 9v2a3 3 0 00.78 2A3 3 0 000 15v2a3 3 0 003 3h14a3 3 0 003-3v-2a3 3 0 00-.78-2 3 3 0 00.78-2V9a3 3 0 00-.78-2A3 3 0 0020 5V3zm-2 14a1 1 0 01-1 1H3a1 1 0 01-1-1v-2a1 1 0 011-1h14a1 1 0 011 1v2zm0-6a1 1 0 01-1 1H3a1 1 0 01-1-1V9a1 1 0 011-1h14a1 1 0 011 1v2zm0-6a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h14a1 1 0 011 1v2zm-5-2a1 1 0 100 2 1 1 0 000-2zM7 3H4a1 1 0 000 2h3a1 1 0 000-2z"></path>
	</svg>
)
