import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAndActivate, getAll, isSupported } from 'firebase/remote-config'
import * as Sentry from '@sentry/react'

import { convertRemoteConfigValues, firebaseRemoteConfig } from 'src/helpers/firebase'

import rcDefaults from '../../../constants/remote_config_defaults.json'

import { RemoteConfigState } from './types'

const fetchRemoteConfigFromFirebase = async (): Promise<RemoteConfigState['configs']> => {
	const browserSupportsRemoteConfig = await isSupported()
	if (!browserSupportsRemoteConfig) {
		const config = rcDefaults
		return config as unknown as RemoteConfigState['configs']
	}
	await fetchAndActivate(firebaseRemoteConfig)
	const allParameters = getAll(firebaseRemoteConfig)
	const config = convertRemoteConfigValues(allParameters)

	return config as RemoteConfigState['configs']
}

export const fetchConfig = createAsyncThunk<RemoteConfigState['configs']>(
	'remote-config/fetch-config',
	async () => {
		try {
			const config = await fetchRemoteConfigFromFirebase()
			return config
		} catch (error) {
			Sentry.captureException(error)
			throw error
		}
	},
)
