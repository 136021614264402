import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type AsRequiredProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const AsRequired: ComponentType<AsRequiredProps> = ({
	width = 20,
	stroke = colors.neutral['70'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 18" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M1 1h13M1 5h9M1 9h6m4 0l4-4m0 0l4 4m-4-4v12"></path>
	</svg>
)
