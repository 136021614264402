import { ComponentType, SVGProps } from 'react'

type ChevronDownProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const ChevronDown: ComponentType<ChevronDownProps> = ({
	width = 10,
	fill = '#6B7280',
	...props
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={width / 1.66666}
		fill="none"
		viewBox="0 0 10 6"
		{...props}>
		<path
			fill={fill}
			fillRule="evenodd"
			d="M.293.293a1 1 0 011.414 0L5 3.586 8.293.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
			clipRule="evenodd"></path>
	</svg>
)
