import React, { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type ChainIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const ChainIcon: ComponentType<ChainIconProps> = ({
	width = 16,
	fill = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill={fill}
			d="M22 15h-2v-3a1 1 0 00-1-1h-6V9h2a1 1 0 001-1V2a1 1 0 00-1-1H9a1 1 0 00-1 1v6a1 1 0 001 1h2v2H5a1 1 0 00-1 1v3H2a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1H6v-2h12v2h-2a1 1 0 00-1 1v6a1 1 0 001 1h6a1 1 0 001-1v-6a1 1 0 00-1-1zM7 17v4H3v-4h4zm3-10V3h4v4h-4zm11 14h-4v-4h4v4z"
		></path>
	</svg>
)
