import { ComponentType } from 'react'

import { IconType } from '../index'

export const MyTasksIcon: ComponentType<IconType> = ({ width = 24, fill = '#fff' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 18 20">
		<path
			fill={fill}
			d="M13 12H5a1 1 0 000 2h8a1 1 0 000-2zm0-4H7a1 1 0 000 2h6a1 1 0 100-2zm4-6h-3V1a1 1 0 00-2 0v1h-2V1a1 1 0 00-2 0v1H6V1a1 1 0 00-2 0v1H1a1 1 0 00-1 1v14a3 3 0 003 3h12a3 3 0 003-3V3a1 1 0 00-1-1zm-1 15a1 1 0 01-1 1H3a1 1 0 01-1-1V4h2v1a1 1 0 002 0V4h2v1a1 1 0 002 0V4h2v1a1 1 0 002 0V4h2v13z"></path>
	</svg>
)
