import FileDownloadIcon from '@mui/icons-material/FileDownload'
import React, { ComponentType, useState } from 'react'

import { AnalyticsEvent, analytics } from 'src/biz'

import { Button } from '../button'

type FileDownloaderProps = {
	downloadLink: string
	fileType: string
}

export const FileDownloader: ComponentType<FileDownloaderProps> = ({ downloadLink, fileType }) => {
	const [loading, isLoading] = useState<boolean>(false)

	const downloadFile = async () => {
		isLoading(true)
		analytics.track({
			eventName: AnalyticsEvent.fileDownloaded,
			properties: {
				fileType,
			},
		})
		window.open(downloadLink)
		isLoading(false)
	}

	return (
		<Button
			onClick={downloadFile}
			variant="text"
			isLoading={loading}
			sx={{ maxWidth: 70, minWidth: 0, width: 70 }}>
			<FileDownloadIcon fontSize="small" />
		</Button>
	)
}
