import { useState } from 'react'
import { backOff } from 'exponential-backoff'

import { DriveUploadPanelProps } from './types'

const allFilesPresent = (fetchedIds: string[], targetIds: string[]): boolean => {
	return targetIds.every((id) => fetchedIds.includes(id))
}

export function useFetchFilesWithRetry({ fetchFiles }: Pick<DriveUploadPanelProps, 'fetchFiles'>) {
	const [state, setState] = useState({
		attempt: 0,
		countdown: 0,
	})

	const fetchFilesWithAllIdsPresent = async (fileIds: string[]): Promise<void> => {
		const timeMultiple = 2
		const startingDelay = 1000
		await backOff(
			() =>
				fetchFiles().then(({ files }) => {
					if (
						!allFilesPresent(
							files.map((file) => file.id),
							fileIds,
						)
					) {
						throw new Error('Missing files')
					}
				}),
			{
				startingDelay,
				timeMultiple,
				numOfAttempts: 4,
				retry: (error, attempt) => {
					setState({
						attempt,
						countdown: attempt * timeMultiple,
					})
					return true
				},
			},
		)
	}

	return {
		...state,
		fetchFilesWithAllIdsPresent,
	}
}
