import React, { ComponentType, ReactNode, useMemo } from 'react'
import { BoxProps } from '@mui/material/Box'
import { TypographyProps } from '@mui/material/Typography'

import { useIsMobile } from 'src/hooks/useMobile'

import { Box } from './box'
import { Text } from './text'

type FormCardProps = BoxProps & {
	title: string
	_text?: TypographyProps
	cta?: ReactNode
}

export const FormCard: ComponentType<FormCardProps> = ({
	title,
	children,
	_text,
	sx,
	cta,
	...rest
}) => {
	const isMobile = useIsMobile()

	const defaultStyles = useMemo(() => {
		if (!isMobile) {
			return {
				boxShadow: 'rgb(0 0 0 / 12%) 2px 4px 15px;',
				borderRadius: '20px 0px 20px 20px',
				pb: 2,
				pt: 1,
			}
		}
	}, [isMobile])

	return (
		<Box
			sx={{
				...defaultStyles,
				px: 2,
				...sx,
			}}
			{...rest}>
			<Box display="flex" justifyContent="space-between" mb={0.5}>
				{!isMobile && (
					<Text variant="overline" fontWeight="bold" {..._text}>
						{title}
					</Text>
				)}
				{cta}
			</Box>
			{children}
		</Box>
	)
}
