import React, { ComponentType } from 'react'
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card'
import CardContent, { CardContentProps } from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'

type CardProps = Omit<MuiCardProps, 'onClick'> & {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
	contentProps?: CardContentProps
}

export const Card: ComponentType<CardProps> = ({ onClick, children, contentProps, ...props }) => {
	const content = <CardContent {...contentProps}>{children}</CardContent>

	const wrappedContent = !onClick ? (
		content
	) : (
		<CardActionArea onClick={onClick}>{content}</CardActionArea>
	)

	return <MuiCard {...props}>{wrappedContent}</MuiCard>
}
