import { ComponentType } from 'react'

type HamburgerProps = {
	width?: number
	stroke?: string
}

export const HamburgerIcon: ComponentType<HamburgerProps> = ({
	width = 24,
	stroke = '#fff',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M3 18h18M3 12h18M3 6h18"></path>
	</svg>
)
