import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type AttachmentIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const AttachmentIcon: ComponentType<AttachmentIconProps> = ({
	width = 20,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 22 23" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M20.44 11.05l-9.19 9.19a6.004 6.004 0 01-8.49-8.49l9.19-9.19a4.002 4.002 0 115.66 5.66l-9.2 9.19a2.001 2.001 0 11-2.83-2.83l8.49-8.48"></path>
	</svg>
)
