import React, { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type WarningProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const WarningIcon: ComponentType<WarningProps> = ({
	width = 20,
	stroke = colors.warning.main,
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 18" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M10.002 7v2m0 4h.01m-6.939 4H16.93c1.54 0 2.502-1.667 1.732-3L11.734 2C10.964.667 9.039.667 8.269 2L1.341 14c-.77 1.333.193 3 1.732 3z"
		></path>
	</svg>
)
