import Box from '@mui/material/Box'

import { Chip } from './chip'

const ChipGroup = ({ labels }: { labels?: string[] }) => {
	if (!labels) return null
	return (
		<Box>
			{labels.map((label, i) => (
				<Chip
					key={`label-for-${label}`}
					label={label}
					color={'primary'}
					variant={'filled'}
					style={{
						marginTop: '3px',
						marginBottom: '3px',
						marginLeft: i === 0 ? '0px' : '3px',
						marginRight: '3px',
						borderRadius: 1,
					}}
				/>
			))}
		</Box>
	)
}

export default ChipGroup
