import { ComponentType, SVGProps } from 'react'

type SoftwareProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Software: ComponentType<SoftwareProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 20 20" {...props}>
		<path
			fill={stroke}
			d="M17 0H3a3 3 0 00-3 3v10a3 3 0 003 3h3v2H3a1 1 0 000 2h14a1 1 0 000-2h-3v-2h3a3 3 0 003-3V3a3 3 0 00-3-3zm-5 18H8v-2h4v2zm6-5a1 1 0 01-1 1H3a1 1 0 01-1-1v-1h16v1zm0-3H2V3a1 1 0 011-1h14a1 1 0 011 1v7z"></path>
	</svg>
)
