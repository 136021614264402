import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Accordion from '@mui/material/Accordion'

import { colors } from '../../theme/colors'
import { Box } from '../box'
import { Text } from '../text'
import { Button } from '../button'
import { useFileUpload } from '../../hooks/useFileUpload'
import { ProjectFolderState } from '../../store/modules/persisted-files'

import { StagedFile } from './stagedFile'

type ProjectWithActiveUploads = {
	parentFolderId: string
	parentFolderName: ProjectFolderState['parentFolderName']
}
export function UploadsAccordion({
	projectsWithActiveUploads,
}: {
	projectsWithActiveUploads: ProjectWithActiveUploads[]
}) {
	return (
		<Box>
			{projectsWithActiveUploads.map((project) => (
				<ProjectAccordion
					key={project.parentFolderId}
					parentFolderName={project.parentFolderName}
					parentFolderId={project.parentFolderId}
				/>
			))}
		</Box>
	)
}

function ProjectAccordion(projectWithUploads: ProjectWithActiveUploads) {
	const { parentFolderId, parentFolderName } = projectWithUploads
	const { stagedFiles, onRemoveStagedFile, onChangeStagedFileName, terminateUpload } =
		useFileUpload({
			parentFolderId,
			parentFolderName: parentFolderName,
		})
	return (
		<Accordion
			key={parentFolderId}
			sx={{
				boxShadow: 'none',
				border: 'none',
				borderRadius: 0,
				'&:before': { display: 'none' },
				'&:hover': { backgroundColor: colors.neutral['10'] },
			}}
		>
			<AccordionSummary aria-controls={`${parentFolderId}-content`} id={`${parentFolderId}-header`}>
				<Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
					<Text variant="body1">{parentFolderName}</Text>
					<Button
						onClick={() => terminateUpload(true)}
						variant="contained"
						color="error"
						size="small"
						sx={{ minWidth: 'auto' }}
					>
						Cancel
					</Button>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box key={parentFolderId} mb={2}>
					<List>
						{stagedFiles.map((file, i) => (
							<ListItem key={file.file.name}>
								<StagedFile
									fileData={file}
									onChangeName={(newName) => onChangeStagedFileName(i, newName)}
									onRemoveFile={() => onRemoveStagedFile(i)}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			</AccordionDetails>
		</Accordion>
	)
}
