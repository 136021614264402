import { ComponentType } from 'react'

import { colors } from 'src/theme/colors'

import { IconType } from '../index'

type InboxNavIconProps = IconType & {
	withCheck?: boolean
}

export const InboxNavIcon: ComponentType<InboxNavIconProps> = ({
	width = 24,
	withCheck = false,
	fill = colors.feldgrau['100'],
	...props
}) => {
	const envelopeShape = withCheck
		? 'M16.29 8.71a1.001 1.001 0 001.42 0l4-4a1.004 1.004 0 00-1.42-1.42L17 6.59l-1.29-1.3a1.003 1.003 0 10-1.42 1.42l2 2zM21 8a1 1 0 00-1 1v9a1 1 0 01-1 1H5a1 1 0 01-1-1V8.41l5.88 5.89a3 3 0 002.11.87 3.08 3.08 0 002.16-.9l1.72-1.72a1.004 1.004 0 10-1.42-1.42l-1.75 1.75a1 1 0 01-1.4 0L5.41 7H11a1 1 0 100-2H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V9a1 1 0 00-1-1z'
		: 'M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm-.41 2l-5.88 5.88a1.002 1.002 0 01-1.42 0L5.41 6h13.18zM20 17a1 1 0 01-1 1H5a1 1 0 01-1-1V7.41l5.88 5.88a3 3 0 004.24 0L20 7.41V17z'

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" {...props}>
			<path fill={fill} d={envelopeShape}></path>
		</svg>
	)
}
