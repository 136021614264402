import * as React from 'react'

const ConfusedUser: React.ComponentType = () => (
	<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
		<defs>
			<style>
				{
					'.cls-1,.cls-4,.cls-6{fill:none;stroke-width:4.83px}.cls-1{stroke:#ccdde2}.cls-1,.cls-4,.cls-6{stroke-miterlimit:10}.cls-2{fill:#d7e8f0}.cls-3{fill:#3fbdf1}.cls-4{stroke:#9f9f9f}.cls-6{stroke:#000}.cls-11,.cls-9{fill:#fff}.cls-9{opacity:.26}'
				}
			</style>
		</defs>
		<rect className="cls-1" x={676.65} y={400.94} width={213.78} height={291.39} rx={16.64} />
		<rect className="cls-1" x={694.95} y={430.96} width={179.37} height={51.25} rx={8.32} />
		<circle className="cls-2" cx={783.54} cy={447.25} r={5.3} />
		<path
			className="cls-2"
			d="M768.73 471.23a14.81 14.81 0 0 1 29.62 0ZM784.62 537.14a28.55 28.55 0 1 0 28.56 28.55 28.55 28.55 0 0 0-28.56-28.55Zm0 50.21a4.18 4.18 0 0 1 0-8.35 4.18 4.18 0 0 1 0 8.35Zm5.27-32.11c-.88 6.19-1.62 12.69-3.47 18.71-.44 1.45-3.16 1.45-3.6 0a106.89 106.89 0 0 1-3-13.79c-.57-3.39-2.55-8.88-.66-12.17 1.67-3 6.37-3.3 9.1-1.76 3.35 1.89 2.03 5.93 1.63 9.01Z"
		/>
		<path
			className="cls-3"
			d="M472.23 458.79a52.81 52.81 0 1 0 35.52 65.69 52.8 52.8 0 0 0-35.52-65.69Zm-26.53 89a7.72 7.72 0 0 1 4.41-14.79 7.72 7.72 0 1 1-4.41 14.79Zm26.3-54.14c-4.83 10.51-9.59 21.64-16 31.33-1.54 2.34-6.37.9-6.38-1.9a193.81 193.81 0 0 1 2-26c.78-6.29.17-17.07 5.26-21.91 4.52-4.33 13-2.47 17.05 1.69 4.87 5.08.41 11.55-1.93 16.79ZM324.73 575.72a26.42 26.42 0 1 0 37.37-.32 26.43 26.43 0 0 0-37.37.32Zm33.14 32.57a3.86 3.86 0 1 1-5.51-5.41 3.86 3.86 0 0 1 5.51 5.41ZM340.1 584c3.51 4.6 7.32 9.31 10.09 14.43.67 1.23-1.09 3-2.33 2.38a97.29 97.29 0 0 1-11-7c-2.61-1.81-7.51-4.07-8.46-7.46-.86-3 2-6.34 4.74-7.13 3.37-.98 5.18 2.51 6.96 4.78Z"
		/>
		<path
			className="cls-1"
			d="M712.89 505.09h139.47M712.89 518.82h139.47M712.89 607.22h139.47M745.28 620.21h74.68"
		/>
		<path
			className="cls-4"
			d="M890.44 589.2c12.3 15.46 24 34.62 34.58 58.23 64.42 143.86-16.1 216.87-16.1 216.87M217.59 703.27S122 594.84 241.2 541.15 384 480 375.4 424.15s59-112.72 165.31-75.14 108.44 108.4 100.9 163.15c-4.28 31.21 13.43 38.71 35 37.14"
		/>
		<path
			d="M883.7 911.09a41.06 41.06 0 0 1-4.54 17.17 6.11 6.11 0 0 1-.38.77 64.76 64.76 0 0 1-19.71 21.74l-.29.2a65.73 65.73 0 0 1-24.32 9.95c0 .08 0 0 0 0H927s-12.38-176.55-126.16-262.45c-110.11-83.12-213.2-43.06-219.74-40.38a47.32 47.32 0 0 1 2.67 17.84s8.28 4.31 9.66 19.74S597.69 754 570 762.08c0 0-24.86-.61-31.43-10.15s-8.15-7.6-10.73-5.18c-2 1.83-14.07 8.37-19.64 11.37a11.93 11.93 0 0 1 2.09.29s3.19 26.92 42.22 69.11a55 55 0 0 1 3.74 4.38q8.84 7.73 19.87 15.78l44.54 32.59a8.4 8.4 0 0 0 3.48 1.77 16.12 16.12 0 0 0 7.6.07c5.18-1 40.55-4.61 63.44-6.89.52 0 1.07-.1 1.58-.16l3-.29c.48 0 1-.1 1.42-.13l2.7-.26c1.32-.13 2.51-.26 3.61-.35l3-.29c2.65-.26 4.22-.42 4.35-.42a119.38 119.38 0 0 0 16.27-1.58c5.15-1 9.85-2.61 11.07-5.28a3.63 3.63 0 0 0 .25-1.78 10 10 0 0 0-2.06-4.64c-4.7-6.31-15.62-11.72-16.42-12.11a.11.11 0 0 0-.07 0l-.22-.16-65-50.5h-.07s9.79-104.44 99-78.74c0 0 .42.29 1.19.93 3.48 2.68 14.43 11.56 30.53 28.15.42.45.87.9 1.32 1.38 1.71 1.74 3.39 3.55 5.06 5.32 11.3 12.08 24.67 27.47 39.42 46.66l.1.1c16.9 22 29.08 42.16 37 60v.06c8.57 19.39 12.18 36 11.47 49.25a5.35 5.35 0 0 1 .02.71ZM614.63 731.58c-2.64-30.43 12.17-41.45 13-42.06-21.06 38.1 18 96.5 19.37 98.76-1.12-.94-29.73-26.28-32.37-56.7Z"
			style={{
				fill: '#353642',
			}}
		/>
		<path
			className="cls-3"
			d="M558.69 725.61a34.87 34.87 0 0 1-3.42 2.22l-6.86-12c15.82-8.09 20.26-22.35 13.3-30.18a15.45 15.45 0 0 0-7.24-4.48c-7-2.12-11.85 1-15.14 5.19a35.7 35.7 0 0 0-5.8 12.14l-3.34-38.52-.26-3s-7.28-3.61-27-.39-23.35 14.11-31.82 9.28-13.27-4.44-13.27-4.44c-2.41 8.85-8.86 8.85-8.86 8.85a19.37 19.37 0 0 1-4.47-1.09c-9.15-8.41-11.63-23-11.63-23 9.6-1.19 10.34-10.43 9.24-18 5.32 3.16 12.82 3.38 23.35-3.38 30.21-19.32 61.19-8.86 72.08-7.25 4.86.71 10.18-1 14.59-3a7.75 7.75 0 0 0 2.32 2.57 57.89 57.89 0 0 1 17.71 19.74c28.35 52.99-2.73 81.13-13.48 88.74Z"
		/>
		<path
			className="cls-6"
			d="M445.52 960.94h11.4a18.41 18.41 0 0 0 18.39-18.39v-12.76a8.85 8.85 0 0 0-8.86-8.85h-68.18a20.65 20.65 0 0 1-20.68 20.67H131.22a19.34 19.34 0 0 0 19.33 19.33h334a3.31 3.31 0 0 0 3.32-3.32v-20.71a15.17 15.17 0 0 1 30.34 0v20.71a3.31 3.31 0 0 0 3.31 3.32H927s-12.38-176.59-126.16-262.48c-110.11-83.12-213.2-43.06-219.74-40.38l-.32.12"
		/>
		<path d="M467.09 701.3a5.62 5.62 0 1 1-5.63-5.64 5.49 5.49 0 0 1 1.29.16 2.86 2.86 0 0 0-2 2.74 2.91 2.91 0 0 0 2.9 2.93 3 3 0 0 0 2.9-2.51 5.59 5.59 0 0 1 .54 2.32ZM506.1 702.87a5.64 5.64 0 1 1-5.64-5.63 3.38 3.38 0 0 1 .8.09 2.87 2.87 0 0 0-1.67 2.61 2.9 2.9 0 0 0 5.8.26 5.46 5.46 0 0 1 .71 2.67Z" />
		<path
			d="M551.09 606.64c-.65 4.38.16 8.22 3.38 10.5a57.89 57.89 0 0 1 17.71 19.74c33.82 63.19-16.91 91-16.91 91l-6.86-12c19.75-10.08 21.77-29.82 6.06-34.66s-20.94 17.33-20.94 17.33l-3.6-41.55s-7.28-3.61-27-.39-23.35 14.11-31.82 9.28-13.27-4.44-13.27-4.44c-2.41 8.85-8.86 8.85-8.86 8.85-18.9-2.41-23.34-28.56-23.34-28.56 9.59-1.2 10.33-10.44 9.24-18 5.31 3.16 12.82 3.39 23.35-3.38 30.21-19.32 61.19-8.85 72.07-7.24 7.52 1.09 16.15-3.51 20.79-6.48Z"
			style={{
				strokeLinejoin: 'round',
				strokeLinecap: 'round',
				stroke: '#000',
				fill: 'none',
				strokeWidth: '4.83px',
			}}
		/>
		<path
			className="cls-3"
			d="m568.53 920 7.47-3 5.6 15.11-.88 27.42-7.57 1.37-28.73-1.24s2-16.72 8.11-24.67 16-14.99 16-14.99Z"
		/>
		<path
			className="cls-6"
			d="M437.72 679.54s7.65-13.73 23.75-12.3M493 667.24s18.4-4.13 24.84 6.1M521.51 729.4s-11-6.37-19.05 4.34"
		/>
		<path className="cls-6" d="M452 669.44s-7.83 92.11 50.56 90.41c10-.29 19.94-6.86 29.44-17.43" />
		<path
			d="M555.27 750.8 532 742.42s5.77 18.44 34.35 19.85 38.17-64.18 17.17-90.57"
			style={{
				strokeLinejoin: 'round',
				stroke: '#000',
				fill: 'none',
				strokeWidth: '4.83px',
			}}
		/>
		<path
			className="cls-6"
			d="M646.25 935.58s-21.33 17.31-6.84 25.36M629.75 919.47s-37 20.94-5.23 41.47"
		/>
		<path className="cls-6" d="M617.27 903.37s-49.11 23-11.27 57.57" />
		<path
			className="cls-6"
			d="M823.79 960.94s59.58 0 60.38-57.57-115.53-202.13-190.81-222.24-78.51 35-78.51 37.26-4.42 26 14.9 51.33c14.92 19.58 80.82 68.46 110.06 89.7a6.54 6.54 0 0 1-3.23 11.8l-129.32 12.42h-.08c-1.22.22-46.86 8.64-60.76 22.94 0 0-7.79 5.77-3.76 14.63s16.41 5.94 16.75 5.61"
		/>
		<circle className="cls-9" cx={500.47} cy={697.23} r={16.1} />
		<circle className="cls-9" cx={459.61} cy={695.64} r={16.1} />
		<path
			className="cls-6"
			d="M598.78 917.46c-5.66-1.58-14.91-3-24.44.06a35.55 35.55 0 0 0-12.63 7.19c-.8.7-1.57 1.41-2.31 2.12a59.48 59.48 0 0 0-4.51 4.95 1.2 1.2 0 0 1-.74.43c-3.55.49-26.92 4.19-26.8 16 .11 10.61 11.73 12.35 15.73 12.63a1.2 1.2 0 0 0 1.31-1.18c0-3.78.86-15.63 10.21-27.55M388.22 834.11c2.74 22.09-14.43 32.08-33.2 33.56"
		/>
		<path
			className="cls-6"
			d="M327.74 863.06a39.7 39.7 0 0 1-20.42-40.64c3-21.25 21-37.55 38.62-47.89 37-21.71 120.38-6 134.39-2.8 10.74 2.46 19.72-5.94 23.36-10a1.21 1.21 0 0 1 2.1.65c7.69 45 97 106.08 117.66 119.75"
		/>
		<path
			className="cls-6"
			d="m410.75 818.83-29.57 18.35a14.85 14.85 0 0 1-21.18-6.06 14.83 14.83 0 0 1 5.72-19.3l45-26.81"
		/>
		<circle className="cls-6" cx={500.47} cy={697.23} r={16.1} />
		<circle
			cx={459.61}
			cy={695.64}
			r={13.69}
			style={{
				stroke: '#fff',
				strokeMiterlimit: 10,
				fill: 'none',
				strokeWidth: '4.83px',
			}}
		/>
		<circle className="cls-6" cx={459.61} cy={695.64} r={16.1} />
		<path
			className="cls-6"
			d="M484.45 695.64h-8.74M484.45 718.39h-5.76a2.46 2.46 0 0 1-2.42-2.92l3.81-19.83M368.88 773.34s-20.53 4.43-27.77 35.43c-4.4 18.82-10.44 42.83-14.5 58.79a13.87 13.87 0 0 0 13.44 17.29A13.87 13.87 0 0 0 353.59 874l8.59-38.54M387.65 845.8s21.55-12.88 64 0S542.9 867.42 559 833.26M574.35 917.52s15 17.12 5.88 43.42"
		/>
		<path
			className="cls-3"
			d="M78.68 704.63h237a34 34 0 0 1 33.09 26l37.73 156.26a28.67 28.67 0 0 1-27.87 35.4H122.24a34 34 0 0 1-33.06-25.93L50.84 740.13a28.67 28.67 0 0 1 27.84-35.5Z"
		/>
		<path
			className="cls-3"
			d="M87.31 711.35h222.36a32 32 0 0 1 31.05 24.43l35.42 146.59A26.89 26.89 0 0 1 350 915.58H128.18a32 32 0 0 1-31-24.33l-36-146.6a26.89 26.89 0 0 1 26.13-33.3Z"
		/>
		<path
			className="cls-6"
			d="M78.68 704.63h237a34 34 0 0 1 33.09 26l37.73 156.26a28.67 28.67 0 0 1-27.87 35.4H122.24a34 34 0 0 1-33.06-25.93L50.84 740.13a28.67 28.67 0 0 1 27.84-35.5Z"
		/>
		<path
			className="cls-2"
			d="m189.68 808.71-14.13-65.87a6.21 6.21 0 0 0-6.07-4.93H99.56a6.2 6.2 0 0 0-6 7.7l16.35 66.64a5.67 5.67 0 0 0 1.26 2.51 6 6 0 0 0 4.7 2.29h.13l67.67-.8a6.21 6.21 0 0 0 4.75-2.32 6.28 6.28 0 0 0 1.26-5.22Zm-91.45-64.28a1.33 1.33 0 0 1 .24-1.15 1.38 1.38 0 0 1 1.09-.54h69.92a1.36 1.36 0 0 1 1.34 1.09L181.58 794c-35.93-34.69-40 5.11-40 5.11-20.28-10.56-26.14 1-27.84 8.61Z"
		/>
		<path
			className="cls-2"
			d="M118.42 768.34c3.9-1.21 5.75-6.38 4.15-11.55s-6.07-8.37-10-7.16-5.75 6.38-4.14 11.54 6.1 8.38 9.99 7.17ZM135.54 767.16h31.91a5.64 5.64 0 0 0 2.34-.44 3.22 3.22 0 0 0 .3-5.6 5 5 0 0 1-1.45-1 4 4 0 0 1-.56-1.44 6.55 6.55 0 0 0-2.89-3.43 8.72 8.72 0 0 0-6.7-1.29 6.6 6.6 0 0 0-3 1.68c-.71.7-.85 1.64-1.4 2.42-.36.51-.59.35-1.08.07s-.76-.49-1.16-.69a18.47 18.47 0 0 0-2.45-1.09 13.1 13.1 0 0 0-5.37-.79c-3.21.25-6.41 2.16-7.53 5.18a4.24 4.24 0 0 0-4.14 3.63 2.49 2.49 0 0 0 .66 2.15 3.56 3.56 0 0 0 2.52.64Z"
		/>
		<path
			className="cls-11"
			d="m300 765.74-1.84-7.57a23.34 23.34 0 0 0-22.7-17.84h-25.27a18.74 18.74 0 0 0-18.1 23.67l2.09 7.69a23.3 23.3 0 0 0 22.51 17.23h25.12A18.74 18.74 0 0 0 300 765.74Zm-27.79 1.87-8.63 5.31a2.55 2.55 0 0 1-3.81-1.42l-3.25-10.62a2.59 2.59 0 0 1 3.51-3.1l11.97 5.29a2.56 2.56 0 0 1 .25 4.54Z"
		/>
		<path
			className="cls-2"
			d="M294.36 833.74h-44.21a10.44 10.44 0 0 1-9.54-6.24 4.7 4.7 0 0 1-.4-1.81 4.58 4.58 0 0 1 4.57-4.54h47c-2.21 3.74-1.09 8.91 2.58 12.59Z"
		/>
		<path
			className="cls-3"
			d="M323 829.2a4.55 4.55 0 0 1-4.54 4.54h-24.1c-3.67-3.68-4.79-8.85-2.58-12.59H313a10.44 10.44 0 0 1 9.54 6.21 4.7 4.7 0 0 1 .46 1.84Z"
		/>
		<path
			className="cls-11"
			d="M311.89 833.74a5.2 5.2 0 0 1-.43.69c-3.16 4.31-10 4.71-15.31.83a15.44 15.44 0 0 1-1.79-1.52c-3.67-3.68-4.79-8.85-2.58-12.59a4.76 4.76 0 0 1 .43-.68c3.13-4.34 10-4.72 15.28-.87a16.33 16.33 0 0 1 1.81 1.55c3.7 3.68 4.77 8.85 2.59 12.59Z"
		/>
		<path
			className="cls-3"
			d="M334.07 857a4.58 4.58 0 0 1-4.57 4.54H154.28c2.24-3.76 1.12-8.91-2.56-12.58h172.41a10.4 10.4 0 0 1 9.53 6.23 4.57 4.57 0 0 1 .41 1.81Z"
		/>
		<path
			className="cls-2"
			d="M154.28 861.49h-21.83a10.43 10.43 0 0 1-9.54-6.21 4.66 4.66 0 0 1-.4-1.84 4.52 4.52 0 0 1 4.54-4.53h24.67c3.68 3.67 4.8 8.82 2.56 12.58Z"
		/>
		<path
			className="cls-11"
			d="M154.28 861.49a3.07 3.07 0 0 1-.4.69c-3.13 4.34-10 4.71-15.29.86a16.2 16.2 0 0 1-1.8-1.55c-3.65-3.68-4.77-8.82-2.59-12.58a4.33 4.33 0 0 1 .43-.69c3.16-4.31 10-4.71 15.31-.84a14.49 14.49 0 0 1 1.78 1.53c3.68 3.67 4.8 8.82 2.56 12.58Z"
		/>
		<path
			className="cls-3"
			d="M169.74 889H142a10.45 10.45 0 0 1-9.54-6.21A4.52 4.52 0 0 1 132 881a4.57 4.57 0 0 1 4.56-4.56h30.57c-2.13 3.78-1.04 8.92 2.61 12.56Z"
		/>
		<path
			className="cls-2"
			d="M343.58 884.53A4.53 4.53 0 0 1 339 889H169.74c-3.65-3.68-4.77-8.82-2.59-12.58h166.49a10.4 10.4 0 0 1 9.53 6.23 4.71 4.71 0 0 1 .41 1.88Z"
		/>
		<path
			className="cls-11"
			d="M187.26 889a4.33 4.33 0 0 1-.43.69c-3.13 4.34-10 4.71-15.28.86a16.33 16.33 0 0 1-1.81-1.55c-3.65-3.68-4.77-8.82-2.59-12.58a5.2 5.2 0 0 1 .43-.69c3.16-4.31 10-4.69 15.31-.84a13.79 13.79 0 0 1 1.78 1.53c3.68 3.71 4.8 8.88 2.59 12.58Z"
		/>
		<g
			style={{
				opacity: 0.21,
			}}>
			<path
				className="cls-2"
				d="M192.1 811.12 178 745.25a6.19 6.19 0 0 0-6.07-4.92H102a6.18 6.18 0 0 0-4.9 2.41A6.1 6.1 0 0 0 96 748l16.35 66.65a5.66 5.66 0 0 0 1.25 2.51 6 6 0 0 0 4.71 2.28h.16l67.66-.81a6.17 6.17 0 0 0 4.74-2.31 6.28 6.28 0 0 0 1.23-5.2Zm-91.46-64.27a1.31 1.31 0 0 1 .25-1.15 1.34 1.34 0 0 1 1.08-.54h69.92a1.37 1.37 0 0 1 1.35 1.08L184 796.41c-35.94-34.7-40 5.11-40 5.11-20.27-10.56-26.14 1-27.83 8.61Z"
			/>
			<path
				className="cls-2"
				d="M120.84 770.75c3.89-1.21 5.75-6.38 4.14-11.54s-6.06-8.38-10-7.17-5.75 6.38-4.15 11.55 6.17 8.41 10.01 7.16ZM138 769.58h31.9a5.61 5.61 0 0 0 2.34-.44 3.21 3.21 0 0 0 .3-5.6 5.51 5.51 0 0 1-1.45-1 4.47 4.47 0 0 1-.56-1.45 6.56 6.56 0 0 0-2.88-3.42 8.7 8.7 0 0 0-6.71-1.29 6.44 6.44 0 0 0-2.94 1.68c-.72.7-.85 1.63-1.4 2.42-.36.51-.59.35-1.08.06s-.76-.48-1.16-.69a18.41 18.41 0 0 0-2.45-1.08 13.13 13.13 0 0 0-5.37-.8c-3.21.25-6.41 2.17-7.54 5.18a4.24 4.24 0 0 0-4.13 3.64 2.53 2.53 0 0 0 .65 2.15 3.65 3.65 0 0 0 2.48.64ZM302.46 768.15l-1.84-7.56a23.36 23.36 0 0 0-22.71-17.85H252.6a18.74 18.74 0 0 0-18.1 23.64l2.09 7.7a23.31 23.31 0 0 0 22.52 17.23h25.12a18.75 18.75 0 0 0 18.23-23.16Zm-27.8 1.85-8.66 5.34a2.54 2.54 0 0 1-3.8-1.42l-3.2-10.63a2.59 2.59 0 0 1 3.51-3.09l11.88 5.28a2.57 2.57 0 0 1 .27 4.52ZM296.78 836.15h-44.21a10.45 10.45 0 0 1-9.54-6.23 4.5 4.5 0 0 1 4.17-6.35h47c-2.2 3.73-1.1 8.91 2.58 12.58Z"
			/>
			<path
				className="cls-3"
				d="M325.36 831.61a4.54 4.54 0 0 1-4.53 4.54h-24.05c-3.68-3.67-4.8-8.85-2.59-12.58h21.23a10.42 10.42 0 0 1 9.54 6.21 4.65 4.65 0 0 1 .4 1.83Z"
			/>
			<path
				className="cls-11"
				d="M314.3 836.15a3.72 3.72 0 0 1-.43.69c-3.16 4.31-10 4.71-15.31.84a15.28 15.28 0 0 1-1.78-1.53c-3.68-3.67-4.8-8.85-2.59-12.58a4.42 4.42 0 0 1 .44-.69c3.13-4.34 10-4.71 15.28-.86a16.18 16.18 0 0 1 1.81 1.55c3.65 3.68 4.77 8.82 2.58 12.58Z"
			/>
			<path
				className="cls-3"
				d="M336.48 859.37a4.57 4.57 0 0 1-4.57 4.53H156.7c2.24-3.76 1.12-8.9-2.56-12.58h172.4a10.41 10.41 0 0 1 9.54 6.24 4.52 4.52 0 0 1 .4 1.81Z"
			/>
			<path
				className="cls-2"
				d="M156.7 863.9h-21.84a10.45 10.45 0 0 1-9.54-6.2 4.88 4.88 0 0 1-.4-1.84 4.54 4.54 0 0 1 4.54-4.54h24.68c3.68 3.68 4.8 8.82 2.56 12.58Z"
			/>
			<path
				className="cls-11"
				d="M156.7 863.9a2.78 2.78 0 0 1-.41.69c-3.13 4.34-10 4.72-15.28.87a16.25 16.25 0 0 1-1.81-1.56c-3.65-3.67-4.77-8.82-2.58-12.58a3.72 3.72 0 0 1 .43-.69c3.16-4.31 10-4.71 15.31-.83a16.21 16.21 0 0 1 1.78 1.52c3.68 3.68 4.8 8.82 2.56 12.58Z"
			/>
			<path
				className="cls-3"
				d="M172.15 891.45h-27.78a10.47 10.47 0 0 1-9.54-6.2 4.74 4.74 0 0 1-.4-1.81 4.59 4.59 0 0 1 4.57-4.57h30.57c-2.19 3.76-1.07 8.91 2.58 12.58Z"
			/>
			<path
				className="cls-2"
				d="M346 886.94a4.52 4.52 0 0 1-4.54 4.51H172.15c-3.65-3.67-4.77-8.82-2.58-12.58h166.48a10.41 10.41 0 0 1 9.54 6.24 4.65 4.65 0 0 1 .41 1.83Z"
			/>
			<path
				className="cls-11"
				d="M189.68 891.45a4.42 4.42 0 0 1-.44.69c-3.13 4.34-10 4.72-15.28.87a16.25 16.25 0 0 1-1.81-1.56c-3.65-3.67-4.77-8.82-2.58-12.58a3.72 3.72 0 0 1 .43-.69c3.16-4.31 10-4.68 15.31-.83a16.21 16.21 0 0 1 1.78 1.52c3.68 3.68 4.8 8.85 2.59 12.58Z"
			/>
		</g>
	</svg>
)

export default ConfusedUser
