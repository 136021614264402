import React, { useCallback, useContext, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Alert } from '@mui/material'
import AlertTitle from '@mui/material/AlertTitle'
import { Controller, useForm } from 'react-hook-form'

import { Button, TextField } from '../components'
import { TableContext } from '../providers/table-provider'
import { showSnackbar } from '../helpers/snackbarManager'

export enum StatusType {
	Confirmed = 'confirmed',
	Rejected = 'rejected',
}

type ResolveData = {
	state: StatusType
	name: string
}

type PromiseHandlers = {
	resolve: (data: ResolveData) => void
	previousName: string
	columnId: string
}

const useConfirmGroupDuplication = (): [
	JSX.Element,
	(previousName: string, columnId: string) => Promise<ResolveData>,
] => {
	const [promise, setPromise] = useState<PromiseHandlers | null>(null)
	const { mappedRows, mappedColumns } = useContext(TableContext)
	const [isLoading] = useState(false)
	const { control, handleSubmit, getValues } = useForm<{
		name: string
	}>()

	const confirm = (previousName: string, columnId: string): Promise<ResolveData> => {
		return new Promise((resolve) => {
			setPromise({
				resolve,
				previousName,
				columnId,
			})
		})
	}

	const handleClose = () => {
		setPromise(null)
	}

	const handleConfirm = async () => {
		const name = getValues('name').trim()
		if (!promise) {
			return
		}
		const column = mappedColumns.get(promise.columnId)
		if (
			name === promise?.previousName ||
			[...mappedRows.values()].some(
				(_row) => promise?.columnId && _row[promise.columnId] === promise?.columnId,
			) ||
			column?.valueOptions.some((_option) => _option.label === name)
		) {
			showSnackbar('Group name already exists', { variant: 'error' })
			return
		}

		promise?.resolve({ state: StatusType.Confirmed, name })
		handleClose()
	}

	const handleCancel = useCallback(() => {
		promise?.resolve({ state: StatusType.Rejected, name: '' })
		handleClose()
	}, [promise])

	// You could replace the Dialog with your library's version
	const NewNameDialog = useMemo(
		() => (
			<Dialog
				open={promise !== null}
				fullWidth
				PaperProps={{
					sx: { borderRadius: 2, p: 2 },
				}}
			>
				<Alert severity="warning">
					<AlertTitle>New group name needed</AlertTitle>
					{`Group names are unique, so you need to create a new group for these duplicated rows`}
				</Alert>
				<DialogContent>
					<Controller
						control={control}
						name="name"
						render={({ field: { value, onChange } }) => (
							<TextField label="New group name" value={value} onChange={onChange} />
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirm} isLoading={isLoading}>
						Confirm
					</Button>
					<Button onClick={handleCancel} variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		),
		[promise, isLoading, handleSubmit, handleConfirm, control],
	)

	return [NewNameDialog, confirm]
}

export default useConfirmGroupDuplication
