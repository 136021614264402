import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Grid, Stack } from '@mui/material'
import { v4 } from 'uuid'

import { Button, IconButton, Select, Text, TextField } from '../../../../components'
import { TrashIcon } from '../../../../assets/icons'
import { SingleSelectForm } from '../types'
import { TableContext } from '../../../../providers/table-provider'

import { TableOptionsFilters } from './table-option-filters'

export const ValueOptionFilters: ComponentType<{ columnId: string }> = ({ columnId }) => {
	const [selectedFilter, setSelectedFilter] = useState('')
	const { onUpdateColumn } = useContext(TableContext)
	const { control, watch, setValue } = useFormContext<SingleSelectForm>()
	const valueOptionFilters = watch('valueOptionFilters')
	const { append: appendValueOptionFilter, remove: removeValueOptionFilter } = useFieldArray({
		control,
		name: 'valueOptionFilters',
	})
	const options = watch('options')

	const selectedOptionIndex = useMemo(() => {
		if (!valueOptionFilters.length) return 0
		return valueOptionFilters.findIndex(({ _id }) => _id === selectedFilter) ?? -1
	}, [valueOptionFilters, selectedFilter])

	const onRemove = async (removeId: string, index: number) => {
		setSelectedFilter('')
		const response = await onUpdateColumn(columnId, {
			valueOptionFilters: valueOptionFilters.filter(({ _id }) => _id !== removeId),
		})
		if (response.result === 'success') {
			removeValueOptionFilter(index)
		}
	}

	const valueOptionFilterId = useMemo(() => {
		const { _id: valueOptionFilterId } = valueOptionFilters?.find(
			({ _id }) => _id === selectedFilter,
		) ?? { _id: null }
		return valueOptionFilterId
	}, [selectedFilter, valueOptionFilters])

	const onAddFilterOptions = useCallback(async () => {
		const valueOptionFilterId = v4()
		const newValueOptionFilter = {
			_id: valueOptionFilterId,
			label: `Filter ${valueOptionFilters.length + 1}`,
			filters: {
				items: [],
			},
			options: options.map(({ _id }) => _id),
		}
		const response = await onUpdateColumn(columnId, {
			valueOptionFilters: [...valueOptionFilters, newValueOptionFilter],
		})
		if (response.result === 'success') {
			appendValueOptionFilter(newValueOptionFilter)
			setSelectedFilter(valueOptionFilterId)
		}
	}, [onUpdateColumn, options, valueOptionFilters])

	return (
		<Stack direction="column" spacing={1} width="100%" maxWidth={650} mt={1}>
			<Stack direction="column">
				<Text variant="h6">Filter Configuration:</Text>
				<Text variant="caption">
					If a row does not match the condition all options will be displayed
				</Text>
				<Grid container spacing={1}>
					<Grid item xs={11}>
						<Select
							fullWidth
							value={selectedFilter}
							onChange={(e) => setSelectedFilter(e.target.value as string)}
							options={valueOptionFilters.map(({ _id, label }) => ({
								_id,
								label,
							}))}
						/>
					</Grid>
					{selectedFilter && valueOptionFilterId && (
						<Grid item xs={1}>
							<IconButton onClick={() => onRemove(valueOptionFilterId, selectedOptionIndex)}>
								<TrashIcon />
							</IconButton>
						</Grid>
					)}
				</Grid>
			</Stack>
			{selectedOptionIndex > -1 && selectedFilter && valueOptionFilterId && (
				<>
					<Controller
						control={control}
						name={`valueOptionFilters.${selectedOptionIndex}.label`}
						render={({ field }) => <TextField fullWidth label="Filter label" {...field} />}
					/>

					<TableOptionsFilters key={valueOptionFilterId} index={selectedOptionIndex} />

					<Text>Options avaliable when filter is true</Text>
					<Button
						variant="text"
						onClick={() => setValue(`valueOptionFilters.${selectedOptionIndex}.options`, [])}
					>
						Unselect all options
					</Button>
					<Controller
						control={control}
						name={`valueOptionFilters.${selectedOptionIndex}.options`}
						render={({ field }) => (
							<Select
								{...field}
								fullWidth
								multiple
								options={options.map(({ _id, label }) => ({
									_id,
									label,
								}))}
							/>
						)}
					/>
				</>
			)}
			<Button variant="text" onClick={onAddFilterOptions}>
				Add filter
			</Button>
		</Stack>
	)
}
