import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SessionConfigState } from "./types";

const initialState: SessionConfigState = {
  selectedOrganisationId: undefined
}

export const sessionConfigSlice = createSlice({
  name: 'session-config',
  initialState: initialState,
  reducers: {
    setSelectedOrganisationId(state: SessionConfigState, action: PayloadAction<SessionConfigState['selectedOrganisationId']>) {
			state.selectedOrganisationId = action.payload
		},
    endSession: () => initialState
  }
})