import { ComponentType, SVGProps } from 'react'

type NoteProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Note: ComponentType<NoteProps> = ({ width = 30, stroke = '#886402', ...props }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 28 28" {...props}>
		<path
			fill={stroke}
			d="M14.25 11.75h-7.5a1.25 1.25 0 000 2.5h7.5a1.25 1.25 0 000-2.5zm5-5H6.75a1.25 1.25 0 000 2.5h12.5a1.25 1.25 0 000-2.5zM21.75.5H4.25A3.75 3.75 0 00.5 4.25v12.5a3.75 3.75 0 003.75 3.75h14.488l4.625 4.637a1.249 1.249 0 00.887.363c.164.004.327-.03.475-.1a1.25 1.25 0 00.775-1.15v-20A3.75 3.75 0 0021.75.5zM23 21.238l-2.863-2.875A1.249 1.249 0 0019.25 18h-15A1.25 1.25 0 013 16.75V4.25A1.25 1.25 0 014.25 3h17.5A1.25 1.25 0 0123 4.25v16.988z"></path>
	</svg>
)
