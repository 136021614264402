import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import { Box } from '../../box'
import { Button } from '../../button'
import { useIsMobile } from '../../../hooks/useMobile'

type Props = {
	onConfirmClose: (shouldTerminate: boolean) => void
	showConfirmClose: boolean
	setShowConfirmClose: (show: boolean) => void
}
export function UploadConfirmDialog({
	onConfirmClose,
	showConfirmClose,
	setShowConfirmClose,
}: Props) {
	const isMobile = useIsMobile()
	const boxProps = isMobile
		? ({
				flexDirection: 'column-reverse',
				alignItems: 'stretch',
				justifyContent: 'center',
			} as const)
		: ({
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			} as const)

	const withMargin = isMobile ? { mt: 1 } : { mr: 2 }

	return (
		<Dialog fullWidth open={showConfirmClose} onClose={() => setShowConfirmClose(false)}>
			<DialogTitle>Your files are still being uploaded!</DialogTitle>
			<DialogContent>
				<Box {...boxProps} display="flex">
					<Button sx={withMargin} color="error" onClick={() => onConfirmClose(true)}>
						Cancel upload
					</Button>
					<Button color="primary" onClick={() => onConfirmClose(false)}>
						Upload in background
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	)
}
