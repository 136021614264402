import { ComponentType, SVGProps } from 'react'

type ArchiveProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Archive: ComponentType<ArchiveProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M21 8v13H3V8M10 12h4M23 3H1v5h22V3z"></path>
	</svg>
)
