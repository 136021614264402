import { ComponentType, SVGProps } from 'react'

type EditPenIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const EditPenIcon: ComponentType<EditPenIconProps> = ({
	width = 24,
	stroke = '#fff',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M12 20h9M16.5 3.5a2.121 2.121 0 113 3L7 19l-4 1 1-4L16.5 3.5z"></path>
	</svg>
)
