import { GridFilterModel, GridValidRowModel } from '@mui/x-data-grid-premium'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ClientTypes, TableTypes } from '@cango-app/types'

import { TableContext } from '../../providers/table-provider'
import { selectors as contactSelectors } from '../../store/modules/contacts'
import { applyFilterModelToRow, resolveAnyRowCalculations } from '../../modules/tables/utils'
import { getFieldType } from '../../modules/tables/mui-formatter'

export type Hierarchy = { _id: string; label: string }[]

export type ViewRow = GridValidRowModel & {
	hierarchy: Hierarchy
}

const getSanitizedGroupedValue = (groupedValue: string) =>
	groupedValue.replace(/[^a-zA-Z0-9]/g, '_')

const getRows = ({
	groupingFields,
	records,
	fields,
	mappedContacts,
	mappedColumns,
	filters,
}: {
	groupingFields: TableTypes.GroupedField[]
	records: TableTypes.Record[]
	fields: TableTypes.Field[]
	mappedContacts: Map<string, ClientTypes.Contact>
	mappedColumns: Map<string, TableTypes.Field>
	filters: GridFilterModel
}): { rows: ViewRow[]; groupNames: Map<string, string> } => {
	const fieldTypes = fields.map((_col) => ({
		_id: _col._id,
		type: getFieldType(_col.type),
	}))

	const resolvedRecords = records.reduce((_records: ViewRow[], _record) => {
		const resolvedRecord = resolveAnyRowCalculations({
			fields: fields,
			row: _record,
			contacts: mappedContacts,
		})
		const recordData: ViewRow = { ...resolvedRecord, hierarchy: [], _id: _record._id }

		if (filters.items.length) {
			const filteredRow = applyFilterModelToRow(fieldTypes, recordData, filters)
			if (!filteredRow) {
				return _records
			}
		}
		return [..._records, recordData]
	}, [])

	if (!groupingFields.length) {
		return {
			rows: resolvedRecords,
			groupNames: new Map(),
		}
	}

	const groupNames = new Map<string, string>()
	const rows = resolvedRecords.reduce((_records: ViewRow[], recordData) => {
		for (const groupingField of groupingFields) {
			const value = recordData[groupingField.groupColumnId]
			const groupedColumn = mappedColumns.get(groupingField.groupColumnId)

			const valueOption = groupedColumn?.valueOptions?.find(
				(_option) => _option._id === value,
			)?.label
			const groupedValue = valueOption ?? recordData[groupingField.groupColumnId]
			if (groupedValue) {
				const sanitizedGroupedValue =
					typeof groupedValue === 'number'
						? String(groupedValue)
						: getSanitizedGroupedValue(groupedValue)
				if (!groupNames.has(sanitizedGroupedValue)) {
					groupNames.set(sanitizedGroupedValue, groupedValue)
				}
				recordData.hierarchy.push({
					_id: sanitizedGroupedValue,
					label: groupedValue,
				})
			}
		}
		if (recordData.hierarchy.length === 0) {
			return _records
		}
		const sanitizedGroupedValue = getSanitizedGroupedValue(recordData._id)
		if (!groupNames.has(sanitizedGroupedValue)) {
			groupNames.set(sanitizedGroupedValue, recordData._id)
		}
		recordData.hierarchy.push({
			_id: sanitizedGroupedValue,
			label: recordData._id,
		})
		return [..._records, recordData]
	}, [])

	return {
		rows,
		groupNames,
	}
}

export const useRows = (
	showChildren: boolean,
	groupingFields: TableTypes.GroupedField[],
	filters: GridFilterModel,
): {
	rows: ViewRow[]
	groupNames: Map<string, string>
} => {
	const { table, mappedColumns } = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	return useMemo((): { rows: ViewRow[]; groupNames: Map<string, string> } => {
		if (!table) {
			return {
				rows: [],
				groupNames: new Map(),
			}
		}

		return getRows({
			groupingFields,
			records: table.records,
			fields: table.fields,
			mappedContacts,
			mappedColumns,
			filters,
		})
	}, [table, showChildren, JSON.stringify(groupingFields)])
}
