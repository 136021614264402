import React, { ComponentType, useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import { MentionsInput, Mention } from 'react-mentions'
import { useSelector } from 'react-redux'

import { selectors as userSelectors } from 'src/store/modules/user'

import { taggedInputStandard, taggedMentionStandard } from '../../helpers/ui'
import { Box } from '../box'
import { Button } from '../button'

type AddNoteProps = {
	onAddNote: (note: string) => Promise<void>
}

export const AddNote: ComponentType<AddNoteProps> = ({ onAddNote }) => {
	const users = useSelector(userSelectors.getAllUsersForTagging)

	const [noteSaving, setNoteSaving] = useState(false)
	const [note, setNote] = useState('')
	const [noteLengthWarning, setNoteLengthWarning] = useState('')

	useEffect(() => {
		if (note.length > 990) {
			setNoteLengthWarning(
				note.length > 1000
					? 'Please limit notes to 1000 characters'
					: `${1000 - note.length} characters remaining`,
			)
		} else {
			setNoteLengthWarning('')
		}
	}, [note])

	const addNote = async (e: React.FormEvent) => {
		e.preventDefault()
		if (!note) return
		setNoteSaving(true)
		await onAddNote(note)
		setNoteSaving(false)
		setNote('')
	}

	return (
		<form style={{ flex: 1, width: '100%' }} onSubmit={addNote}>
			<Box display="flex">
				<MentionsInput
					value={note}
					onChange={(event: { target: { value: string } }) => setNote(event.target.value)}
					style={taggedInputStandard}>
					<Mention trigger="@" data={users} style={taggedMentionStandard} />
				</MentionsInput>
			</Box>

			<Box display="flex">
				{!!noteLengthWarning && <span className="cango-error-text">{noteLengthWarning}</span>}
			</Box>
			<Box display="flex" sx={{ mt: 1, mb: 2 }}>
				<Button type="submit" variant="contained" isLoading={noteSaving}>
					<SendIcon fontSize="small" />
				</Button>
			</Box>
		</form>
	)
}
