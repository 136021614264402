import { ComponentType, SVGProps } from 'react'

import { colors } from '../../theme/colors'

type FlagProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const FlagIcon: ComponentType<FlagProps> = ({
	width = 30,
	fill = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={fill}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M4 22v-7"
		></path>
		<path
			fill={fill}
			stroke={fill}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v12z"
		></path>
	</svg>
)
