import { createSelector } from '@reduxjs/toolkit'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'

import { CangoReduxModuleName, RootState } from '../../types'

import { NotesState } from './types'

const getNotesState: (state: RootState) => NotesState = createSelector(
	(state: RootState) => state[CangoReduxModuleName.CangoNotes],
	(notes) => notes,
)

const getTasksWithUnreadNotes = createSelector(
	getNotesState,
	({ tasksWithUnreadNotes }) => tasksWithUnreadNotes,
)

const getUnreadTaskIds = createSelector(getTasksWithUnreadNotes, (tasks) =>
	tasks.map(({ taskId }) => taskId),
)

const getInboxNavState = createSelector(getNotesState, (state) => state.inboxNavState)

const isLoadingInbox = createSelector(getNotesState, (state) => state.isLoadingInbox)
const isLoadingTaskNotes = createSelector(getNotesState, (state) => state.isLoadingTaskNotes)

const getInboxList = createSelector(getNotesState, (state) =>
	_orderBy(state.inboxNotes, 'timestamp', 'desc'),
)

const getTaskNotes = createSelector(getNotesState, (state) => state.taskNotes)

const getLatestNoteTime: (state: RootState) => number | undefined = createSelector(
	getTaskNotes,
	(notes) => {
		if (_isEmpty(notes)) return
		return notes[notes.length - 1].timestamp
	},
)

const getSelectedInboxTaskId: (state: RootState) => string | undefined = createSelector(
	getNotesState,
	(state) => state.selectedInboxTaskId,
)

const getSelectedInboxItem = createSelector(
	getSelectedInboxTaskId,
	getInboxList,
	(taskId, inboxList) => {
		if (!taskId) return
		return _find(inboxList, (item) => item.task._id === taskId)
	},
)

export const selectors = {
	getTasksWithUnreadNotes,
	getInboxNavState,
	isLoadingInbox,
	getInboxList,
	getTaskNotes,
	getLatestNoteTime,
	isLoadingTaskNotes,
	getSelectedInboxTaskId,
	getSelectedInboxItem,
	getUnreadTaskIds,
}
