import { OptionsObject, SnackbarMessage } from 'notistack'

let enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void

export const setSnackbarEnqueue = (enqueueFn: typeof enqueueSnackbar) => {
	enqueueSnackbar = enqueueFn
}

export const showSnackbar = (message: SnackbarMessage, options?: OptionsObject) => {
	if (enqueueSnackbar) {
		enqueueSnackbar(message, options)
	} else {
		console.error('Snackbar enqueueSnackbar not set')
	}
}
