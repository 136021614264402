import React, { ComponentType } from 'react'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'

import { Box } from '../box'
import { Text } from '../text'

export const LinearProgressWithLabel: ComponentType<LinearProgressProps & { value?: number }> = (
	props,
) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '300px', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			{!!props.value && (
				<Box sx={{ minWidth: 35 }}>
					<Text variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Text>
				</Box>
			)}
		</Box>
	)
}
