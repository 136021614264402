import React, { ComponentType, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FeedbackForm } from 'src/modules/help/feedback-form'
import {
	UniversalDrawerType,
	actions as configActions,
	selectors as configSelectors,
} from 'src/store/modules/config'
import { useIsMobile } from 'src/hooks/useMobile'
import { Modal } from 'src/components/modal'

import { AsyncDispatchType } from '../../../store/types'
import { Box } from '../../box'
import Drawer from '../../drawer'
import { Text } from '../../text'

import { NewProjectTaskForm } from './new-project-task-form'
import { NewProjectFormV3 } from './new-project-form-v3'

const AddDrawer: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const universalDrawerType = useSelector(configSelectors.getUniversalDrawerType)
	const isMobile = useIsMobile()

	const handleCloseDrawer = () => {
		dispatch(configActions.setUniversalDrawerType(undefined))
	}

	const addForm = useMemo(() => {
		switch (universalDrawerType) {
			case UniversalDrawerType.Project:
				return {
					title: 'New Project',
					component: <NewProjectFormV3 onFormClose={handleCloseDrawer} />,
				}
			// case UniversalDrawerType.Task:
			// 	return {
			// 		title: 'New Project Task',
			// 		component: <NewProjectTaskForm />,
			// 	}
			case UniversalDrawerType.Feedback:
				return {
					title: 'New Idea or Bug',
					component: <FeedbackForm />,
				}
			default:
				return {
					title: '',
					component: null,
				}
		}
	}, [universalDrawerType])

	if (isMobile) {
		return (
			<Modal open={!!universalDrawerType} onClose={handleCloseDrawer}>
				<Box p={2} display="flex" flexDirection="column">
					<Text sx={{ mb: { laptop: 2 } }} variant={isMobile ? 'h6' : 'h4'}>
						{addForm.title}
					</Text>
					{!!addForm.component && addForm.component}
				</Box>
			</Modal>
		)
	}

	return (
		<Drawer open={!!universalDrawerType} onClose={handleCloseDrawer}>
			<Box width={400} p={2} display="flex" flexDirection="column">
				<Text sx={{ mb: 2 }} variant="h4">
					{addForm.title}
				</Text>
				{!!addForm.component && addForm.component}
			</Box>
		</Drawer>
	)
}

export default AddDrawer
