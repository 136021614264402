import { ComponentType } from 'react'

import { IconType } from '../index'

export const ContactsIcon: ComponentType<IconType> = ({ width = 20, fill = '#000', ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={width}
		fill="none"
		viewBox="0 0 20 20"
		{...props}>
		<path
			fill={fill}
			d="M12.81 10.28a3.73 3.73 0 001-2.5 3.78 3.78 0 10-7.56 0 3.73 3.73 0 001 2.5A5.94 5.94 0 004 14.89a1.006 1.006 0 102 .22 4 4 0 017.94 0A1 1 0 0015 16h.11a1 1 0 00.88-1.1 5.94 5.94 0 00-3.18-4.62zM10 9.56A1.78 1.78 0 1110 6a1.78 1.78 0 010 3.56zM17 0H3a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V3a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h14a1 1 0 011 1v14z"></path>
	</svg>
)
