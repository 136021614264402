import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type OpenTabLinkProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const OpenTabLink: ComponentType<OpenTabLinkProps> = ({
	width = 24,
	stroke = colors.feldgrau['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3"
		></path>
	</svg>
)
