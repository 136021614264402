import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type VideoCameraIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const VideoCameraIcon: ComponentType<VideoCameraIconProps> = ({
	width = 20,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M23 7l-7 5 7 5V7zM14 5H3a2 2 0 00-2 2v10a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2z"></path>
	</svg>
)
