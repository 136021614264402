import { ComponentType, SVGProps } from 'react'

type CloseIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const CloseIcon: ComponentType<CloseIconProps> = ({
	width = 15,
	stroke = '#545454',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 15 15" {...props}>
		<path stroke={stroke} strokeLinecap="round" strokeWidth="2" d="M14 1L1 14M1 1l13 13"></path>
	</svg>
)
