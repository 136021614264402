import React, { ComponentType, useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useSelector } from 'react-redux'
import Stack from '@mui/system/Stack'

import { CloseIcon } from 'src/assets/icons'
import { useFileUpload } from 'src/hooks/useFileUpload'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

import googleDriveLogo from '../../assets/images/google_drive_logo.png'
import { Box } from '../box'
import { Modal } from '../modal'
import { TabPanel } from '../tab-panel'
import { IconButton } from '../icon-button'
import { Text } from '../text'
import { useMyTasks } from '../../hooks/useMyTasks'
import { useProject } from '../../modules/projects-v3/useProject'
import { RootState } from '../../store/types'
import { ChainList } from '../section-tasks-v3/chains-list'

import { UploadPanel } from './panels/upload-panel'
import { FilesPanel } from './panels/files-panel'
import { UploadConfirmDialog } from './panels/upload-confirm-dialog'
import { DriveUploadPanelProps } from './types'
import { useFetchFilesWithRetry } from './use-fetch-files-with-retry'

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export const DriveUploadPanelWeb: ComponentType<DriveUploadPanelProps> = ({
	parentFolderId,
	parentFolderName,
	allFiles,
	fetchFiles,
	isLoadingFiles,
	onlyUpload,
	isOpen,
	setIsOpen,
	onFileIdsChange,
	selectedFilesIds,
}) => {
	const { selectedTaskId: selectedMyTasksTaskId } = useMyTasks()
	const { selectedTaskId: selectedProjectTaskId } = useProject()
	const selectedTask = useSelector((state: RootState) =>
		projectSelectors.getSelectedTask(state, selectedMyTasksTaskId ?? selectedProjectTaskId),
	)
	const [showConfirmClose, setShowConfirmClose] = useState<boolean>(false)
	const [selectedTab, setSelectedTab] = useState(onlyUpload ? 1 : 0)
	const [selectedFiles, setSelectedFiles] = useState<string[]>(selectedFilesIds)
	const [errorRetryingFileFetch, setErrorRetryingFileFetch] = useState(false)
	const fileUploadProps = useFileUpload({ parentFolderId, parentFolderName })
	const { attempt, countdown, fetchFilesWithAllIdsPresent } = useFetchFilesWithRetry({
		fetchFiles,
	})

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue)
	}

	const onConfirmClose = (forceStop: boolean) => {
		fileUploadProps.terminateUpload(forceStop)
		setIsOpen(false)
	}

	const handleCloseModal = () => {
		if (fileUploadProps.areFilesUploading) {
			setShowConfirmClose(true)
			return
		}
		onConfirmClose(true)
	}

	const onFilesUploaded = async (fileIds: string[]) => {
		if (onlyUpload) {
			await onFileIdsChange(fileIds)
			handleCloseModal()
			return
		}

		setSelectedFiles((s) => [...s, ...fileIds])
		setSelectedTab(0)
		try {
			await fetchFilesWithAllIdsPresent(fileIds)
		} catch (error) {
			setErrorRetryingFileFetch(true)
		}
	}

	const handleSubmit = async (fileIds: string[]) => {
		fileUploadProps.terminateUpload(true)
		await onFileIdsChange(fileIds)
		handleCloseModal()
	}

	const content = () => {
		if (!parentFolderId) return null
		return (
			<Box>
				{!!selectedTask && (
					<Stack>
						<Text variant="overline">{selectedTask.name}</Text>
						<ChainList chain={selectedTask.chain} />
					</Stack>
				)}
				<Box display="flex" justifyContent="space-between" width="100%" height="72px">
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={selectedTab} onChange={handleChangeTab}>
							{!onlyUpload && (
								<Tab
									label="Select from Google Drive"
									sx={{ textTransform: 'none' }}
									{...a11yProps(0)}
									icon={<img src={googleDriveLogo} width={20} />}
									iconPosition="start"
								/>
							)}
							<Tab
								label="Upload to Google Drive"
								sx={{ textTransform: 'none' }}
								{...a11yProps(1)}
								icon={<img src={googleDriveLogo} width={20} />}
								iconPosition="start"
							/>
						</Tabs>
					</Box>
					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						height="100%"
						paddingRight={2}
					>
						<IconButton onClick={handleCloseModal}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
				{!onlyUpload && (
					<TabPanel value={selectedTab} index={0} containerProps={{ flex: 1, overflow: 'hidden' }}>
						<FilesPanel
							selectedFiles={selectedFiles}
							setSelectedFiles={setSelectedFiles}
							parentFolderName={parentFolderName}
							files={allFiles}
							onSubmit={handleSubmit}
							fetchError={errorRetryingFileFetch}
							isLoadingFiles={isLoadingFiles}
							attemptNumber={attempt}
							countdown={countdown}
						/>
					</TabPanel>
				)}
				<TabPanel value={selectedTab} index={1} containerProps={{ flex: 1 }}>
					<UploadPanel
						fileUploadProps={fileUploadProps}
						onFilesUploaded={onFilesUploaded}
						onSendToBackground={() => onConfirmClose(false)}
					/>
				</TabPanel>
				<UploadConfirmDialog
					showConfirmClose={showConfirmClose}
					setShowConfirmClose={() => setShowConfirmClose(false)}
					onConfirmClose={onConfirmClose}
				/>
			</Box>
		)
	}

	return (
		<Modal open={isOpen} onClose={handleCloseModal}>
			<Box width={700} minHeight={500} maxHeight={700} maxWidth="100%" bgcolor="white">
				{content()}
			</Box>
		</Modal>
	)
}
