import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import Papa, { ParseResult } from 'papaparse'
import { v4 } from 'uuid'

import { Box, Button, IconButton, TextField } from '../../../../components'
import { TrashIcon } from '../../../../assets/icons'
import { SingleSelectForm } from '../types'

export const CustomOptions = () => {
	const { control } = useFormContext<SingleSelectForm>()
	const { fields, append, remove, update } = useFieldArray({ control, name: 'options' })

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
		e.preventDefault()
		e.stopPropagation() // prevents it's handled by onPaste on core table component
		const clipboardData = e.clipboardData
		const pastedData = clipboardData.getData('text')
		if (!pastedData) return

		Papa.parse(pastedData as any, {
			delimiter: '\t',
			newline: '\n',
			skipEmptyLines: true,
			quoteChar: '"',
			complete: (results: ParseResult<string[]>) => {
				const rowsByCell = results.data as string[][]
				const allValues = rowsByCell.map((_row) => _row[0])
				let indexTicker = index
				for (const data of allValues) {
					if (fields[indexTicker]) {
						update(indexTicker, { _id: fields[indexTicker]._id, label: data })
					} else {
						append({ _id: v4(), label: data })
					}
					indexTicker++
				}
			},
		})
	}

	return (
		<Box my={1} flex={1}>
			{fields.map((_field, index) => {
				return (
					<Box key={_field.id} display="flex" alignItems="center" width="100%" mb={1}>
						<Controller
							control={control}
							name={`options.${index}.label`}
							render={({ field }) => {
								return (
									<TextField
										{...field}
										label={`Option ${index + 1}`}
										fullWidth
										size="small"
										containerProps={{ width: 400 }}
										onPaste={(e) => handlePaste(e, index)}
									/>
								)
							}}
						/>
						<Box display="flex" alignItems="center" pt={2}>
							<IconButton onClick={() => remove(index)}>
								<TrashIcon />
							</IconButton>
						</Box>
					</Box>
				)
			})}
			<Button
				variant="text"
				onClick={() => {
					append({ _id: v4(), label: '' })
				}}
			>
				Add option
			</Button>
		</Box>
	)
}
