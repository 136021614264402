import { closeSnackbar, SnackbarKey } from 'notistack'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { Button } from 'src/components/button'

const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					borderRadius: '10px',
					color: '#fff',
				},
			},
		},
	},
})

export const snackbarActions = (snackbarId: SnackbarKey) => (
	<ThemeProvider theme={theme}>
		<Button
			onClick={() => {
				closeSnackbar(snackbarId)
			}}
			size="small"
			sx={{
				text: {
					color: 'white',
				},
			}}
		>
			Dismiss
		</Button>
	</ThemeProvider>
)
