import { createSelector } from '@reduxjs/toolkit'
import { FilesSdk } from '@cango-app/sdk'

import { CangoReduxModuleName, RootState } from '../../types'

import { FILES_STORAGE } from './files-storage'

const getFilesRootState: (state: RootState) => RootState[CangoReduxModuleName.CangoPersistedFiles] =
	createSelector(
		(state: RootState) => state,
		({ cangoPersistedFiles }) => cangoPersistedFiles,
	)

const getProjectFiles: (state: RootState, projectFolderId?: string) => FilesSdk.File[] =
	createSelector(
		getFilesRootState,
		(state: RootState, projectFolderId?: string) => projectFolderId,
		(filesState, projectFolderId) => {
			if (!projectFolderId) return []
			if (!filesState[projectFolderId]) return []
			return filesState[projectFolderId].files
		},
	)

const getIsLoadingFilesForProjectId: (state: RootState, projectFolderId?: string) => boolean =
	createSelector(
		getFilesRootState,
		(state: RootState, projectFolderId?: string) => projectFolderId,
		(filesState, projectFolderId) => {
			if (!projectFolderId) return false
			if (!filesState[projectFolderId]) return false
			return filesState[projectFolderId].loading
		},
	)

const getMappedProjectFiles = createSelector(
	getProjectFiles,
	(files) => new Map(files.map((file) => [file.id, file])),
)

const getStagedFilesWithBinaryData = createSelector(
	getFilesRootState,
	(state: RootState, projectFolderId?: string) => projectFolderId,
	(filesState, projectFolderId) => {
		if (!projectFolderId) return []
		if (!filesState[projectFolderId]) return []
		return filesState[projectFolderId].stagedFiles.map((stagedFile, i) => ({
			...stagedFile,
			file: FILES_STORAGE.get(projectFolderId, i),
		}))
	},
)

const areFilesUploading = createSelector(getStagedFilesWithBinaryData, (stagedFiles) => {
	if (!stagedFiles.length) return false
	return stagedFiles.some((file) => file.state === FilesSdk.FileUploadState.Uploading)
})

const haveAllFilesUploaded = createSelector(getStagedFilesWithBinaryData, (stagedFiles) => {
	if (!stagedFiles.length) return true
	return stagedFiles.every((file) => file.state === FilesSdk.FileUploadState.Complete)
})

const hasUploadError = createSelector(getStagedFilesWithBinaryData, (stagedFiles) => {
	if (!stagedFiles.length) return false
	return stagedFiles.some((file) => file.state === FilesSdk.FileUploadState.Failed)
})

const projectsWithActiveUploads = createSelector(getFilesRootState, (filesState) => {
	const projectsFolderIds = Object.keys(filesState)
	return projectsFolderIds
		.map((folderId) => {
			return {
				parentFolderId: folderId,
				parentFolderName: filesState[folderId].parentFolderName,
			}
		})
		.filter((project) => {
			const stagedFiles = filesState[project.parentFolderId].stagedFiles
			return stagedFiles?.length > 0
		})
})

export const selectors = {
	getProjectFiles,
	getMappedProjectFiles,
	getIsLoadingFilesForProjectId,
	getStagedFilesWithBinaryData,
	areFilesUploading,
	haveAllFilesUploaded,
	hasUploadError,
	projectsWithActiveUploads,
}
