import React, { ComponentType, memo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BoxProps } from '@mui/material/Box'

import { Box } from './box'
import { Button } from './button'
import { TextField } from './text-field'

type NameInputProps = BoxProps & {
	name: string
	onUpdateClick: (newName: string) => Promise<void>
	onChangeComplete?: () => void
}

type NameInputForm = {
	name: string
}

const UndecoratedNameInput: ComponentType<NameInputProps> = ({
	name,
	onChangeComplete,
	onUpdateClick,
	...boxProps
}) => {
	const {
		control,
		formState: { dirtyFields },
		handleSubmit,
	} = useForm<NameInputForm>({
		defaultValues: { name },
	})
	const [isUpdatingName, setIsUpdatingName] = useState(false)

	const handleSaveName = async (data: NameInputForm) => {
		setIsUpdatingName(true)
		await onUpdateClick(data.name)
		setIsUpdatingName(false)
		if (onChangeComplete) {
			onChangeComplete()
		}
	}

	return (
		<Box {...boxProps}>
			<Controller
				control={control}
				name="name"
				rules={{ required: true }}
				render={({ field: { value, onChange } }) => (
					<TextField
						label="Name"
						value={value}
						onChange={(event) => onChange(event.target.value)}
						autoComplete="off"
						fullWidth
						containerProps={{ mb: 0 }}
					/>
				)}
			/>

			<Button
				disabled={!dirtyFields.name || isUpdatingName}
				onClick={handleSubmit(handleSaveName)}
				isLoading={isUpdatingName}
				sx={{ width: '100%', mt: 1 }}
				variant="outlined"
				size="small">
				Update name
			</Button>
		</Box>
	)
}

export const NameInput = memo(UndecoratedNameInput)
