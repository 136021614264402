import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import React, { useContext, useEffect } from 'react'

import { TableContext } from '../../../../providers/table-provider'
import { SingleSelectForm } from '../types'
import { TableFilters } from '../../../../components/table-filters'

type Props = {
	index: number
}

export const TableOptionsFilters = ({ index }: Props) => {
	const { control } = useFormContext<SingleSelectForm>()
	const { fields } = useFieldArray({ control, name: 'valueOptionFilters' })
	const { apiRef } = useContext(TableContext)

	useEffect(() => {
		if (!apiRef.current.setFilterModel) return
		apiRef.current.setFilterModel(fields[index].filters)
	}, [apiRef, fields, index])

	return (
		<Controller
			control={control}
			name={`valueOptionFilters.${index}.filters`}
			render={({ field: { onChange } }) => <TableFilters onChange={onChange} />}
		/>
	)
}
