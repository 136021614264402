import React, { ComponentType, createContext, PropsWithChildren, useMemo } from 'react'

export const DriveFilesContext = createContext<{
	parentFolderId?: string
	parentFolderName?: string
}>({
	parentFolderId: undefined,
	parentFolderName: undefined,
})

export const DriveFilesProvider: ComponentType<
	PropsWithChildren<{ parentFolderName?: string; parentFolderId?: string }>
> = (props) => {
	const contextValue = useMemo(
		() => ({
			parentFolderId: props.parentFolderId,
			parentFolderName: props.parentFolderName,
		}),
		[props.parentFolderId, props.parentFolderName],
	)

	return (
		<DriveFilesContext.Provider value={contextValue}>{props.children}</DriveFilesContext.Provider>
	)
}
