import { createSelector } from "@reduxjs/toolkit"

import { RootState } from "src/store/types"

const getCangoSessionState = (state: RootState) => state.cangoSession


const getSelectedOrganisationId: (state: RootState) => string | undefined = createSelector(
	getCangoSessionState,
	(authState) => authState?.selectedOrganisationId,
)


export const selectors = {
  getSelectedOrganisationId
}