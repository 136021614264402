import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../types'
import { selectors as authSelectors } from '../auth/selectors'
import {selectors as sessionSelectors } from '../session-config'

import { CangoSchemaState, ConfigState, RemoteConfigState } from './types'

const getConfigState = (state: RootState) => state.cangoConfig

const getConfig: (state: RootState) => ConfigState = createSelector(
	getConfigState,
	(config) => config,
)

const getSelectedOrganisationId: (state: RootState) => string | undefined = createSelector(
	getConfigState,
	(configState) => {
		return configState?.selectedOrganisationId
	},
)

const getRemoteConfigState: (state: RootState) => RemoteConfigState = createSelector(
	getConfigState,
	({ remoteConfig }) => remoteConfig,
)

const getIsMobileMenuOpen: (state: RootState) => boolean = createSelector(
	getConfigState,
	({ isMobileMenuOpen }) => isMobileMenuOpen,
)

const getCangoSchemaState: (state: RootState) => CangoSchemaState = createSelector(
	getConfigState,
	({ cangoSchemaState }) => cangoSchemaState,
)

const shouldShowUpdateSchemaOverlay: (state: RootState) => boolean = createSelector(
	getConfigState,
	({ cangoSchemaState }) =>
		cangoSchemaState === CangoSchemaState.RequiresMigration ||
		cangoSchemaState === CangoSchemaState.Loading ||
		cangoSchemaState === CangoSchemaState.MigrationInProgress,
)

const isUpdatingSchema: (state: RootState) => boolean = createSelector(
	getConfigState,
	({ cangoSchemaState }) => cangoSchemaState === CangoSchemaState.Loading,
)

const schemaMigrationAlreadyInProgress: (state: RootState) => boolean = createSelector(
	getConfigState,
	({ cangoSchemaState }) => cangoSchemaState === CangoSchemaState.MigrationInProgress,
)

const getUniversalDrawerType: (state: RootState) => string | undefined = createSelector(
	getConfigState,
	({ universalDrawerType }) => universalDrawerType,
)

const getOrganisationId: (state: RootState) => string | undefined = createSelector(
	sessionSelectors.getSelectedOrganisationId,
	authSelectors.getDefaultOrganisationId,
	authSelectors.getLastVisitedOrganisationId,
	(selectedOrganisationId, defaultOrganisationId, getLastVisitedOrganisationId) => {
		return selectedOrganisationId ?? getLastVisitedOrganisationId ??  defaultOrganisationId
	},
)

export const selectors = {
	getConfig,
	getRemoteConfigState,
	getIsMobileMenuOpen,
	getSelectedOrganisationId,
	shouldShowUpdateSchemaOverlay,
	isUpdatingSchema,
	schemaMigrationAlreadyInProgress,
	getCangoSchemaState,
	getUniversalDrawerType,
	getOrganisationId,
}
