import { ComponentType } from 'react'

import { colors } from 'src/theme/colors'

import { Box } from '../box'
import { Text } from '../text'

import { Select, SelectProps } from './select'

type ContactSelectProps = SelectProps & {
	onNewContactClick: () => void
}

export const ContactSelect: ComponentType<ContactSelectProps> = ({
	onNewContactClick,
	containerProps,
	...props
}) => (
	<Box {...containerProps}>
		<Select {...props} />
		{!props.disabled && (
			<Box display="flex">
				<Text
					color={colors.primary.main}
					fontWeight="bold"
					onClick={onNewContactClick}
					fontSize={12}
					sx={{ cursor: 'pointer' }}
				>
					+ New Contact
				</Text>
			</Box>
		)}
	</Box>
)
