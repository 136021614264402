export enum RemoteConfigKey {
	MinimumAppVersion = 'minimumAppVersion',
	PrivacyPolicy = 'privacyPolicy',
	RevertInstanceEnabled = 'revertInstanceEnabled',
	SyncEnabled = 'syncEnabled',
	UploadUpdateEnabled = 'uploadUpdateEnabled',
	cameraEnabled = 'cameraEnabled',
	isDatabasesEnabled = 'isDatabasesEnabled',
	enableHq = 'enableHq',
	enableInbox = 'enableInbox',
	bidsEnabled = 'bidsEnabled',
	enableMultipleActions = 'enableMultipleActions',
}

type RemoteConfigs = {
	[RemoteConfigKey.MinimumAppVersion]: string
	[RemoteConfigKey.PrivacyPolicy]: string
	[RemoteConfigKey.RevertInstanceEnabled]: boolean
	[RemoteConfigKey.SyncEnabled]: boolean
	[RemoteConfigKey.UploadUpdateEnabled]: boolean
	[RemoteConfigKey.cameraEnabled]: boolean
	[RemoteConfigKey.isDatabasesEnabled]: boolean
	[RemoteConfigKey.enableHq]: boolean
	[RemoteConfigKey.enableInbox]: boolean
	[RemoteConfigKey.bidsEnabled]: boolean
	[RemoteConfigKey.enableMultipleActions]: boolean
}

export type RemoteConfigState = {
	isLoading: boolean
	configs: Partial<RemoteConfigs>
}
