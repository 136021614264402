import React, { ComponentType } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, LinearProgressWithLabel, Text } from 'src/components'
import { RouteId } from 'src/constants/routes'

type LoadingBlueprintProps = {
	errorText: string
	docType?: string
	returnToRoute: RouteId
	customText?: string
}

export const LoadingDocument: ComponentType<LoadingBlueprintProps> = ({
	errorText,
	docType,
	returnToRoute,
	customText,
}) => {
	const navigate = useNavigate()

	const handleGoBackClick = () => {
		navigate(`/${returnToRoute}`, { replace: true })
	}

	return (
		<Box
			alignItems="center"
			display="flex"
			height="100vh"
			justifyContent="center"
			flexDirection="column">
			{errorText ? (
				<>
					<Text color={errorText ? 'red' : undefined} mb={2}>
						{errorText}
					</Text>
					<Button variant="contained" onClick={handleGoBackClick}>
						Go back
					</Button>
				</>
			) : (
				<>
					<LinearProgressWithLabel variant="indeterminate" />
					<Text>{`Loading${docType ? ' ' + docType : ''}...`}</Text>
					{!!customText && <Text>{customText}</Text>}
				</>
			)}
		</Box>
	)
}
