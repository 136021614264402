import React, { ComponentType, useState } from 'react'
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BoxProps } from '@mui/material/Box'
import dayjs from 'dayjs'

import { Box } from './box'
import { Text } from './text'

type DatePickerProps = Omit<DesktopDatePickerProps<any>, 'renderInput'> & {
	containerProps?: BoxProps
}

export const DatePicker: ComponentType<DatePickerProps> = ({
	containerProps,
	...datePickerProps
}) => {
	const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false)

	const handleOpenDatePicker = () => {
		setIsDatePickerDialogOpen(true)
	}

	const handleCloseDatePicker = () => {
		setIsDatePickerDialogOpen(false)
	}

	return (
		<Box {...containerProps}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DesktopDatePicker
					{...datePickerProps}
					open={isDatePickerDialogOpen}
					onOpen={handleOpenDatePicker}
					onClose={handleCloseDatePicker}
					slotProps={{ textField: { sx: { width: '100%' }, onClick: handleOpenDatePicker } }}
					format="MMMM dd, yyyy"
					minDate={dayjs('01/01/2022')}
				/>
				<Box height={24}>
					{!!datePickerProps.loading && <Text fontSize={12}>Please wait, saving...</Text>}
				</Box>
			</LocalizationProvider>
		</Box>
	)
}
