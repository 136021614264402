import { Stack } from '@mui/material'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'
import _isEmpty from 'lodash/isEmpty'

import { Box, Button, Chip, Text, TextField } from '../../../components'
import { TableContext } from '../../../providers/table-provider'
import { showSnackbar } from '../../../helpers/snackbarManager'

type Form = {
	concatination: string
}

type Props = {
	columnId: string
}

export const replaceIdsWithLabels = (
	input: string,
	mappedColumns: Map<string, TableTypes.Field>,
	referenceColumnNames: TableTypes.ReferenceColumnNames,
) => {
	return input.replace(/{{([a-f0-9-]+(?:\.[a-z0-9-]+)*)}}/gi, (match, id) => {
		const [baseId, nestedPath]: [string, string | undefined] = id.split('.')
		const column = mappedColumns.get(baseId)

		if (!column) {
			return match
		}

		if (
			column.type === TableTypes.FieldType.REFERENCE &&
			nestedPath &&
			referenceColumnNames[baseId]
		) {
			const referencedColumn = referenceColumnNames[baseId].find(
				(_refCol) => _refCol._id === nestedPath,
			)
			if (referencedColumn) {
				return `{{${column.name}.${referencedColumn.label}}}`
			}
		}

		return `{{${column.name}}}`
	})
}

const replaceLabelsWithIds = (
	input: string,
	mappedColumns: Map<string, TableTypes.Field>,
	referenceColumnNames: TableTypes.ReferenceColumnNames,
) => {
	return input.replace(/{{([^}]+)}}/gi, (match, label) => {
		const [baseLabel, nestedLabel] = label.split('.')
		let foundId: string | undefined

		for (const [id, column] of mappedColumns) {
			if (column.name === baseLabel) {
				foundId = id
				break
			}
		}

		if (!foundId) {
			return match
		}

		if (
			nestedLabel &&
			referenceColumnNames[foundId] &&
			mappedColumns.get(foundId)?.type === TableTypes.FieldType.REFERENCE
		) {
			const referencedColumn = referenceColumnNames[foundId].find(
				(_refCol) => _refCol.label === nestedLabel,
			)
			if (referencedColumn) {
				return `{{${foundId}.${referencedColumn._id}}}`
			}
		}

		return `{{${foundId}}}`
	})
}

export const ConcatinationModal = ({ columnId }: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const { onUpdateColumn, mappedColumns, table } = useContext(TableContext)
	const inputRef = useRef<any>()
	const columns = useMemo(() => {
		if (!table) {
			return []
		}
		const columnList = table.fields
		return columnList
			.map((_column) => ({
				..._column,
				_id: _column._id,
				label: _column.name,
			}))
			.filter(({ _id, type }) => _id !== columnId && type !== TableTypes.FieldType.REFERENCE)
	}, [table?.fields, table?.referenceColumnNames])

	const { control, handleSubmit, setValue } = useForm<Form>({
		defaultValues: {
			concatination: mappedColumns.get(columnId)?.concatination ?? '',
		},
	})

	const onSubmit = useCallback(
		async ({ concatination }: Form) => {
			setIsLoading(true)
			try {
				await onUpdateColumn(columnId, {
					concatination: replaceLabelsWithIds(
						concatination,
						mappedColumns,
						table?.referenceColumnNames ?? {},
					),
				})
			} catch (e) {
				showSnackbar('Error saving concatenation', { variant: 'error' })
			} finally {
				setIsLoading(false)
			}
		},
		[onUpdateColumn, mappedColumns, table?.referenceColumnNames],
	)

	const handleAddVariableAtCursorPosition = (newId: string) => {
		const input = inputRef.current
		if (input) {
			const currentValue = input.value
			const cursorPosition = input.selectionStart || currentValue.length
			const textBeforeCursor = currentValue.substring(0, cursorPosition)
			const textAfterCursor = currentValue.substring(cursorPosition)
			const newValue = `${textBeforeCursor}{{${newId}}}${textAfterCursor}`
			setValue('concatination', newValue)
		}
	}

	return (
		<Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
			<Stack direction="column" spacing={2}>
				<Text variant="h5">Create your concatenated text</Text>
				<Text>Table variables: </Text>
				<Box>
					<Box display="flex" flexWrap="wrap">
						{columns.map(({ _id, label }) => {
							return (
								<Chip
									key={_id}
									variant="outlined"
									label={label}
									sx={{
										mr: 1,
										mb: 1,
									}}
									onClick={() => {
										handleAddVariableAtCursorPosition(_id)
									}}
								/>
							)
						})}
					</Box>
					<Box>
						{!!table?.referenceColumnNames &&
							!_isEmpty(table.referenceColumnNames) &&
							Object.keys(table.referenceColumnNames).map((columnId) => {
								const column = mappedColumns.get(columnId)
								if (!column) {
									return null
								}
								const referencedTableColumnNames = table.referenceColumnNames[columnId]
								if (!referencedTableColumnNames?.length) {
									return null
								}

								return (
									<Box key={columnId} mb={2}>
										<Text component="label" fontSize={14} fontWeight={500}>
											{column.name}
										</Text>
										<Stack
											direction="row"
											flexWrap="wrap"
											marginY={0.5}
											sx={{
												overflowX: 'auto',
											}}
										>
											{referencedTableColumnNames.map(({ label, _id }) => (
												<Box key={_id} marginRight={1} mb={1}>
													<Chip
														variant="outlined"
														color="primary"
														label={label}
														onClick={() => {
															handleAddVariableAtCursorPosition(`${columnId}.${_id}`)
														}}
													/>
												</Box>
											))}
										</Stack>
									</Box>
								)
							})}
					</Box>
				</Box>

				<Controller
					control={control}
					name="concatination"
					render={({ field: { value, onChange } }) => {
						const parsedValue = replaceIdsWithLabels(
							value ?? '',
							mappedColumns,
							table?.referenceColumnNames ?? {},
						)

						return (
							<TextField
								label="Concatination:"
								fullWidth
								value={parsedValue}
								onChange={onChange}
								sx={{ mb: 2 }}
								inputRef={(ref) => (inputRef.current = ref)}
							/>
						)
					}}
				/>
			</Stack>
			<Stack direction="row" spacing={2} paddingTop="auto">
				<Button fullWidth isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
					Save
				</Button>
				<Button fullWidth variant="text" onClick={() => setValue('concatination', '')}>
					Clear all
				</Button>
			</Stack>
		</Box>
	)
}
