import React, { ComponentType } from 'react'

export const CsvDocumentIcon: ComponentType = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		style={{
			//@ts-ignore
			enableBackground: 'new 0 0 512 512',
		}}
		xmlSpace="preserve">
		<path
			style={{
				fill: '#ecedef',
			}}
			d="M100.641 0c-14.139 0-25.6 11.461-25.6 25.6v460.8c0 14.139 11.461 25.6 25.6 25.6h375.467c14.139 0 25.6-11.461 25.6-25.6V85.333L416.375 0H100.641z"
		/>
		<path
			style={{
				fill: '#d9dcdf',
			}}
			d="M441.975 85.333h59.733L416.375 0v59.733c0 14.139 11.461 25.6 25.6 25.6z"
		/>
		<path
			style={{
				fill: '#c6cacf',
			}}
			d="M399.308 42.667H75.041v153.6h324.267a8.533 8.533 0 0 0 8.533-8.533V51.2a8.533 8.533 0 0 0-8.533-8.533z"
		/>
		<path
			style={{
				fill: '#c4df64',
			}}
			d="M382.241 179.2H18.843c-7.602 0-11.41-9.191-6.034-14.567L75.041 102.4 12.809 40.167C7.433 34.791 11.241 25.6 18.843 25.6h363.398a8.533 8.533 0 0 1 8.533 8.533v136.533a8.533 8.533 0 0 1-8.533 8.534z"
		/>
		<path
			style={{
				fill: '#c6cacf',
			}}
			d="M399.308 460.8H177.441a8.533 8.533 0 0 1-8.533-8.533V230.4a8.533 8.533 0 0 1 8.533-8.533h221.867a8.533 8.533 0 0 1 8.533 8.533v221.867a8.534 8.534 0 0 1-8.533 8.533z"
		/>
		<path
			style={{
				fill: '#b3b9bf',
			}}
			d="M185.975 443.733V221.867h-8.533a8.533 8.533 0 0 0-8.533 8.533v221.867a8.533 8.533 0 0 0 8.533 8.533h221.867a8.533 8.533 0 0 0 8.533-8.533v-8.533H185.975z"
		/>
		<path
			style={{
				fill: '#fff',
			}}
			d="M185.975 145.067h-25.6a8.533 8.533 0 0 1-8.533-8.533v-51.2a8.533 8.533 0 0 1 8.533-8.533h25.6a8.533 8.533 0 0 1 0 17.066h-17.067V128h17.067a8.535 8.535 0 0 1 0 17.067zM237.175 145.067h-25.6a8.533 8.533 0 0 1 0-17.066h17.067v-8.533h-17.067a8.533 8.533 0 0 1-8.533-8.533v-25.6a8.533 8.533 0 0 1 8.533-8.533h25.6a8.533 8.533 0 0 1 0 17.066h-17.067v8.533h17.067a8.533 8.533 0 0 1 8.533 8.533v25.6a8.534 8.534 0 0 1-8.533 8.533zM279.841 145.067a8.535 8.535 0 0 1-8.096-5.835l-17.067-51.2a8.535 8.535 0 0 1 5.397-10.794 8.531 8.531 0 0 1 10.795 5.397l8.971 26.913 8.971-26.913a8.536 8.536 0 0 1 10.795-5.397 8.535 8.535 0 0 1 5.397 10.794l-17.067 51.2a8.533 8.533 0 0 1-8.096 5.835z"
		/>
		<path
			style={{
				fill: '#8e959f',
			}}
			d="M237.175 273.067h-34.133a8.533 8.533 0 0 1 0-17.066h34.133a8.533 8.533 0 0 1 0 17.066zM373.708 273.067h-102.4a8.533 8.533 0 0 1 0-17.066h102.4a8.533 8.533 0 0 1 0 17.066zM237.175 324.267h-34.133a8.533 8.533 0 0 1 0-17.066h34.133a8.533 8.533 0 0 1 0 17.066zM373.708 324.267h-102.4a8.533 8.533 0 0 1 0-17.066h102.4a8.533 8.533 0 0 1 0 17.066zM237.175 375.467h-34.133a8.533 8.533 0 0 1 0-17.066h34.133a8.533 8.533 0 0 1 0 17.066zM296.908 375.467h-25.6a8.533 8.533 0 0 1 0-17.066h25.6a8.533 8.533 0 0 1 0 17.066zM237.175 426.667h-34.133a8.533 8.533 0 0 1 0-17.066h34.133a8.533 8.533 0 0 1 0 17.066zM296.908 426.667h-25.6a8.533 8.533 0 0 1 0-17.066h25.6a8.533 8.533 0 0 1 0 17.066z"
		/>
		<path
			style={{
				fill: '#529e44',
			}}
			d="m363.483 392.533 16.781-20.137a8.534 8.534 0 0 0-13.11-10.927l-14.778 17.733-14.778-17.733a8.534 8.534 0 0 0-13.11 10.927l16.78 20.137-16.781 20.137c-2.754 3.305-2.574 8.309.425 11.399 3.484 3.59 9.485 3.368 12.686-.474l14.778-17.733 14.778 17.733c3.201 3.842 9.202 4.063 12.686.474 2.998-3.09 3.179-8.095.425-11.399l-16.782-20.137z"
		/>
	</svg>
)
