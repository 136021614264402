import { TableTypes } from '@cango-app/types'

export const columnsWithSettings = [
	TableTypes.FieldType.CALCULATION,
	TableTypes.FieldType.SINGLE_SELECT,
	TableTypes.FieldType.CONCATINATION,
]

export type SingleSelectForm = {
	options: TableTypes.Field['valueOptions']
	single_select_lookup: {
		tableId: string
		fieldId: string
	}
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
}
