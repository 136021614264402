import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import * as thunks from './thunks'
import { RemoteConfigState } from './types'

const initialState: RemoteConfigState = {
	isLoading: true,
	configs: {},
}

export const authSlice = createSlice({
	name: 'remote-config',
	initialState,
	reducers: {
		endSession: () => initialState,
		setMinimumAppVersion: (state, action: PayloadAction<string>) => {
			state.configs.minimumAppVersion = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.fetchConfig.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(thunks.fetchConfig.fulfilled, (state, action) => {
			return {
				isLoading: false,
				configs: action.payload ?? {},
			}
		})
		builder.addCase(thunks.fetchConfig.rejected, (state) => {
			state.isLoading = false
		})
	},
})
