import { ComponentType, SVGProps } from 'react'

type MeetingProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Meeting: ComponentType<MeetingProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 28 22" {...props}>
		<path
			fill={stroke}
			stroke="#886402"
			d="M20.25 20.375h-.5a.75.75 0 11-1.5 0 8 8 0 00-16 0 .75.75 0 01-1.5 0 9.5 9.5 0 015.581-8.645l.712-.322-.59-.511A5.65 5.65 0 014.5 6.625a5.75 5.75 0 1111.5 0 5.65 5.65 0 01-1.952 4.272l-.59.511.71.322a9.5 9.5 0 015.582 8.645h.5zm0 0a10 10 0 00-5.389-8.864m5.389 8.864a1.25 1.25 0 01-2.5 0 7.5 7.5 0 00-15 0 1.25 1.25 0 01-2.5 0 10 10 0 015.389-8.864h9.222m0 0a6.5 6.5 0 01-.159.142l-.327-.378.206-.456.185.086.095.606zM17.75.875h0a5.75 5.75 0 014.301 9.568l-.393.442.502.314a10.75 10.75 0 015.052 9.173v.003a.75.75 0 01-1.5.002 9.25 9.25 0 00-5.273-8.423l-.131-.07-.468-.313-.003-.001a.75.75 0 01.038-1.274h0A4.25 4.25 0 0022 6.626v-.001a4.25 4.25 0 00-4.25-4.25.75.75 0 110-1.5zm-9.861 9.284A4.25 4.25 0 1012.61 3.09 4.25 4.25 0 007.89 10.16z"></path>
	</svg>
)
