import { StagedFileData } from './types'

export type UpdaterCb = (stagedFiles: StagedFileData[]) => StagedFileData[]
export type Updater = (parentFolderId: string, cb: UpdaterCb) => void

export class FilesStorage {
	private files: Map<string, File>
	private updater: Updater | null = null

	constructor() {
		this.files = new Map()
	}

	static key(parentFolderId: string, fileIndex: number) {
		return `${parentFolderId}_${fileIndex}`
	}

	setUpdater(updater: Updater) {
		this.updater = updater
	}

	update(parentFolderId: string, cb: UpdaterCb) {
		if (this.updater == null) {
			throw new Error('Updater is not set, first call setUpdater!')
		}
		this.updater(parentFolderId, cb)
	}

	set(parentFolderId: string, fileIndex: number, file: File) {
		this.files.set(FilesStorage.key(parentFolderId, fileIndex), file)
		const time = new Date().getTime()
		this.update(parentFolderId, (files) =>
			files.map((file, i) => {
				if (i === fileIndex) {
					return {
						...file,
						fileModifiedAt: time,
					}
				}
				return file
			}),
		)
	}

	get(parentFolderId: string, fileIndex: number) {
		const key = FilesStorage.key(parentFolderId, fileIndex)
		const file = this.files.get(key)

		if (!file) {
			throw new Error('File not found in storage!!!')
		}

		return file
	}

	remove(parentFolderId: string, fileIndex: number) {
		// We need to remove the file first to avoid race conditions
		this.update(parentFolderId, (files) => files.filter((f, i) => i !== fileIndex))
		this.files.delete(FilesStorage.key(parentFolderId, fileIndex))
	}
}

export const FILES_STORAGE = new FilesStorage()
