import React, { ComponentType, PropsWithChildren } from 'react'
import * as Sentry from '@sentry/react'

import { ErrorFallback } from './index'

export const ErrorBoundary: ComponentType<PropsWithChildren> = ({ children }) => {
	return (
		<Sentry.ErrorBoundary
			fallback={({ error, resetError }) => <ErrorFallback error={error} resetError={resetError} />}
		>
			{children}
		</Sentry.ErrorBoundary>
	)
}
