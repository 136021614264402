import React, {
	ComponentType,
	HTMLInputTypeAttribute,
	useEffect,
	useMemo,
	useState,
	FormEvent,
} from 'react'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { TypographyProps } from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import { BoxProps } from '@mui/material/Box'

import { colors } from 'src/theme/colors'
import { EmailInput } from 'src/assets/icons/actions'
import { SearchIcon } from 'src/assets/icons'
import { useIsMobile } from 'src/hooks/useMobile'

import { Text } from './text'
import { Box } from './box'
import { Button } from './button'

export type TextFieldProps = MuiTextFieldProps & {
	label?: string
	colorLabel?: TypographyProps['color']
	fullWidth?: boolean
	containerProps?: BoxProps
	bgColor?: string
	onSubmit?: () => void
	type?: HTMLInputTypeAttribute | 'chat'
}

export const TextField: ComponentType<TextFieldProps> = ({
	label,
	colorLabel = colors.neutral['80'],
	required,
	variant = 'outlined',
	fullWidth,
	containerProps,
	type,
	value,
	bgColor,
	onSubmit,
	...props
}) => {
	const isMobile = useIsMobile()
	const [error, setError] = useState<string | null>(null)

	const handleFormSubmit = (event: FormEvent<HTMLFormElement> | KeyboardEvent) => {
		event.preventDefault()
		if (!onSubmit) return
		onSubmit()
	}

	useEffect(() => {
		if (type === 'email') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			if (value && !emailRegex.test(value as string)) {
				setError('Invalid email address')
			} else {
				setError(null)
			}
		}
	}, [type, value])

	const startAdornment = useMemo(() => {
		if (type === 'email') {
			return <EmailInput />
		}
		if (type === 'search') {
			return <SearchIcon />
		}
	}, [type])

	const endAdornment = useMemo(() => {
		if (type === 'chat') {
			return (
				<Button type="submit" variant="text">
					Send
				</Button>
			)
		}
	}, [type])

	const TextField = (
		<MuiTextField
			color="borders"
			rows={props.multiline ? 3 : undefined}
			aria-label={label}
			variant={variant}
			required={required}
			value={value}
			error={!!error || props.error}
			helperText={error || props.helperText}
			onFocus={() => {
				if (!isMobile) return
				window.scrollTo(0, 0)
				document.body.scrollTop = 0
			}}
			InputProps={{
				sx: {
					bgcolor: bgColor ?? '#fff',
					height: 'auto',
					overflowY: 'auto',
				},
				startAdornment: startAdornment ?? null,
				endAdornment: endAdornment ?? null,
			}}
			{...props}
		/>
	)

	return (
		<Box {...containerProps}>
			{!!label && (
				<Text fontSize={14} color={colorLabel} fontWeight={500}>
					{label} {!!required && '*'}
				</Text>
			)}
			<FormControl fullWidth={fullWidth} required={required} error={props.error}>
				{onSubmit ? (
					<form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
						{TextField}
					</form>
				) : (
					TextField
				)}
			</FormControl>
		</Box>
	)
}
