import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type FileUploadProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const FileUpload: ComponentType<FileUploadProps> = ({
	width = 30,
	stroke = colors.sunglow['100'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 30 30" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M20 20l-5-5-5 5M15 15v11.25"></path>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M25.487 22.987A6.25 6.25 0 0022.5 11.25h-1.575A10 10 0 103.75 20.375"></path>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M20 20l-5-5-5 5"></path>
	</svg>
)
