import { ComponentType } from 'react'

import { Box, IBoxProps } from './box'

type TabPanelProps<T extends string | number> = {
	children?: React.ReactNode
	index: T extends string ? string : number
	value: T
	containerProps?: IBoxProps
}

export const TabPanel: ComponentType<TabPanelProps<string | number>> = (props) => {
	const { children, value, index, containerProps } = props

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...containerProps}
		>
			{value === index && <Box height="100%">{children}</Box>}
		</Box>
	)
}
