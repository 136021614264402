import { EditorContent, Editor } from '@tiptap/react'

import 'public/rich-editor-styles.css'

type Props = {
	editor: Editor
}

export const TipTapContentWithStyles = ({ editor }: Props) => {
	return <EditorContent editor={editor} />
}
