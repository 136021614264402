import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type CircledCheckIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const CircledCheckIcon: ComponentType<CircledCheckIconProps> = ({
	width = 24,
	fill = colors.feldgrau['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill={fill}
			d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 10-1.41 1.41l2.35 2.36a1 1 0 001.41 0l5-5a1.002 1.002 0 10-1.41-1.42zM12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16.001A8 8 0 0112 20z"></path>
	</svg>
)
