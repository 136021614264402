import React, { ComponentType } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import { Box } from '../box'

export const DrawerLoading: ComponentType = () => (
	<Box
		position="absolute"
		bgcolor="rgba(255, 255, 255, 0.8)"
		display="flex"
		alignItems="center"
		justifyContent="center"
		zIndex={1000}
		left={0}
		right={0}
		top={0}
		bottom={0}>
		<CircularProgress size={40} />
	</Box>
)
