import { ComponentType } from 'react'

import { IconType } from './index'

export const HardHat: ComponentType<IconType> = ({ width = 22, fill = '#000', ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={22}
		height={width * 0.6363636363}
		fill="none"
		viewBox="0 0 22 14"
		{...props}>
		<path
			fill={fill}
			d="M19 8.17V8a8 8 0 00-2.42-5.74A7.84 7.84 0 0013.4.38h-.05A8.24 8.24 0 0010.76 0 8.21 8.21 0 003 8.17 3 3 0 004 14h14a3 3 0 001-5.83zM18 12H4a1 1 0 010-2h2a1 1 0 000-2H5a6.41 6.41 0 013-5.15V6a1 1 0 002 0V2.09c.271-.045.545-.075.82-.09H11c.335.004.67.037 1 .1V6a1 1 0 102 0V2.82c.424.246.82.538 1.18.87A6 6 0 0117 8h-1a1 1 0 100 2h2a1 1 0 110 2z"></path>
	</svg>
)
