import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { RouteId } from 'src/constants/routes'
import { selectors as authSelectors } from 'src/store/modules/auth'

export const useProject = () => {
	const navigate = useNavigate()
	const organisationId = useSelector(authSelectors.getOrganisationId)
	const { projectId, selectedTaskId } = useParams<{
		projectId: string
		selectedTaskId?: string
	}>()

	const goToTask = (taskId?: string) => {
		if (!taskId) {
			navigate(`/${RouteId.Project}/${projectId}?orgId=${organisationId}`)
			return
		}

		navigate(`/${RouteId.Project}/${projectId}/${taskId}?orgId=${organisationId}`)
	}

	return {
		projectId,
		selectedTaskId,
		goToTask,
	}
}
