import { ComponentType, SVGProps } from 'react'

type EmailInputProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const EmailInput: ComponentType<EmailInputProps> = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="none" viewBox="0 0 16 12">
		<path
			fill="#9CA3AF"
			d="M.003 1.884L8 5.882l7.997-3.998A2 2 0 0014 0H2A2 2 0 00.003 1.884z"></path>
		<path fill="#9CA3AF" d="M16 4.118l-8 4-8-4V10a2 2 0 002 2h12a2 2 0 002-2V4.118z"></path>
	</svg>
)
