import { useNavigate, useParams } from 'react-router-dom'

import { RouteId } from 'src/constants/routes'

export const useMyTasks = () => {
	const navigate = useNavigate()
	const { selectedProjectId, selectedSectionId, selectedTaskId } = useParams<{
		selectedProjectId?: string
		selectedSectionId?: string
		selectedTaskId?: string
	}>()

	const goToProject = (projectId?: string) => {
		navigate(`/${RouteId.MyTasks}/${projectId}`)
	}

	const goToSection = (sectionId?: string) => {
		navigate(`/${RouteId.MyTasks}/${selectedProjectId}/${sectionId}`)
	}

	const goToTask = (taskId?: string) => {
		navigate(`/${RouteId.MyTasks}/${selectedProjectId}/${selectedSectionId}/${taskId}`)
	}

	return {
		selectedProjectId,
		selectedSectionId,
		selectedTaskId,
		goToProject,
		goToSection,
		goToTask,
	}
}
