import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type PlusIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const PlusIcon: ComponentType<PlusIconProps> = ({
	width = 24,
	stroke = colors.feldgrau['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M12 5v14M5 12h14"></path>
	</svg>
)
