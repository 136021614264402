import { RouteId } from 'src/constants/routes'

export enum AnalyticsEvent {
	error = 'error',
	stepCreated = 'step_created',
	stepUpdated = 'step_updated',
	stepRowClick = 'step_row_click',
	downloadBlueprintClicked = 'download_blueprint_clicked',
	uploadBlueprintClicked = 'upload_blueprint_clicked',
	blueprintNameUpdated = 'blueprint_name_updated',
	blueprintPublishSwitched = 'blueprint_publish_switched',
	blueprintDeleted = 'blueprint_deleted',

	// Nav
	navMenuItemClicked = 'nav_menu_item_clicked',
	navAllProjectsClicked = 'nav_all_projects_clicked',
	navProjectClicked = 'nav_project_clicked',

	// Files
	fileRowClicked = 'file_row_clicked',
	fileDownloaded = 'file_downloaded',
	changeFileIds = 'change_file_ids',

	// General
	buttonClicked = 'button_clicked',
	milestoneSet = 'milestone_set',
	chatNoteSent = 'chat_note_sent',
	chatFilesSent = 'chat_files_sent',
	blockedTaskSubmited = 'blocked_task_submited',

	// Contacts
	assignUsersToRoles = 'assign_users_to_roles',
	assignContactsToProject = 'assign_contacts_to_project',
}

const pageView = (pageName: string): void => {
	window.analytics.page(pageName)
}

const identify = (userId: string, traits?: Record<string, any>): void => {
	window.analytics.identify(userId, traits)
}

type NonEnforcedEventData = Exclude<AnalyticsEvent, AnalyticsEvent.buttonClicked>

type EventData =
	| {
			eventName: AnalyticsEvent.buttonClicked
			properties: { name: string; screen: RouteId; [propertyKey: string]: any }
	  }
	| { eventName: NonEnforcedEventData; properties?: Record<string, any> }

const track = ({ eventName, properties }: EventData): void => {
	window.analytics.track(eventName, properties)
}

export const analytics = {
	pageView,
	identify,
	track,
}
