import { ComponentType, SVGProps } from 'react'

type ProjectsIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
	fill?: string
}

export const ProjectsIcon: ComponentType<ProjectsIconProps> = ({
	width = 23,
	fill = '#fff',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 23 18" {...props}>
		<path
			fill={fill}
			d="M21.78 7.37A1 1 0 0021 7h-2V6a3 3 0 00-3-3H9.72L9.4 2a3 3 0 00-2.84-2H3a3 3 0 00-3 3v12a3 3 0 003 3h14.4a3 3 0 002.92-2.35L22 8.22a1 1 0 00-.22-.85zM4.37 15.22a1 1 0 01-1 .78H3a1 1 0 01-1-1V3a1 1 0 011-1h3.56a1 1 0 011 .68l.54 1.64A1 1 0 009 5h7a1 1 0 011 1v1H7a1 1 0 00-1 .78l-1.63 7.44zm14 0a1 1 0 01-1 .78H6.21c.052-.111.089-.229.11-.35L7.8 9h12l-1.43 6.22z"></path>
	</svg>
)
