import { ComponentType, SVGProps } from 'react'

type FileTemplateProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const FileTemplate: ComponentType<FileTemplateProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 16 20" {...props}>
		<path
			fill={stroke}
			d="M5 8h1a1 1 0 000-2H5a1 1 0 000 2zm0 2a1 1 0 000 2h6a1 1 0 000-2H5zm11-3.06a1.307 1.307 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19.32.32 0 00-.09 0A.88.88 0 009.05 0H3a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3V7v-.06zm-6-3.53L12.59 6H11a1 1 0 01-1-1V3.41zM14 17a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h5v3a3 3 0 003 3h3v9zm-3-3H5a1 1 0 000 2h6a1 1 0 000-2z"></path>
	</svg>
)
