import { createSelector } from '@reduxjs/toolkit'
import { compareVersions } from 'compare-versions'

import packageInfo from '../../../../package.json'
import { RootState } from '../../types'

import { RemoteConfigState } from './types'

const getRemoteConfigState: (state: RootState) => RemoteConfigState = createSelector(
	(state: RootState) => state,
	({ cangoRemoteConfig }) => cangoRemoteConfig,
)

const getConfigs: (state: RootState) => RemoteConfigState['configs'] = createSelector(
	getRemoteConfigState,
	({ configs }) => configs,
)

const shouldUpdateApp: (state: RootState) => boolean = createSelector(
	getConfigs,
	({ minimumAppVersion }) => {
		if (!minimumAppVersion) {
			return false
		}
		return compareVersions(packageInfo.version, minimumAppVersion) === -1
	},
)

const isCameraEnabled: (state: RootState) => boolean = createSelector(
	getConfigs,
	({ cameraEnabled }) => {
		return cameraEnabled ?? false
	},
)

const isUploadUpdateEnabled: (state: RootState) => boolean = createSelector(
	getConfigs,
	({ uploadUpdateEnabled }) => uploadUpdateEnabled ?? false,
)

const isRevertInstanceEnabled: (state: RootState) => boolean = createSelector(
	getConfigs,
	({ revertInstanceEnabled }) => revertInstanceEnabled ?? false,
)

const getPrivacyPolicy: (state: RootState) => string = createSelector(
	getConfigs,
	({ privacyPolicy }) => {
		return privacyPolicy ?? ''
	},
)

const isRemoteConfigLoading: (state: RootState) => boolean = createSelector(
	getRemoteConfigState,
	({ isLoading }) => isLoading,
)

const isDatabasesEnabled = createSelector(
	getConfigs,
	({ isDatabasesEnabled }) => isDatabasesEnabled ?? false,
)

const isBidBuilderEnabled = createSelector(getConfigs, ({ bidsEnabled }) => bidsEnabled ?? false)

const isInboxEnabled = createSelector(getConfigs, ({ enableInbox }) => enableInbox ?? false)

const isMultipleActionsEnabled = createSelector(
	getConfigs,
	({ enableMultipleActions }) => enableMultipleActions ?? false,
)

export const selectors = {
	getConfigs,
	shouldUpdateApp,
	isCameraEnabled,
	isUploadUpdateEnabled,
	isRevertInstanceEnabled,
	getPrivacyPolicy,
	isRemoteConfigLoading,
	isDatabasesEnabled,
	isInboxEnabled,
	isBidBuilderEnabled,
	isMultipleActionsEnabled,
}
