import React, { ComponentType, useState } from 'react'
import { useSelector } from 'react-redux'

import { Drawer } from 'src/components'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import type { RootState } from 'src/store/types'

import { DeleteRole } from './delete-role'
import { RoleForm } from './role-form'

type RoleFormProps = {
	open: boolean
	roleId?: string
	setShowRoleDrawer?: (open: boolean) => void
	setSelectedRole?: (roleId: string | undefined) => void
	onNewRoleAdded?: (roleId: string) => void
}

const RoleDrawer: ComponentType<RoleFormProps> = ({
	open,
	roleId,
	setShowRoleDrawer,
	setSelectedRole,
	onNewRoleAdded,
}) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)

	const role = useSelector((state: RootState) => roleSelectors.getRoleById(state, roleId))

	const handleCloseDrawer = () => {
		if (setSelectedRole) {
			setSelectedRole(undefined)
		}
		setShouldPromptDelete(false)
		if (setShowRoleDrawer) {
			setShowRoleDrawer(false)
		}
	}

	return (
		<Drawer open={open} onClose={handleCloseDrawer}>
			{shouldPromptDelete && role ? (
				<DeleteRole role={role} closeDrawer={handleCloseDrawer} />
			) : (
				<RoleForm
					role={role}
					closeDrawer={handleCloseDrawer}
					setShouldPromptDelete={setShouldPromptDelete}
					onNewRoleAdded={onNewRoleAdded}
				/>
			)}
		</Drawer>
	)
}

export default RoleDrawer
