import React, { ClientTypes } from '@cango-app/types'
import { ComponentType, useState } from 'react'

import { Button } from '../button'

import { Note } from './note'

const MORE_THRESHOLD = 2

type AllNotesProps = {
	notes: ClientTypes.Note[]
	showAll?: boolean
}

export const AllNotes: ComponentType<AllNotesProps> = ({ notes, showAll = false }) => {
	const [showingAll, setShowingAll] = useState(showAll)
	const noteList = showingAll ? notes : notes.slice(0, MORE_THRESHOLD)

	return (
		<>
			{noteList.map((note: ClientTypes.Note, index) => {
				return <Note key={`${note}:${index}`} {...note} />
			})}
			{notes.length > MORE_THRESHOLD && !showingAll && (
				<Button onClick={() => setShowingAll(true)}>
					Show {notes.length - 2} more note{notes.length - 2 > 1 && 's'}
				</Button>
			)}
		</>
	)
}
