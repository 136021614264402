import React, { useState, ComponentType } from 'react'
import Avatar from '@mui/material/Avatar'

import { getRandomColour } from '../routing/navigation/utils'

type UserAvatarProps = {
	userInitials: string
	size?: number
	fontSize?: number
	isWarning?: boolean
	picture: string | undefined
}

export const UserAvatar: ComponentType<UserAvatarProps> = ({
	userInitials,
	size = 32,
	fontSize = 14,
	isWarning,
	picture,
}) => {
	const [imgError, setImgError] = useState(false)

	const avatarStyle = {
		width: size,
		height: size,
		fontSize,
		bgcolor: isWarning ? 'red' : getRandomColour(userInitials),
	}

	return (
		<Avatar
			sx={avatarStyle}
			src={!imgError ? picture : undefined}
			onError={() => setImgError(true)}>
			{imgError || isWarning ? (isWarning ? '!' : userInitials) : null}
		</Avatar>
	)
}
