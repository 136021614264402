import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type SearchIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const SearchIcon: ComponentType<SearchIconProps> = ({
	width = 20,
	stroke = colors.feldgrau['60'],
	...props
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={width}
		fill="none"
		viewBox="0 0 20 20"
		{...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M9.167 15.833a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333zM17.5 17.5l-3.625-3.625"></path>
	</svg>
)
