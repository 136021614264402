import React, { ComponentType } from 'react'
import { ClientTypes } from '@cango-app/types'
import FlagIcon from '@mui/icons-material/Flag'
import { useSelector } from 'react-redux'

import { relativeTimeLabel } from 'src/helpers/labels'
import { selectors as userSelectors } from 'src/store/modules/user'

import { Box } from '../box'
import { Text } from '../text'
import { MentionedText } from '../mentions/mentioned-text'

export const Note: ComponentType<ClientTypes.Note> = ({ created_by, timestamp, text, isFlag }) => {
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	return (
		<Box
			my={1}
			px={2}
			py={1}
			borderRadius={4}
			bgcolor={isFlag ? 'rgba(255, 152, 0, 0.7)' : 'rgba(76, 175, 80, 0.5)'}>
			<Box display="flex">
				{!!isFlag && <FlagIcon color="error" sx={{ mr: 1 }} />}
				<Text fontWeight="bold">{mappedUsers.get(created_by)?.name ?? 'Unknown author'}</Text>
			</Box>
			<Text>
				<MentionedText raw={text} />
			</Text>
			<Text color="#333" width="100%" textAlign="right" fontStyle="italic" fontSize={14}>
				{relativeTimeLabel(timestamp)}
			</Text>
		</Box>
	)
}
