export const getRandomColour = (initials = '') => {
	if (!initials) {
		// If no initials provided, return a random color
		const hue = Math.floor(Math.random() * 360)
		return `hsl(${hue}, 70%, 60%)`
	}

	// If initials are provided, compute a consistent color
	let hash = 0
	for (let i = 0; i < initials.length; i++) {
		const char = initials.charCodeAt(i)
		hash = (hash << 5) - hash + char
		hash |= 0
	}

	const hue = Math.abs(hash) % 360
	return `hsl(${hue}, 70%, 60%)`
}

export const getNameInitials = (name?: string, surname?: string) => {
	if (!name || !surname) return ''
	const firstInitial = name[0]
	const lastNameInitial = surname[0]
	return firstInitial + lastNameInitial
}
