import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type TrashIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const TrashIcon: ComponentType<TrashIconProps> = ({
	width = 20,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height="20"
		fill="none"
		viewBox="0 0 18 20"
		{...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M16 5l-.867 12.142A2 2 0 0113.138 19H4.862a2 2 0 01-1.995-1.858L2 5m5 4v6m4-6v6m1-10V2a1 1 0 00-1-1H7a1 1 0 00-1 1v3M1 5h16"></path>
	</svg>
)
