import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type BackArrowOutlinedProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const BackArrowOutlined: ComponentType<BackArrowOutlinedProps> = ({
	width = 20,
	fill = colors.feldgrau['60'],
	...props
}) => (
	<svg width={width} fill="none" viewBox="0 0 20 20" {...props}>
		<path
			fill={fill}
			d="M6.29 9.29a1 1 0 00-.21.33 1 1 0 000 .76 1 1 0 00.21.33l3 3a1.004 1.004 0 101.42-1.42L9.41 11H13a1 1 0 000-2H9.41l1.3-1.29a1 1 0 00-.325-1.639 1 1 0 00-1.095.219l-3 3zM0 10a10 10 0 1020 0 10 10 0 00-20 0zm18 0a8 8 0 11-16 0 8 8 0 0116 0z"></path>
	</svg>
)
