import { ChainsSdk, TablesSdk, V3ProjectSdk } from '@cango-app/sdk'
import { ComponentType, useCallback, useEffect, useReducer, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { PulseLoader } from 'react-spinners'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box } from 'src/components/box'
import { ContactSelect, Select } from 'src/components/select'
import { selectors as rolesSelectors } from 'src/store/modules/roles'
import { selectors as contactsSelectors } from 'src/store/modules/contacts'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { selectors as configSelectors } from 'src/store/modules/config'
import { TextField } from 'src/components/text-field'
import { Text } from 'src/components/text'
import { Button } from 'src/components/button'
import { RouteId } from 'src/constants/routes'
import { AsyncDispatchType } from 'src/store/types'
import { ContactForm } from 'src/modules/contacts/contact-drawer/contact-form'
import { showSnackbar } from 'src/helpers/snackbarManager'

type QuickProjectV3Form = V3ProjectSdk.CreateProjectRequest

enum QuickProjectActionType {
	FETCH_BLUEPRINTS = 'FETCH_BLUEPRINTS',
	FETCH_BLUEPRINTS_SUCCESS = 'FETCH_BLUEPRINTS_SUCCESS',
	FETCH_BLUEPRINTS_ERROR = 'FETCH_BLUEPRINTS_ERROR',
	FETCH_ROLES_AND_EXTERNALS = 'FETCH_ROLES_AND_EXTERNALS',
	FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS',
	FETCH_EXTERNALS_SUCCESS = 'FETCH_EXTERNALS_SUCCESS',
	FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR',
	FETCH_EXTERNALS_ERROR = 'FETCH_EXTERNALS_ERROR',
	SUBMIT_PROJECT = 'SUBMIT_PROJECT',
}

interface FetchBlueprintsAction {
	type: QuickProjectActionType.FETCH_BLUEPRINTS
}

interface FetchBlueprintsSuccessAction {
	type: QuickProjectActionType.FETCH_BLUEPRINTS_SUCCESS
	payload: { _id: string; label: string }[]
}

interface FetchBlueprintsErrorAction {
	type: QuickProjectActionType.FETCH_BLUEPRINTS_ERROR
}

interface FetchRolesAndExternalsAction {
	type: QuickProjectActionType.FETCH_ROLES_AND_EXTERNALS
}

interface FetchRolesSuccessAction {
	type: QuickProjectActionType.FETCH_ROLES_SUCCESS
}

interface FetchExternalsSuccessAction {
	type: QuickProjectActionType.FETCH_EXTERNALS_SUCCESS
}

interface FetchRolesErrorAction {
	type: QuickProjectActionType.FETCH_ROLES_ERROR
}

interface FetchExternalsErrorAction {
	type: QuickProjectActionType.FETCH_EXTERNALS_ERROR
}

interface SubmitLoadingAction {
	type: QuickProjectActionType.SUBMIT_PROJECT
	payload: boolean
}

type QuickProjectAction =
	| FetchBlueprintsAction
	| FetchBlueprintsSuccessAction
	| FetchBlueprintsErrorAction
	| FetchRolesAndExternalsAction
	| FetchRolesSuccessAction
	| FetchExternalsSuccessAction
	| FetchRolesErrorAction
	| FetchExternalsErrorAction
	| SubmitLoadingAction

// An interface for our state
interface QuickProjectState {
	blueprintNames: { _id: string; label: string; database_table?: string }[]
	isLoadingBlueprints: boolean
	hasError: boolean
	isLoadingRoles: boolean
	isLoadingExternals: boolean
	isSubmitting: boolean
}

const quickProjectReducer = (
	state: QuickProjectState,
	action: QuickProjectAction,
): QuickProjectState => {
	const { type } = action
	switch (type) {
		case QuickProjectActionType.FETCH_BLUEPRINTS:
			return {
				...state,
				isLoadingBlueprints: true,
			}
		case QuickProjectActionType.FETCH_BLUEPRINTS_SUCCESS:
			return {
				...state,
				isLoadingBlueprints: false,
				blueprintNames: action.payload,
			}
		case QuickProjectActionType.FETCH_BLUEPRINTS_ERROR:
			return {
				...state,
				hasError: true,
				isLoadingBlueprints: false,
			}
		case QuickProjectActionType.FETCH_ROLES_AND_EXTERNALS:
			return {
				...state,
				isLoadingRoles: true,
				isLoadingExternals: true,
			}
		case QuickProjectActionType.FETCH_ROLES_SUCCESS:
			return {
				...state,
				isLoadingRoles: false,
			}
		case QuickProjectActionType.FETCH_EXTERNALS_SUCCESS:
			return {
				...state,
				isLoadingExternals: false,
			}
		case QuickProjectActionType.FETCH_ROLES_ERROR:
			return {
				...state,
				isLoadingRoles: false,
			}
		case QuickProjectActionType.FETCH_EXTERNALS_ERROR:
			return {
				...state,
				isLoadingExternals: false,
			}
		case QuickProjectActionType.SUBMIT_PROJECT:
			return {
				...state,
				isSubmitting: action.payload,
			}
		default:
			return state
	}
}

export const NewProjectFormV3: ComponentType<{ onFormClose: () => void }> = ({ onFormClose }) => {
	const storeDispatch = useDispatch<AsyncDispatchType>()
	const location = useLocation()
	const navigate = useNavigate()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [state, dispatch] = useReducer(quickProjectReducer, {
		blueprintNames: [],
		isLoadingBlueprints: false,
		hasError: false,
		isLoadingRoles: false,
		isLoadingExternals: false,
		isSubmitting: false,
	})
	const { control, watch, setValue, handleSubmit } = useForm<Required<QuickProjectV3Form>>({
		defaultValues: {
			roleAssignments: [],
			contactAssignments: [],
		},
	})
	const [newContactRole, setNewContactRole] = useState<{
		_id: string
		name: string
		index: number
	}>()
	const [selectedDB, setSelectedDB] = useState('')
	const mappedRoles = useSelector(rolesSelectors.getMappedRoles)
	const usersForSelect = useSelector(userSelectors.getAllUsersForSelectWithUnassigned)
	const contactsForSelect = useSelector(contactsSelectors.getContactsForSelectWithUnassigned)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const [tableList, setTableList] = useState<{ _id: string; label: string }[]>([])
	const [isFetchingTableList, setIsFetchingTableList] = useState(false)

	const [selectedBlueprintId, contactAssignments, roleAssignments] = watch([
		'blueprintId',
		'contactAssignments',
		'roleAssignments',
	])

	const handleSuccessToastClick = (projectId: string) => {
		navigate(`/project/${projectId}?orgId=${organisationId}`)
	}

	const fetchTableList = useCallback(async () => {
		try {
			setIsFetchingTableList(true)
			const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, authHeaders)
			setTableList(response.map(({ _id, name }) => ({ _id, label: name })))
		} catch (error) {
			showSnackbar('Error fetching databases', { variant: 'error' })
		} finally {
			setIsFetchingTableList(false)
		}
	}, [])

	const fetchBlueprintNames = useCallback(async () => {
		dispatch({ type: QuickProjectActionType.FETCH_BLUEPRINTS })
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'true',
			})
			dispatch({
				type: QuickProjectActionType.FETCH_BLUEPRINTS_SUCCESS,
				payload: response.map(({ _id, name, database_table }) => ({
					_id,
					label: name,
					database_table,
				})),
			})
		} catch (error) {
			dispatch({ type: QuickProjectActionType.FETCH_BLUEPRINTS_ERROR })
			Sentry.captureException(error)
		}
	}, [])

	const fetchRolesAndExternals = useCallback(async (blueprintId: string) => {
		dispatch({ type: QuickProjectActionType.FETCH_ROLES_AND_EXTERNALS })
		try {
			const { roles } = await ChainsSdk.getRoles(
				import.meta.env.VITE_API as string,
				authHeaders,
				blueprintId,
			)
			dispatch({
				type: QuickProjectActionType.FETCH_ROLES_SUCCESS,
			})
			setValue(
				'roleAssignments',
				roles.map((roleId) => {
					const role = mappedRoles.get(roleId)
					return {
						role: roleId,
						user: role?.defaultUser,
					}
				}),
			)
		} catch (error) {
			dispatch({ type: QuickProjectActionType.FETCH_ROLES_ERROR })
			Sentry.captureException(error)
		}

		try {
			const { externals } = await ChainsSdk.getExternals(
				import.meta.env.VITE_API as string,
				authHeaders,
				blueprintId,
			)
			dispatch({
				type: QuickProjectActionType.FETCH_EXTERNALS_SUCCESS,
			})
			const filteredExternals = externals.filter((_external) => mappedRoles.has(_external))
			setValue(
				'contactAssignments',
				filteredExternals.map((externalId) => {
					const role = mappedRoles.get(externalId)
					return {
						role: externalId,
						contact: role?.defaultContact,
					}
				}),
			)
		} catch (error) {
			dispatch({ type: QuickProjectActionType.FETCH_EXTERNALS_ERROR })
			Sentry.captureException(error)
		}
	}, [])

	const hasBlueprintTable = state.blueprintNames.find(
		({ _id }) => _id === selectedBlueprintId,
	)?.database_table
	const onSubmit = async (formData: Required<QuickProjectV3Form>) => {
		if (!hasBlueprintTable) {
			await linkDatabase()
		}
		dispatch({ type: QuickProjectActionType.SUBMIT_PROJECT, payload: true })
		try {
			const response = await V3ProjectSdk.createProject(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					name: formData.name,
					blueprintId: formData.blueprintId,
					roleAssignments: formData.roleAssignments,
					contactAssignments: formData.contactAssignments,
				},
			)
			showSnackbar('Project created', {
				variant: 'success',
				action: () => (
					<>
						<Button
							onClick={() => {
								handleSuccessToastClick(response._id)
							}}
							variant="text"
							sx={{ color: '#fff' }}
						>
							View
						</Button>
					</>
				),
			})
			onFormClose()

			if (location.pathname.includes(`/${RouteId.MyTasks}`)) {
				await storeDispatch(projectActions.fetchMyTasksProject(response._id))
			}

			navigate(`/${RouteId.MyTasks}/${response._id}`)
		} catch (error) {
			showSnackbar('Error creating project', { variant: 'error' })
		} finally {
			dispatch({ type: QuickProjectActionType.SUBMIT_PROJECT, payload: false })
		}
	}

	useEffect(() => {
		if (selectedBlueprintId) {
			if (!tableList.length) {
				fetchTableList()
			}
			fetchRolesAndExternals(selectedBlueprintId)
		}
	}, [selectedBlueprintId])

	useEffect(() => {
		fetchBlueprintNames()
	}, [])

	const linkDatabase = useCallback(async () => {
		try {
			await ChainsSdk.update(import.meta.env.VITE_API as string, authHeaders, selectedBlueprintId, {
				database_table: selectedDB,
			})
			await handleSubmit(onSubmit)
		} catch (error) {
			showSnackbar('Error selecting database', { variant: 'error' })
		}
	}, [handleSubmit, onSubmit, showSnackbar, selectedDB, selectedBlueprintId])

	if (newContactRole) {
		return (
			<Box display="flex" flexDirection="column">
				<Text sx={{ mb: 2 }}>Contact for {newContactRole?.name}</Text>
				<ContactForm
					closeDrawer={() => setNewContactRole(undefined)}
					setNewContactId={(contactId: string) => {
						setValue(`contactAssignments.${newContactRole.index}.contact`, contactId)
						setNewContactRole(undefined)
					}}
				/>
			</Box>
		)
	}

	return (
		<Box>
			<Controller
				control={control}
				name="blueprintId"
				rules={{ required: true }}
				render={({ field: { value, onChange } }) => (
					<Select
						required
						error={state.hasError}
						helperText={state.hasError ? 'Error fetching blueprints' : undefined}
						label="Blueprint"
						onChange={onChange}
						value={value}
						options={state.blueprintNames}
						containerProps={{ mb: 3 }}
						disabled={!state.blueprintNames.length}
					/>
				)}
			/>
			{!hasBlueprintTable && selectedBlueprintId && (
				<Select
					error={!tableList.length && !isFetchingTableList}
					helperText={!tableList.length ? 'Error fetching databases' : undefined}
					label="Database"
					onChange={(e) => setSelectedDB(e.target.value as string)}
					value={selectedDB}
					isLoading={isFetchingTableList}
					options={tableList}
					containerProps={{ mb: 3 }}
				/>
			)}
			<Controller
				control={control}
				name="name"
				rules={{ required: true }}
				render={({ field: { value, onChange } }) => (
					<TextField required label="Project Name" onChange={onChange} value={value} fullWidth />
				)}
			/>

			<Text variant="h6" sx={{ mt: 4, mb: 2 }}>
				Role Assignments
			</Text>

			{state.isLoadingRoles ? (
				<PulseLoader size={8} />
			) : roleAssignments.length ? (
				roleAssignments.map(({ role: roleId }, index) => {
					const role = mappedRoles.get(roleId)
					return (
						<Controller
							key={`roleAssignments.${index}.user`}
							control={control}
							name={`roleAssignments.${index}.user`}
							rules={{ required: 'Role is required' }}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<Select
									label={role?.label}
									onChange={onChange}
									value={value}
									options={usersForSelect}
									containerProps={{ mb: 1 }}
									error={!!error}
									helperText={error?.message}
								/>
							)}
						/>
					)
				})
			) : (
				<Text sx={{ mb: 3 }} color={'grey'}>
					No internal roles to assign
				</Text>
			)}

			<Text variant="h6" sx={{ mt: 4, mb: 2 }}>
				External Assignments
			</Text>

			{state.isLoadingExternals ? (
				<PulseLoader size={8} />
			) : contactAssignments.length ? (
				contactAssignments.map(({ role: roleId }, index) => {
					const role = mappedRoles.get(roleId)
					return (
						<Controller
							key={`contactAssignments.${index}.contact`}
							control={control}
							name={`contactAssignments.${index}.contact`}
							render={({ field: { value, onChange } }) => (
								<ContactSelect
									disabled={
										!!role?.internal &&
										roleAssignments.some(({ role: roleId }) => roleId === role?._id)
									}
									label={role?.label}
									onChange={onChange}
									value={value}
									options={contactsForSelect}
									containerProps={{ mb: 1 }}
									onNewContactClick={() => {
										if (!role) return
										setNewContactRole({ _id: roleId, name: role?.label, index })
									}}
								/>
							)}
						/>
					)
				})
			) : (
				<Text sx={{ mb: 3 }} color={'grey'}>
					No external contact roles
				</Text>
			)}

			<Button
				variant="contained"
				onClick={handleSubmit(onSubmit)}
				isLoading={state.isSubmitting}
				sx={{ my: 3 }}
			>
				Create Project
			</Button>
		</Box>
	)
}
