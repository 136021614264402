import React, { useCallback, useContext, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Controller, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import { TableTypes } from '@cango-app/types'

import { Button, TextField } from '../components'
import { TableContext } from '../providers/table-provider'
import { showSnackbar } from '../helpers/snackbarManager'
import RoleDrawer from '../modules/roles/role-drawer'

type ResolveData = {
	option:
		| {
				_id: string
				label: string
		  }
		| undefined
}

type PromiseHandlers = {
	resolve: (data: ResolveData) => void
	column: TableTypes.Field
}

const useNewDatabaseOption = (): [
	JSX.Element | null,
	(columnId: string) => Promise<ResolveData>,
] => {
	const [promise, setPromise] = useState<PromiseHandlers | null>(null)
	const { mappedColumns } = useContext(TableContext)
	const [isLoading] = useState(false)
	const { control, handleSubmit, getValues } = useForm<{
		optionName: string
	}>()

	const addOptionToColumn = (columnId: string): Promise<ResolveData> => {
		return new Promise((resolve) => {
			const column = mappedColumns.get(columnId)
			if (!column) {
				return
			}
			setPromise({
				resolve,
				column,
			})
		})
	}

	const handleClose = () => {
		setPromise(null)
	}

	const handleConfirmNewOption = async () => {
		const newOptionLabel = getValues('optionName')
		if (!promise) {
			return
		}
		const column = promise.column

		if (!column?.valueOptions) {
			showSnackbar('Column is not a single select', { variant: 'error' })
			return
		}

		const existingOption = column.valueOptions.find((_option) => _option.label === newOptionLabel)

		if (existingOption) {
			promise.resolve({ option: existingOption })
			return
		}

		const newOption = {
			_id: v4(),
			label: newOptionLabel,
		}

		promise?.resolve({ option: newOption })
		handleClose()
	}

	const handleConfirmNewRole = async (newRoleId: string) => {
		promise?.resolve({ option: { _id: newRoleId, label: 'New Role' } })
		handleClose()
	}

	const handleCancel = useCallback(() => {
		promise?.resolve({ option: undefined })
		handleClose()
	}, [promise])

	// You could replace the Dialog with your library's version
	const NewOption = useMemo(() => {
		if (!promise) {
			return null
		}

		if (promise.column.type === TableTypes.FieldType.ROLE) {
			return <RoleDrawer open onNewRoleAdded={handleConfirmNewRole} />
		}

		return (
			<Dialog
				open={true}
				fullWidth
				PaperProps={{
					sx: { borderRadius: 2, p: 2 },
				}}
			>
				<DialogContent sx={{ px: 0 }}>
					<Controller
						control={control}
						name="optionName"
						render={({ field: { value, onChange } }) => (
							<TextField
								label="New option name"
								value={value}
								onChange={onChange}
								fullWidth
								autoFocus
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										event.preventDefault()
										event.stopPropagation()
										handleConfirmNewOption()
									}
								}}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions sx={{ px: 0 }}>
					<Button onClick={handleConfirmNewOption} isLoading={isLoading}>
						Confirm
					</Button>
					<Button onClick={handleCancel} variant="text">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		)
	}, [promise, isLoading, handleSubmit, handleConfirmNewOption, control])

	return [NewOption, addOptionToColumn]
}

export default useNewDatabaseOption
