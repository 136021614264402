import React, { ComponentType, SVGProps } from 'react'

type ContactProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const Contact: ComponentType<ContactProps> = ({
	width = 30,
	stroke = '#886402',
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M20 8v6M23 11h-6M8.5 11a4 4 0 100-8 4 4 0 000 8z"></path>
	</svg>
)
