import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type HistoryIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}

export const HistoryIcon: ComponentType<HistoryIconProps> = ({
	width = 24,
	stroke = colors.feldgrau['60'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill={stroke}
			d="M12 2a10 10 0 00-6.88 2.77V3a1 1 0 00-2 0v4.5a1 1 0 001 1h4.5a1 1 0 000-2h-2.4A8 8 0 114 12a1 1 0 10-2 0A10 10 0 1012 2zm0 6a1 1 0 00-1 1v3a1 1 0 001 1h2a1 1 0 000-2h-1V9a1 1 0 00-1-1z"
		></path>
	</svg>
)
