import { configSlice } from './reducer'
import * as thunks from './thunks'

export { selectors } from './selectors'
export * from './types'
export const actions = {
	...configSlice.actions,
	...thunks,
}
export const reducer = configSlice.reducer
