export function invariant(condition: boolean, message: string): asserts condition {
	if (!condition) {
		throw new Error(message)
	}
}

export function invariantDefined<T>(value: T | undefined, message: string): T {
	if (value == null) {
		throw new Error(message)
	}
	return value
}
