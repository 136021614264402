import React, { ComponentType, MouseEvent } from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import PulseLoader from 'react-spinners/PulseLoader'

import { AnalyticsEvent, analytics } from 'src/biz'
import { colors } from 'src/theme/colors'
import { RouteId } from 'src/constants/routes'

type ButtonProps = MuiButtonProps & {
	isLoading?: boolean
	analytics?: {
		eventName: string
		eventParameters?: Record<string, any>
		routeId: RouteId
	}
}

export const Button: ComponentType<ButtonProps> = ({ children, isLoading, disabled, ...props }) => {
	const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		if (!props.onClick) {
			return
		}
		props.onClick(event)
		if (props.analytics) {
			analytics.track({
				eventName: AnalyticsEvent.buttonClicked,
				properties: {
					name: props.analytics.eventName,
					screen: props.analytics.routeId,
					...(props.analytics.eventParameters ?? {}),
				},
			})
		}
	}
	return (
		<MuiButton {...props} disabled={isLoading || disabled} onClick={handleOnClick}>
			{isLoading ? (
				<PulseLoader
					size={6}
					color={!props.variant || props.variant === 'contained' ? '#fff' : colors.feldgrau['100']}
				/>
			) : (
				children
			)}
		</MuiButton>
	)
}
