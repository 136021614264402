import { TableTypes } from '@cango-app/types'
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox } from '../checkbox'
import { Select } from '../select'

const getTableActionLabel = (key: TableTypes.ActionFunction) => {
	switch (key) {
		case TableTypes.ActionFunction.ADD_ROW_BELOW:
			return 'Add row'
		case TableTypes.ActionFunction.DUPLICATE_ROW:
			return 'Duplicate'
		case TableTypes.ActionFunction.DELETE_ROW:
			return 'Delete'
		default:
			return key
	}
}

const tableActions = Object.values(TableTypes.ActionFunction).reduce(
	(
		_acc: {
			_id: string
			label: string
		}[],
		value,
	) => {
		if (
			[TableTypes.ActionFunction.ADD_ROW_BELOW, TableTypes.ActionFunction.DUPLICATE_ROW].includes(
				value,
			)
		) {
			_acc.push({ _id: value, label: getTableActionLabel(value) })
		}
		return _acc
	},
	[],
)

export const DisplayToggle = () => {
	const { control, watch } = useFormContext<TableTypes.TableView>()
	const displayMode = watch('displayMode')
	const isPresentationMode = displayMode === TableTypes.ViewStyleMode.presentation
	return (
		<Stack direction="column" spacing={1}>
			<Controller
				control={control}
				name="displayMode"
				render={({ field: { value, onChange } }) => (
					<ToggleButtonGroup value={value} onChange={onChange}>
						<ToggleButton value="editable">Editable</ToggleButton>
						<ToggleButton value="presentation">Presentation</ToggleButton>
					</ToggleButtonGroup>
				)}
			/>
			<Stack direction="row" spacing={2}>
				<Controller
					control={control}
					name="withVerticalTableBorder"
					render={({ field: { value, onChange } }) => (
						<Checkbox
							label="Show vertical borders"
							checked={value}
							onChange={(_, checked) => onChange(checked)}
						/>
					)}
				/>
				{isPresentationMode && (
					<Controller
						control={control}
						name="withHorizontalTableBorder"
						render={({ field: { value, onChange } }) => (
							<Checkbox
								label="Show horizontal borders"
								checked={value}
								onChange={(_, checked) => onChange(checked)}
							/>
						)}
					/>
				)}
			</Stack>
			<Controller
				control={control}
				name="actions"
				render={({ field: { value, onChange } }) => (
					<Select
						label="Permitted table actions"
						multiple
						options={tableActions}
						value={value}
						onChange={onChange}
						containerProps={{ width: 300 }}
						fullWidth
					/>
				)}
			/>
		</Stack>
	)
}
