import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _uniqBy from 'lodash/uniqBy'
import { format } from 'date-fns'
import { FilesSdk } from '@cango-app/sdk'

import { FilesState, ProjectFolderState, StagedFileData } from './types'
import * as thunks from './thunks'

export const getInitialProjectState = (
	parentFolderName = `New Project - ${format(new Date(), 'yyyy-MM-dd - HH:mm')}`,
): ProjectFolderState => ({
	files: [],
	stagedFiles: [],
	parentFolderName,
	loading: false,
})
export const initialState: FilesState = {}

export const filesSlice = createSlice({
	name: 'files',
	initialState,
	reducers: {
		setAddNewFilesToProjectFiles: (
			state,
			action: PayloadAction<{ id: string; files: FilesSdk.File[]; parentFolderName?: string }>,
		) => {
			const { id, files, parentFolderName } = action.payload
			if (!state[id]) {
				state[id] = getInitialProjectState(parentFolderName)
			}
			state[id].files = _uniqBy([...state[id].files, ...files], 'id')
		},
		removeProjectFilesReference: (state, action: PayloadAction<string>) => {
			delete state[action.payload]
		},
		setStagedFiles: (
			state,
			action: PayloadAction<{
				id: string
				stagedFiles: StagedFileData[]
				parentFolderName?: string
			}>,
		) => {
			const { id, stagedFiles } = action.payload
			state[id].stagedFiles = stagedFiles
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(thunks.addNewFilesToCollection.fulfilled, (state, action) => {
				const { id, files } = action.payload
				state[id].files = _uniqBy([...state[id].files, ...files], 'id')
			})
			.addCase(thunks.fetchFiles.pending, (state, action) => {
				const { parentFolderId, parentFolderName } = action.meta.arg
				if (!state[parentFolderId]) {
					state[parentFolderId] = getInitialProjectState(parentFolderName)
				}
				state[parentFolderId].loading = true
			})
			.addCase(thunks.fetchFiles.fulfilled, (state, action) => {
				const { id, files } = action.payload
				state[id].files = _uniqBy(files, 'id')
				state[id].loading = false
			})
			.addCase(thunks.fetchFiles.rejected, (state, action) => {
				const { parentFolderId } = action.meta.arg
				state[parentFolderId].loading = false
			})
			.addCase(thunks.terminateWorkerPool.fulfilled, (state, action) => {
				const { id } = action.payload
				if (state[id]) {
					state[id].stagedFiles = []
				}
			}),
})
