import { ComponentType, useContext, useMemo } from 'react'
import { TableTypes } from '@cango-app/types'
import { Control, useWatch } from 'react-hook-form'

import { TableContext } from '../../../../providers/table-provider'
import { Chip } from '../../../../components'

import { allOperators } from './utils'
import { ConfigureCalculationForm } from './types'

export const Slice: ComponentType<{
	sliceIndex: number
	onClick?: () => void
	onDelete: () => void
	control: Control<ConfigureCalculationForm>
}> = ({ sliceIndex, onClick, control, onDelete }) => {
	const slice = useWatch({ control, name: `calculation.${sliceIndex}` })
	const { mappedColumns, table, tableList } = useContext(TableContext)

	const label = useMemo(() => {
		switch (slice.type) {
			case TableTypes.FormulaSliceType.FIELD:
				return mappedColumns.get(slice.value)?.name
			case TableTypes.FormulaSliceType.OPERATOR:
				return allOperators.find(({ _id }) => _id === slice.value)?.label
			case TableTypes.FormulaSliceType.NUMBER:
				return slice.value
			case TableTypes.FormulaSliceType.LOOKUP:
				return `Lookup from ${
					tableList.find(({ _id }) => _id === slice.lookup?.tableId)?.label ?? 'TBC'
				}`
			case TableTypes.FormulaSliceType.VLOOKUP:
				return `Lookup: ${mappedColumns.get(slice.vlookup?.sourceCompareFieldId ?? '')?.name ?? 'TBC'} --> Table: ${tableList.find(({ _id }) => _id === slice.vlookup?.targetTableId)?.label ?? 'TBC'}`
			case TableTypes.FormulaSliceType.REFERENCE_FIELD: {
				if (!slice.reference_column) {
					return ''
				}
				const mainColumn = mappedColumns.get(slice.reference_column)
				if (!mainColumn) {
					return ''
				}

				if (slice.value === TableTypes.QUESTIONAIRE_REFERENCE_ANSWER_PLACEHOLDER) {
					return `${mainColumn.name}.Answer`
				}

				if (!table?.referenceColumnNames[slice.reference_column]) {
					return ''
				}

				const referencedColumn = table?.referenceColumnNames[slice.reference_column].find(
					(_refCol) => _refCol._id === slice.value,
				)?.label
				return `${mainColumn.name}.${referencedColumn}`
			}
			default:
				return ''
		}
	}, [slice])

	return (
		<Chip
			key={sliceIndex}
			sx={{ mr: 1 }}
			label={label}
			onClick={onClick}
			onDelete={onDelete}
			variant="filled"
			color="primary"
		/>
	)
}
