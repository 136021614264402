import { CommsSdk } from '@cango-app/sdk'
import { ClientTypes } from '@cango-app/types'

export enum MessageStatus {
	IsSending = 'isSending',
	Sent = 'sent',
	Failed = 'failed',
}

export type NoteWithMessageStatus = ClientTypes.Note & { messageStatus: MessageStatus }

export interface NotesState {
	inboxNavState: CommsSdk.InboxNavState | string
	isLoadingInbox: boolean
	isLoadingTaskNotes: boolean
	inboxNotes: CommsSdk.InboxTaskListItem[]
	tasksWithUnreadNotes: CommsSdk.FetchUnreadTasksResponse
	taskNotes: NoteWithMessageStatus[]
	selectedInboxTaskId?: string
}
