import { FilesSdk, UserSdk } from '@cango-app/sdk'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import {
	StagedFileData,
	actions as persistedFilesActions,
	selectors as persistedFilesSelectors,
	StagedFileWithBinaryData,
} from 'src/store/modules/persisted-files'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as userSelectors } from 'src/store/modules/user'
import {
	initWorkerPool,
	triggerUploadInWorkerPool,
	terminateWorkerPool,
} from 'src/store/modules/persisted-files/thunks'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { FILES_STORAGE } from 'src/store/modules/persisted-files/files-storage'
import { RootState } from 'src/store/types'

interface FileUploadHookProps {
	parentFolderName: string | undefined
	parentFolderId: string | undefined
	defaultFilesToUpload?: StagedFileWithBinaryData[]
}

export const useFileUpload = ({
	parentFolderName,
	parentFolderId,
	defaultFilesToUpload = [],
}: FileUploadHookProps) => {
	const dispatch = useDispatch()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const [googleAccessToken, setGoogleAccessToken] = useState<string | undefined>()
	const [fetchingDrivePermission, setFetchingDrivePermission] = useState(true)
	const [shouldRequestDrivePermission, setShouldRequestDrivePermission] = useState(false)
	const stagedFiles = useSelector((state: RootState) =>
		persistedFilesSelectors.getStagedFilesWithBinaryData(state, parentFolderId as string),
	)
	const areFilesUploading = useSelector((state: RootState) =>
		persistedFilesSelectors.areFilesUploading(state, parentFolderId as string),
	)
	const haveAllFilesUploaded = useSelector((state: RootState) =>
		persistedFilesSelectors.haveAllFilesUploaded(state, parentFolderId as string),
	)
	const hasUploadError = useSelector((state: RootState) =>
		persistedFilesSelectors.hasUploadError(state, parentFolderId as string),
	)

	const getDriveToken = useCallback(
		() =>
			UserSdk.getUserAccessToken(import.meta.env.VITE_API as string, authHeaders, {
				userId,
			}),
		[authHeaders, userId],
	)

	const initDriveUpload = useCallback(async () => {
		try {
			const tokenResponse = await getDriveToken()
			if (tokenResponse.tokenError) {
				setShouldRequestDrivePermission(true)
				return
			}
			setGoogleAccessToken(tokenResponse.token)
		} catch (error) {
			setShouldRequestDrivePermission(true)
			Sentry.captureException(error)
		} finally {
			setFetchingDrivePermission(false)
		}
	}, [])

	const handleSaveUserDriveToken = useCallback(
		async (authCode: string) => {
			try {
				const { token } = await UserSdk.updateUserToken(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						code: authCode,
					},
				)
				setGoogleAccessToken(token)
				setShouldRequestDrivePermission(false)
			} catch (error) {
				showSnackbar('Error saving drive token', { variant: 'error' })
			}
		},
		[userId],
	)

	const handleStageFiles = (fileList: FileList | null) => {
		if (!fileList || !fileList.length) {
			return
		}

		const stagedFiles: StagedFileData[] = []
		Array.from(fileList).forEach((file, i) => {
			FILES_STORAGE.set(parentFolderId as string, i, file)
			stagedFiles.push({
				fileModifiedAt: new Date().getTime(),
				uploadProgress: 0,
				state: FilesSdk.FileUploadState.Pending,
			})
		})
		dispatch(
			persistedFilesActions.setStagedFiles({
				id: parentFolderId as string,
				stagedFiles,
				parentFolderName,
			}),
		)
	}

	const handleChangeFileName = (fileIndex: number, newName: string) => {
		const file = FILES_STORAGE.get(parentFolderId as string, fileIndex)
		const blob = file.slice(0, file.size, file.type)
		const newFile = new File([blob], newName, file)
		FILES_STORAGE.set(parentFolderId as string, fileIndex, newFile)
	}

	const handleRemoveStagedFile = (fileIndex: number) => {
		FILES_STORAGE.remove(parentFolderId as string, fileIndex)
	}

	useEffect(() => {
		if (defaultFilesToUpload.length) {
			const stagedFiles: StagedFileData[] = []
			defaultFilesToUpload?.forEach(({ file, ...stagedFile }, i) => {
				FILES_STORAGE.set(parentFolderId as string, i, file)
				stagedFiles.push(stagedFile)
			})
			dispatch(
				persistedFilesActions.setStagedFiles({
					id: parentFolderId as string,
					stagedFiles,
					parentFolderName,
				}),
			)
		}
	}, [])

	useEffect(() => {
		if (parentFolderId) {
			dispatch(initWorkerPool({ parentFolderId }))
			initDriveUpload().catch((error) => {
				Sentry.captureException(error)
			})
		}
	}, [])

	const handleUploadFiles = useCallback(() => {
		if (!googleAccessToken || !parentFolderId) return

		dispatch(
			triggerUploadInWorkerPool({
				parentFolderId,
				googleAccessToken,
				parentFolderName,
			}),
		)
	}, [googleAccessToken, parentFolderId])

	const terminateUpload = useCallback(
		(force?: boolean) => {
			dispatch(
				terminateWorkerPool({
					force,
					parentFolderId: parentFolderId as string,
					parentFolderName: parentFolderName as string,
				}),
			)
		},
		[parentFolderId],
	)

	const clearStagedFiles = useCallback(() => {
		dispatch(
			persistedFilesActions.setStagedFiles({
				id: parentFolderId as string,
				stagedFiles: [],
				parentFolderName,
			}),
		)
	}, [parentFolderId])

	return {
		//variables
		stagedFiles,
		areFilesUploading,
		isUploadingComplete: haveAllFilesUploaded,
		hasUploadError,
		shouldRequestDrivePermission,
		fetchingDrivePermission,

		// methods
		terminateUpload,
		clearStagedFiles,
		onStageFiles: handleStageFiles,
		onChangeStagedFileName: handleChangeFileName,
		onUploadStagedFiles: handleUploadFiles,
		onRemoveStagedFile: handleRemoveStagedFile,
		onSaveAccessToken: handleSaveUserDriveToken,
	}
}
