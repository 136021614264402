import React, { ComponentType, useState, useCallback } from 'react'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import dayjs from 'dayjs'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { FilesSdk } from '@cango-app/sdk'

import { StagedFileWithBinaryData } from 'src/store/modules/persisted-files/types'

import { Button } from '../button'
import { Box } from '../box'
import { TextField } from '../text-field'
import { Text } from '../text'

interface UploadStateIconProps {
	fileData: StagedFileWithBinaryData
	onRemoveFile: () => void
}

const UploadStateIcon: ComponentType<UploadStateIconProps> = ({ fileData, onRemoveFile }) => {
	if (
		[FilesSdk.FileUploadState.Failed, FilesSdk.FileUploadState.Pending].includes(fileData.state)
	) {
		return (
			<IconButton color="error" onClick={onRemoveFile}>
				<DeleteOutlineIcon />
			</IconButton>
		)
	}
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress variant="determinate" value={fileData.uploadProgress} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Text variant="caption" color="text.secondary">{`${fileData.uploadProgress}%`}</Text>
			</Box>
		</Box>
	)
}

interface EditingNameControlsProps {
	handleChangeAccept: () => void
	handleCancelChange: () => void
}

const EditingNameControls: ComponentType<EditingNameControlsProps> = ({
	handleChangeAccept,
	handleCancelChange,
}) => (
	<Box display="flex">
		<IconButton color="success" sx={{ mr: 0.5 }} onClick={handleChangeAccept}>
			<DoneIcon />
		</IconButton>
		<IconButton color="error" onClick={handleCancelChange}>
			<ClearIcon />
		</IconButton>
	</Box>
)

interface DefaultControlsProps {
	fileData: StagedFileWithBinaryData
	onEdit: () => void
	onRemoveFile: () => void
}

const DefaultControls: ComponentType<DefaultControlsProps> = ({
	fileData,
	onEdit,
	onRemoveFile,
}) => (
	<Box display="flex" alignItems="center">
		{getUploadText(fileData) && (
			<Text fontSize={14} mr={2}>
				{getUploadText(fileData)}
			</Text>
		)}
		{!getUploadText(fileData) && (
			<Button onClick={onEdit} variant="text">
				Rename
			</Button>
		)}
		<UploadStateIcon fileData={fileData} onRemoveFile={onRemoveFile} />
	</Box>
)

const getUploadText = (fileData: StagedFileWithBinaryData) => {
	if (fileData.state === FilesSdk.FileUploadState.Failed) return 'Failed to upload'
	if (fileData.state === FilesSdk.FileUploadState.Complete) return 'Uploaded'
	if (fileData.state === FilesSdk.FileUploadState.Uploading) return 'Uploading to Google Drive'
	return null
}

interface StagedFileProps {
	fileData: StagedFileWithBinaryData
	onChangeName: (newName: string) => void
	onRemoveFile: () => void
}

export const StagedFile: ComponentType<StagedFileProps> = ({
	fileData,
	onChangeName,
	onRemoveFile,
}) => {
	const [isEditingName, setIsEditingName] = useState(false)
	const [fileName, setFileName] = useState(fileData.file.name)

	const handleChangeAccept = useCallback(() => {
		onChangeName(fileName)
		setIsEditingName(false)
	}, [fileName, onChangeName])

	const handleCancelChange = useCallback(() => {
		setIsEditingName(false)
		setFileName(fileData.file.name)
	}, [fileData.file.name])

	return (
		<Box display="flex">
			<ListItem>
				<ListItemAvatar>
					<FilePresentIcon />
				</ListItemAvatar>
				{isEditingName ? (
					<TextField
						value={fileName}
						onChange={(event) => setFileName(event.target.value)}
						label="File name"
					/>
				) : (
					<ListItemText
						primary={fileData.file.name}
						secondary={`Last updated: ${dayjs(fileData.file.lastModified).format(
							'MMM DD, YYYY HH:mm',
						)}`}
					/>
				)}
			</ListItem>
			<Box display="flex" alignItems="center">
				{isEditingName ? (
					<EditingNameControls
						handleChangeAccept={handleChangeAccept}
						handleCancelChange={handleCancelChange}
					/>
				) : (
					<DefaultControls
						fileData={fileData}
						onEdit={() => setIsEditingName(true)}
						onRemoveFile={onRemoveFile}
					/>
				)}
			</Box>
		</Box>
	)
}
