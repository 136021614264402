import React, { ComponentType } from 'react'
import { GridCellParams } from '@mui/x-data-grid-premium'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Button } from '../../../../components'

export const CellUrlLink: ComponentType<{ cell: GridCellParams; onEditClick: () => void }> = ({
	cell,
	onEditClick,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!cell.value) {
			onEditClick()
			return
		}
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const openLink = () => {
		if (!cell.value) return
		window.open(cell.value as string, '_blank')
		handleClose()
	}

	return (
		<>
			<Button
				variant="text"
				size="small"
				aria-describedby={cell.row.id}
				sx={{
					textDecoration: 'underline',
					cursor: 'pointer',
					textAlign: 'right',
				}}
				onClick={(e) => {
					e.stopPropagation()
					handleClick(e)
				}}
			>
				{cell.value as string}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={onEditClick}>Edit</MenuItem>
				<MenuItem onClick={openLink}>Open</MenuItem>
			</Menu>
		</>
	)
}
