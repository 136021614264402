import { ComponentType, useMemo } from 'react'
import _capitalize from 'lodash/capitalize'

import { Box } from '../../../components'
import { colors } from '../../../theme/colors'

export const numberToLetter = (num: number): string | null => {
	if (num < 1 || num > 26) return null
	return _capitalize(String.fromCharCode(64 + num))
}

export const ListLetter: ComponentType<{ alphabetIndex: number }> = ({ alphabetIndex }) => {
	const letter = useMemo(() => {
		return numberToLetter(alphabetIndex)
	}, [alphabetIndex])

	return (
		<Box
			p={1}
			border="1px solid"
			borderColor={colors.feldgrau['60']}
			borderRadius={1}
			width="16px"
			height="16px"
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			{letter}
		</Box>
	)
}
