import { TableTypes } from '@cango-app/types'
import _isNumber from 'lodash/isNumber'
import { GridValidRowModel } from '@mui/x-data-grid-premium'

import { showSnackbar } from 'src/helpers/snackbarManager'

export type TreeData = {
	_id: string
	label: string
	records: TableTypes.Field[]
	aggregates: Record<string, string | number>
	children?: TreeData[] | GridValidRowModel
}

export const TABLE_TYPES_WITH_VALUE_OPTIONS = [
	TableTypes.FieldType.TABLE_SELECT,
	TableTypes.FieldType.SINGLE_SELECT,
	TableTypes.FieldType.REFERENCE,
	TableTypes.FieldType.ROLE,
	TableTypes.FieldType.CONTACT,
]

export const generateTreeData = (
	rows: GridValidRowModel[],
	columns: TableTypes.Field[],
	grouping: TableTypes.TableView['groupedFields'],
): TreeData[] => {
	const calculateAggregates = (
		rows: GridValidRowModel[],
		aggregationModel: Record<string, TableTypes.AggregationFormulas>,
	): Record<string, any> => {
		const aggregates: Record<string, string | number> = {}
		Object.entries(aggregationModel ?? {}).forEach(([columnId, aggregationType]) => {
			const values = rows.map((row) => row[columnId]).filter((value) => value !== undefined)
			switch (aggregationType) {
				case TableTypes.AggregationFormulaNumbers['sum']:
					aggregates[columnId] = values.reduce((acc, value) => acc + value, 0)
					break
				case TableTypes.AggregationFormulaNumbers['avg']:
					aggregates[columnId] =
						values.length > 0 ? values.reduce((acc, value) => acc + value, 0) / values.length : 0
					break
				case TableTypes.AggregationFormulaNumbers['size']:
					aggregates[columnId] = values.length
					break
				case TableTypes.AggregationFormulaNumbers['max']:
					aggregates[columnId] = values.reduce(
						(acc, value) => (value >= acc ? value : acc),
						-Infinity,
					)
					break
				case TableTypes.AggregationFormulaNumbers['min']:
					aggregates[columnId] = values.reduce(
						(acc, value) => (value <= acc ? value : acc),
						Infinity,
					)
					break
				case TableTypes.AggregationFormulaStrings['concatenate']:
					aggregates[columnId] = values.reduce((acc, value) => `${acc} ${value}`, '')
					break
				case TableTypes.AggregationSpecialCase['show']:
					aggregates[columnId] = ''
					break
				default:
					break
			}
		})

		return aggregates
	}

	const buildChildren = (
		groupedRows: Record<string, any>[],
		currentGroup: number,
		accumulatedId: string,
	): TreeData[] | Record<string, any>[] => {
		if (currentGroup >= grouping.length) {
			return groupedRows.map((row) => {
				const lastestVisibilityModel = grouping[currentGroup - 1]?.visibilityModel
				return Object.entries(row).reduce(
					(row, [rowKey, rowValue]) => {
						if (rowKey === '_id' || lastestVisibilityModel[rowKey]) {
							row[rowKey] = rowValue
						}
						return row
					},
					{} as Record<string, any>,
				)
			})
		}

		const groupColumnId = grouping[currentGroup].groupColumnId
		const visibilityModel = grouping[currentGroup].visibilityModel
		const aggregationModel = grouping[currentGroup].aggregationModel
		const grouped = groupedRows.reduce(
			(acc, row) => {
				const groupValue = row[groupColumnId] ?? false
				if (!acc[groupValue]) {
					acc[groupValue] = []
				}
				acc[groupValue].push(row)
				return acc
			},
			{} as Record<string, Record<string, any>[]>,
		)

		return Object.entries(grouped).map(([groupValue, row]) => {
			const sanitizedGroupValue = groupValue.replace(/[^a-zA-Z0-9]/g, '_') // Replaces non-alphanumeric characters with underscores
			const newGroupingId = `${groupColumnId}/${sanitizedGroupValue}`
			const newAccumulatedgroupId = accumulatedId
				? `${accumulatedId}-${newGroupingId}`
				: `${newGroupingId}`

			const children = buildChildren(row, currentGroup + 1, newAccumulatedgroupId)
			const aggregates = calculateAggregates(row, aggregationModel)

			return {
				_id: newAccumulatedgroupId,
				label: groupValue,
				records: columns.filter(({ _id }) => visibilityModel[_id]),
				children,
				aggregates,
			}
		})
	}

	return buildChildren(rows, 0, '') as TreeData[]
}

export const getFormatedTable = (data: TreeData): { tables: string[]; title: string[][] } => {
	const title: string[][] = []
	const tables: string[] = []
	const getTableGroup = (
		node: TreeData | Record<string, any>,
		level: number,
	): { tables: string[]; title: any } => {
		if (node.children) {
			title[level] ? title[level].push(node.label) : (title[level] = [node.label])
			if (!node.children[0]?.children) {
				const table = `<table>
					<thead>
						${node.records
							.map((col: TableTypes.Field) => {
								return `<th>${col.name}</th>`
							})
							.join('')}
					</thead>
					        <tbody>
          ${node.children
						.map(
							(row: GridValidRowModel) => `
            <tr>
              ${node.records
								.map((col: TableTypes.Field) => {
									return `<td>${(_isNumber(row[col._id]) ? row[col._id].toFixed(2) : row[col._id]) ?? ''}</td>`
								})
								.join('')}
            </tr>
          `,
						)
						.join('')}
        </tbody>
				</table>`
				tables.push(table)
			}
			node.children.forEach((group: TreeData) => {
				getTableGroup(group, level + 1)
			})
		}
		return {
			title: combineTitles(title),
			tables,
		}
	}
	return getTableGroup(data, 0)
}

const combineTitles = (titlesArray: string[][], separator = ' - '): string[] => {
	// get an array of titles like this [['title parent'], ['title 1 children', 'title 2 children']]
	// make them look like ['title parent - title 1 children', 'title parent - title 2 children']
	const combineTitleByLevel = (currentLevel: number, fullTitle: string[]): string[] => {
		if (currentLevel === titlesArray.length) {
			return [fullTitle.join(separator)]
		}

		const combinations: string[] = []
		for (const title of titlesArray[currentLevel]) {
			combinations.push(...combineTitleByLevel(currentLevel + 1, [...fullTitle, title]))
		}

		return combinations
	}

	return combineTitleByLevel(0, [])
}

const LINE_SEPARATOR = `<br/><br/>`

const onSuccessCopiedTable = () => showSnackbar('Table copied!', { variant: 'success' })
const onFailedCopiedTable = () => showSnackbar('Failed to copy table', { variant: 'error' })

export const onCopyGroup = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, node: TreeData) => {
	e.stopPropagation()
	const { title, tables } = getFormatedTable(node)

	const formattedTables = tables
		.map((table, index) => {
			return `<h2>${title[index]}</h2>
    ${table}${LINE_SEPARATOR}
    `
		})
		.join('')
	const blob = new Blob([formattedTables], { type: 'text/html' })
	const clipboardItem = new ClipboardItem({ 'text/html': blob })
	navigator.clipboard.write([clipboardItem]).then(onSuccessCopiedTable).catch(onFailedCopiedTable)
}

export const onCopyAllGroups = (node: TreeData[]) => {
	const fullFormattedTables = node
		.map((nodeChild) => {
			const { title, tables } = getFormatedTable(nodeChild)
			return tables
				.map((table, index) => {
					return `<h2>${title[index]}</h2>
    ${table}${LINE_SEPARATOR}
    `
				})
				.join('')
		})
		.join(LINE_SEPARATOR)

	const blob = new Blob([fullFormattedTables], { type: 'text/html' })
	const clipboardItem = new ClipboardItem({ 'text/html': blob })
	navigator.clipboard.write([clipboardItem]).then(onSuccessCopiedTable).catch(onFailedCopiedTable)
}
