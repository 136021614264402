import { useState, useRef, useCallback } from 'react'

const usePolling = (pollFn: () => void, intervalMs: number): [boolean, () => void, () => void] => {
	const fetchInterval = useRef<ReturnType<typeof setInterval> | null>(null)
	const [isPolling, setIsPolling] = useState(false)

	const start = useCallback(() => {
		if (fetchInterval.current) {
			clearInterval(fetchInterval.current)
		}

		fetchInterval.current = setInterval(pollFn, intervalMs)
		setIsPolling(true)
	}, [pollFn, intervalMs])
	const stop = useCallback(() => {
		if (!fetchInterval.current) {
			return
		}

		clearInterval(fetchInterval.current)
		fetchInterval.current = null
		setIsPolling(false)
	}, [])

	return [isPolling, start, stop]
}

export default usePolling
