import type { ComponentType } from 'react'
import _escapeRegExp from 'lodash/escapeRegExp'

const getAllTagMatches = (raw: string): [string, string, string][] => {
	const regexPattern = /@\[(.*?)\]\((.*?)\)/gi
	const matches = Array.from(raw.matchAll(regexPattern))
	return matches.map((match) => [match[0], match[1], match[2]])
}

export const MentionedText: ComponentType<{ raw: string }> = ({ raw }) => {
	if (!raw) return null

	const tags = getAllTagMatches(raw)
	const tagsPattern = new RegExp(
		`(${tags.map(([wholeTag]) => _escapeRegExp(wholeTag)).join('|')})`,
		'gi',
	)

	const displayText = raw.split(tagsPattern).map((part, i) => {
		const tag = tags.find(([wholeTag]) => wholeTag.toLowerCase() === part.toLowerCase())
		return tag ? <b key={`${part}.${i}`}>{`@${tag[1]}`}</b> : part
	})

	return <span>{displayText}</span>
}

export const mentionedPlainText = (raw?: string): string => {
	if (!raw) return ''

	const tags = getAllTagMatches(raw)
	return tags.reduce((text, [wholeTag, displayValue]) => {
		const pattern = new RegExp(_escapeRegExp(wholeTag), 'gi')
		return text.replace(pattern, `@${displayValue}`)
	}, raw)
}
