import React, { ComponentType, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Backdrop from '@mui/material/Backdrop'
import { useEffectOnce } from 'usehooks-ts'
import { off, onValue, ref } from 'firebase/database'

import { firebaseDatabase } from 'src/helpers/firebase'

import { AsyncDispatchType } from '../store/types'
import {
	selectors as remoteConfigSelectors,
	actions as remoteConfigActions,
} from '../store/modules/remote-config'
import {
	selectors as configSelectors,
	actions as configActions,
	CangoSchemaState,
} from '../store/modules/config'

import { Box } from './box'
import { Text } from './text'
import { Button } from './button'

export const ForceUpdateOverlay: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const shouldUpdateApp = useSelector(remoteConfigSelectors.shouldUpdateApp)
	const schemaState = useSelector(configSelectors.getCangoSchemaState)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const isMigrationInProgress = schemaState === CangoSchemaState.MigrationInProgress
	const hasMigrationError = schemaState === CangoSchemaState.Failed

	const shouldShowSchemaOverlay = useMemo(() => {
		return (
			schemaState === CangoSchemaState.RequiresMigration ||
			schemaState === CangoSchemaState.Loading ||
			schemaState === CangoSchemaState.MigrationInProgress ||
			schemaState === CangoSchemaState.Failed
		)
	}, [schemaState])

	const handleRefreshClick = async () => {
		if (shouldShowSchemaOverlay && organisationId) {
			const response = await dispatch(configActions.updateSchema(organisationId))
			if (response.meta.requestStatus === 'rejected') return
		}
		window.location.reload()
	}

	useEffectOnce(() => {
		const firebaseRef = ref(
			firebaseDatabase,
			`${import.meta.env.VITE_ENVIRONMENT}/minimumAppVersion`,
		)

		onValue(firebaseRef, (snapshot) => {
			const typedData = snapshot.val()
			dispatch(remoteConfigActions.setMinimumAppVersion(typedData))
		})

		return () => {
			off(firebaseRef, 'value')
		}
	})

	const [heading, infoText, buttonText] = useMemo(() => {
		if (isMigrationInProgress) {
			return [
				'Update in progress',
				'Please wait while we update your app. This may take a few minutes.',
				'Check if completed',
			]
		}

		if (hasMigrationError) {
			return [
				'Update failed',
				'Looks like there was an error updating your app. Please contact support.',
				undefined,
			]
		}

		return [
			'New update!',
			"Looks like you're using an old version of the app. Click below to get the latest updates.",
			'Update now',
		]
	}, [schemaState])

	return (
		<Backdrop
			open={shouldShowSchemaOverlay || shouldUpdateApp}
			sx={{ zIndex: ({ zIndex }) => zIndex.drawer + 1 }}>
			<Box bgcolor="white" maxWidth={400} borderRadius="10px" p={2} textAlign="center">
				<Text
					fontWeight={600}
					color={isMigrationInProgress || hasMigrationError ? 'red' : 'inherit'}>
					{heading}
				</Text>
				<Text>{infoText}</Text>
				{!hasMigrationError && (
					<Button
						variant="contained"
						sx={{ mt: 2 }}
						onClick={handleRefreshClick}
						isLoading={schemaState === CangoSchemaState.Loading}
						disabled={schemaState === CangoSchemaState.Loading}>
						{buttonText}
					</Button>
				)}
			</Box>
		</Backdrop>
	)
}
