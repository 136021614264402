import { SelectChangeEvent, Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { TablesSdk } from '@cango-app/sdk'
import { useSelector } from 'react-redux'

import { Modal } from '../modal'
import { Box } from '../box'
import { Text } from '../text'
import { Button } from '../button'
import { Select } from '../select'
import { selectors as authSelectors } from '../../store/modules/auth'

type Props = {
	openModal: boolean
	isLoading: boolean
	onClose: () => void
	onSubmit: () => void
	selectedDB: string
	setSelectedDB: (event: SelectChangeEvent<unknown>) => void
}

export const LinkingDBModal = ({
	onClose,
	onSubmit,
	openModal,
	isLoading,
	selectedDB,
	setSelectedDB,
}: Props) => {
	const [tableList, setTableList] = useState<{ _id: string; label: string }[]>([])
	const [isFetchingTableList, setIsFetchingTableList] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const fetchTableList = useCallback(async () => {
		try {
			setIsFetchingTableList(true)
			const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, authHeaders)
			const formattedTableList = response.map((_table) => ({
				_id: _table._id,
				label: _table.name,
			}))
			setTableList(formattedTableList)
			setIsFetchingTableList(false)
			return formattedTableList
		} catch (error) {
			setIsFetchingTableList(false)
			return []
		}
	}, [])

	useEffect(() => {
		if (!tableList.length) {
			fetchTableList()
		}
	}, [tableList])

	return (
		<Modal open={openModal} onClose={onClose} withCloseButton>
			<Stack direction="column" spacing={4} padding={2} width={500}>
				<Box>
					<Text variant="h4" marginBottom={1}>
						Choose a Database
					</Text>
					<Text>
						{`The selected blueprint does not have an associated database.
          You need to choose one in order to create a project from this blueprint.
          This action will link the database to the blueprint.
          You can always change it in the blueprint's settings.`}
					</Text>
				</Box>
				<Select
					value={selectedDB}
					options={tableList}
					isLoading={isFetchingTableList}
					onChange={setSelectedDB}
				/>
				<Button isLoading={isLoading} disabled={!selectedDB} onClick={onSubmit}>
					Select database and Create project
				</Button>
			</Stack>
		</Modal>
	)
}
