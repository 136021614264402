import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
	DataGridPremium,
	GridToolbarContainer,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-premium'
import { TableTypes } from '@cango-app/types'

import { Box } from '../box'
import { TableContext } from '../../providers/table-provider'
import { useColumnFormatter } from '../../modules/tables/use-column-formatter'

const CustomToolbar: ComponentType<{
	setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
}> = ({ setFilterButtonEl }) => {
	return (
		<GridToolbarContainer>
			<GridToolbarFilterButton ref={setFilterButtonEl} />
		</GridToolbarContainer>
	)
}

const slots = {
	toolbar: CustomToolbar,
}

export const ColumnsAndFilters: ComponentType<{ viewId: string | undefined }> = ({ viewId }) => {
	const { table, apiRef } = useContext(TableContext)
	const { control } = useFormContext<TableTypes.TableView>()
	const { columns } = useColumnFormatter({
		isTableLocked: true,
		apiRef,
		sortingModel: [],
		isBulkEditEnabled: false,
	})
	const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null)

	const selectedView = useMemo(() => {
		if (!table || !viewId) {
			return
		}

		return table.views.find((view) => view._id === viewId)
	}, [viewId, table?.views])

	if (viewId && !selectedView) {
		return null
	}

	return (
		<Box>
			<Box height={50} width={175}>
				<Controller
					control={control}
					name="filters"
					render={({ field: { onChange } }) => {
						return (
							<Box width={190}>
								<DataGridPremium
									rows={[]}
									columns={columns}
									hideFooter
									sx={{
										'& .MuiDataGrid-main': {
											display: 'none',
										},
										'& .MuiDataGrid-root': {
											border: 'none !important',
										},
										'& .MuiDataGrid-toolbarContainer': {
											padding: 1,
										},
									}}
									disableColumnSelector
									disableDensitySelector
									onFilterModelChange={onChange}
									slots={slots}
									slotProps={{
										toolbar: {
											setFilterButtonEl,
										},
										panel: {
											anchorEl: filterButtonEl,
										},
									}}
								/>
							</Box>
						)
					}}
				/>
			</Box>
		</Box>
	)
}
