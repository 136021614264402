import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { V3ProjectSdk, V3BlueprintSdk, FilesSdk, MyTasksV3Sdk } from '@cango-app/sdk'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { NavigateFunction } from 'react-router-dom'
import { GridFilterModel } from '@mui/x-data-grid-premium'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { getNextEligibleSection, getNextEligibleTask } from 'src/helpers/chains'

import type { RootState, AsyncDispatchType } from '../../types'
import { selectors as authSelectors } from '../auth'
import { selectors as userSelectors } from '../user'
import { selectors as configSelectors } from '../config'
import {
	actions as persistedConfigActions,
	selectors as persistedConfigSelectors,
} from '../persisted-config'
import { errorHandler } from '../../../helpers/api'

import { selectors as projectSelectors } from './selectors'
import { CompleteTaskResponse, ProjectsStateV3 } from './types'

export const createNewTask = createAsyncThunk<
	{ task: V3ClientTypes.Project.Task; projectId: string },
	V3ProjectSdk.CreateTaskRequest,
	{ rejectValue: void; dispatch: Dispatch; state: RootState }
>('projects-v3/create-new-task', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.createTask(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return { projectId: data.project_id, task: response }
	} catch (error) {
		if ((error as any).response.data.message === 'duplicated_key') {
			showSnackbar('Oops! Please try saving again...', { variant: 'error' })
		}
		errorHandler({ error, dispatch })
		return rejectWithValue()
	}
})

export const deleteTask = createAsyncThunk<
	{ taskId: string; projectId: string },
	{ taskId: string; projectId: string },
	{ rejectValue: string; dispatch: AsyncDispatchType }
>('projects-v3/deleteTask', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3ProjectSdk.deleteTask(import.meta.env.VITE_API as string, headers, data)

		return { ...data }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getProjectNames = createAsyncThunk<
	V3ProjectSdk.GetProjectNamesResponse,
	undefined,
	{ state: RootState; rejectValue: string; dispatch: AsyncDispatchType }
>('projects-v3/project-names', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		return await V3ProjectSdk.getProjectNames(import.meta.env.VITE_API as string, headers)
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getCards = createAsyncThunk<
	V3BlueprintSdk.Card[],
	void,
	{ state: RootState; rejectValue: string; dispatch: AsyncDispatchType }
>('projects-v3/getProjectCards', async (ids, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const projectCards = await V3ProjectSdk.getCards(import.meta.env.VITE_API as string, headers)

		const projectIds = projectCards.map((project) => project._id)
		dispatch(persistedConfigActions.cleanRecentlyViewedProjects({ projectIds, organisationId }))

		return projectCards
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getProjectTasks = createAsyncThunk<
	V3ProjectSdk.GetTasksResponse,
	{ projectId: string },
	{ rejectValue: any; dispatch: AsyncDispatchType; state: RootState }
>(
	'projects-v3/get-project-tasks',
	async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await V3ProjectSdk.getTasks({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				projectId,
			})

			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const createProject = createAsyncThunk<
	{ projectId: string; error?: string },
	V3ProjectSdk.CreateProjectRequest,
	{
		rejectValue: { projectId?: string; error: string }
		dispatch: Dispatch
	}
>('projects-v3/create-project', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.createProject(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return { projectId: response._id }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue({
			projectId: undefined,
			error: (error as Error).message,
		})
	}
})

export const getProject = createAsyncThunk<
	V3ProjectSdk.GetProjectResponse,
	{ projectId: string },
	{ rejectValue: any; dispatch: AsyncDispatchType }
>('projects-v3/getById', async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const response = await V3ProjectSdk.getProject({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			projectId,
		})

		if (organisationId) {
			dispatch(
				persistedConfigActions.setRecentlyViewedProject({
					organisationId,
					project: {
						_id: response.project._id,
						name: response.project.name,
						blueprintId: response.project.blueprint_id,
					},
				}),
			)
		}

		dispatch(getProjectTasks({ projectId }))

		return response
	} catch (error) {
		if ((error as any).response.data.message === 'cannot_find_project') {
			return rejectWithValue('cannot_find_project')
		}
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateProject = createAsyncThunk<
	{ response: V3ProjectSdk.UpdateProjectResponse; organisationId?: string },
	V3ProjectSdk.UpdateProjectRequest & { projectId: string },
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/updateProject', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const response = await V3ProjectSdk.updateProject({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			data,
		})
		return { response, organisationId }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateTask = createAsyncThunk<
	V3ClientTypes.Project.Task,
	V3ProjectSdk.UpdateTaskRequest & { projectId: string },
	{ rejectValue: any; dispatch: Dispatch; state: RootState }
>('projects-v3/updateTask', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.updateTask({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			data,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const completeTask = createAsyncThunk<
	CompleteTaskResponse,
	V3ProjectSdk.CompleteTaskRequest & { projectId: string; taskId: string; sectionId?: string },
	{ rejectValue: any; dispatch: AsyncDispatchType; state: RootState }
>(
	'projects-v3/complete-task',
	async ({ projectId, taskId, ...data }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const mappedTasks = projectSelectors.getMappedProjectTasks(state)
			const userId = userSelectors.getCurrentUserId(state)
			const tasks = projectSelectors.getProjectTasks(state)
			const groupBy = persistedConfigSelectors.getProjectGroupingConfig(state, projectId)
			const response = await V3ProjectSdk.completeTask(
				import.meta.env.VITE_API as string,
				headers,
				projectId,
				taskId,
				data,
			)

			const completedTask = mappedTasks.get(taskId)
			if (
				completedTask?.actions.some((_action) =>
					[
						V3BlueprintTypes.ActionEnum.FileUpload,
						V3BlueprintTypes.ActionEnum.FileTemplate,
					].includes(_action.type),
				)
			) {
				dispatch(updateDependencies({ taskId: taskId, projectId: completedTask.project_id }))
			}

			const allTasks = [...tasks, ...response.tasks]

			const nextTask = getNextEligibleTask(taskId, allTasks, userId, groupBy)
			let nextSectionId: string | undefined

			if (!nextTask && data.sectionId) {
				const nextSection = getNextEligibleSection(data.sectionId, allTasks, userId, groupBy)
				nextSectionId = nextSection?._id
			}

			return {
				options: data.options ?? completedTask?.lifecycle?.completed_options ?? [],
				taskId: taskId,
				tasks: response.tasks,
				instances: response.instances,
				isProjectComplete: response.isProjectComplete,
				nextTaskId: nextTask?._id,
				nextSectionId,
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const completeOccurrence = createAsyncThunk<
	V3ProjectSdk.CompleteOccurrenceResponse,
	V3ProjectSdk.CompleteOccurrenceRequest,
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/complete-occurrence', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.completeOccurrence(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignTaskDate = createAsyncThunk<
	V3ProjectSdk.AssignTaskDateResponse & { projectId: string },
	V3ProjectSdk.AssignTaskDateRequest & { projectId: string },
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/assign-task-date', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3ProjectSdk.assignTaskDate(import.meta.env.VITE_API as string, headers, data)
		return data
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignUserToRole = createAsyncThunk<
	V3ProjectSdk.AssignUserRoleResponse,
	V3ProjectSdk.AssignUserRoleRequest,
	{ rejectValue: any; dispatch: Dispatch; state: RootState }
>('projects-v3/assign-user-role', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.assignUserRole(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		showSnackbar('User assigned to Project Role')
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignContactToExternal = createAsyncThunk<
	V3ClientTypes.Project.Project['externals'],
	V3ProjectSdk.AssignContactsToProjectRequest,
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/assign-contact-external', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const updatedExternals = await V3ProjectSdk.assignContactsToProject(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return updatedExternals
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const flagTask = createAsyncThunk<
	V3ClientTypes.Project.Task[],
	MyTasksV3Sdk.FlatTaskRequest,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('my-project-v3/flag-task', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await MyTasksV3Sdk.flagTask(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const resolveFlaggedTask = createAsyncThunk<
	void,
	{ projectId: string; taskId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'my-project-v3/resolve-flagged-task',
	async ({ taskId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			await V3ProjectSdk.updateTask({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data: {
					taskId,
					update: {
						isFlagged: false,
					},
				},
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const deleteProject = createAsyncThunk<
	{ projectId: string; organisationId?: string },
	{ projectId: string; navigate: NavigateFunction },
	{ rejectValue: string; dispatch: Dispatch }
>(
	'projects-v3/delete',
	async ({ projectId, navigate }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const organisationId = configSelectors.getOrganisationId(state)
			await V3ProjectSdk.deleteProject(import.meta.env.VITE_API as string, headers, {
				projectId: projectId,
			})
			navigate(`/projects`, { replace: true })
			return {
				projectId: projectId,
				organisationId,
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const archive = createAsyncThunk<
	{ projectId: string; archivedAt: number },
	{ projectId: string },
	{ rejectValue: string; dispatch: Dispatch }
>('projects-v3/archive', async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.archive(import.meta.env.VITE_API as string, headers, {
			projectId,
		})
		return {
			projectId,
			archivedAt: response.archivedAt,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const restoreFromArchive = createAsyncThunk<
	void,
	{ projectId: string },
	{ rejectValue: string; dispatch: Dispatch }
>(
	'projects-v3/restoreFromArchive',
	async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			await V3ProjectSdk.updateProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data: {
					projectId,
					update: {
						archived: {
							state: false,
						},
					},
				},
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const addFileIdsToTask = createAsyncThunk<
	V3ProjectSdk.AddFileIdsToTaskResponse,
	V3ProjectSdk.AddFileIdsToTaskRequest & { projectId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'projects-v3/add-task-file',
	async ({ fileIds, ...data }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await V3ProjectSdk.addFileIdsToTask(
				import.meta.env.VITE_API as string,
				headers,
				{
					taskId: data.taskId,
					fileIds,
					actionIndex: data.actionIndex,
				},
			)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const getTasksPendingUploadedFiles = createAsyncThunk<
	V3ProjectSdk.GetTasksPendingUploadedFilesResponse,
	V3ProjectSdk.GetTasksPendingUploadedFilesRequest & { projectId: string; fetchFiles: () => void },
	{ rejectValue: any }
>(
	'projects-v3/get-tasks-pending-uploaded-files',
	async ({ projectId, ...data }, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await V3ProjectSdk.getTasksPendingUploadedFiles({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data,
			})

			if (response.length) {
				data.fetchFiles()
			}

			return response
		} catch (error) {
			return rejectWithValue(error as any)
		}
	},
)

export const fetchProjectFiles = createAsyncThunk<
	FilesSdk.GetFilesByFolderIdResponse,
	FilesSdk.GetFilesByFolderIdRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('projects-v3/fetch-files', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await FilesSdk.getFilesByFolderId(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateDependencies = createAsyncThunk<
	V3ProjectSdk.GetUpdatedDependenciesResponse,
	{ taskId: string; projectId: string },
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('projects-v3/updated-dependencies', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await V3ProjectSdk.updateDependencies(
			import.meta.env.VITE_API as string,
			headers,
			data.projectId,
			data.taskId,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const fetchMyTasksProjects = createAsyncThunk<
	{ projects: ProjectsStateV3['allProjects']; error?: string },
	| {
			isMobile: boolean
			focusSection?: {
				projectId: string
				sectionId: string
			}
	  }
	| undefined,
	{
		rejectValue: { projects: ProjectsStateV3['allProjects']; error?: string }
		dispatch: AsyncDispatchType
		state: RootState
	}
>(
	'projects-v3/fetch-my-tasks-projects',
	async (options, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await MyTasksV3Sdk.getProjects(import.meta.env.VITE_API as string, headers)

			const projects = response.reduce((acc: ProjectsStateV3['allProjects'], currValue) => {
				acc[currValue._id] = currValue
				return acc
			}, {})

			return { projects }
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue({ error: (error as Error).message, projects: {} })
		}
	},
)

export const fetchMyTasksProject = createAsyncThunk<
	MyTasksV3Sdk.GetProjectResponse,
	string,
	{
		rejectValue: any
		dispatch: AsyncDispatchType
		state: RootState
	}
>('projects-v3/fetch-project', async (projectId, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await MyTasksV3Sdk.getProject(
			import.meta.env.VITE_API as string,
			headers,
			projectId,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue({ error: (error as Error).message })
	}
})

export const fetchBlockOptions = createAsyncThunk<
	MyTasksV3Sdk.BlockOptionsResponse,
	string,
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('projects-v3/fetch-block-options', async (projectId, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await MyTasksV3Sdk.blockOptions(import.meta.env.VITE_API as string, headers, {
			projectId,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const createOnDemandChain = createAsyncThunk<
	V3ClientTypes.Project.Task[],
	{
		projectId: string
		newChainId: string
		chainId: string | undefined
		instanceName: string
		filters?: GridFilterModel
		ctaInstanceName?: string
	},
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>(
	'projects-v3/create-on-demand-chain',
	async ({ projectId, newChainId, ...rest }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)

			const response = await V3ProjectSdk.createOnDemandChain(
				import.meta.env.VITE_API as string,
				headers,
				projectId,
				newChainId,
				rest,
			)

			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)
