import { Stack } from '@mui/material'
import { ComponentType } from 'react'

import { Modal, Text, Box, Button } from '.'

type Props = {
	blockedTaskName: string | undefined
	isOpen: boolean
	onClose: () => void
	onComplete: () => void
	onCompleteAndResolve: () => void
}

export const RemoveFlaggedTaskModal: ComponentType<Props> = ({
	isOpen,
	onClose,
	blockedTaskName,
	onComplete,
	onCompleteAndResolve,
}) => {
	const title = blockedTaskName
		? `Has this task unblocked task: ${blockedTaskName}?`
		: 'Has this task unblocked a task?'

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box>
				<Stack direction="column" alignContent="center" spacing={5} paddingX={3}>
					<Text textAlign="center">{title}</Text>
					<Stack spacing={2} direction="row">
						<Button variant="contained" onClick={onCompleteAndResolve}>
							Complete and unblock
						</Button>
						<Button variant="outlined" onClick={onComplete}>
							Complete
						</Button>
						<Button variant="text" onClick={onClose}>
							Cancel
						</Button>
					</Stack>
				</Stack>
			</Box>
		</Modal>
	)
}
